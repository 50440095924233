import React from "react";
import { Stack, Typography, Tooltip } from "@mui/material";
import {
  BuildingOffice2Icon,
  GlobeAltIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { TFunction } from "i18next";
import WebSiteItem from "./WebSiteItem";
import linkedinIcon from "../../assets/linkedinIcon.png";
import xingIcon from "../../assets/xingIcon.png";
import { TCompany } from "../../models/CompanyInfo";
import {
  StyledCompanyDescription,
  StyledCompanyHeaderContainer,
} from "./style";

const CompanyHeader = ({
  t,
  company,
  desktop,
  industry,
  isShowMore,
  isShowMoreExist,
  shortCompanyDescription,
  isJobPreviewPage = false,
  isLogoAndTitleOnly = false,
  onShowMoreLess,
}: {
  readonly t: TFunction;
  readonly company: TCompany;
  readonly desktop: boolean;
  readonly industry: string;
  readonly isShowMoreExist: boolean;
  readonly isShowMore: boolean;
  readonly shortCompanyDescription: string;
  readonly isJobPreviewPage?: boolean;
  readonly isLogoAndTitleOnly?: boolean;
  readonly onShowMoreLess?: () => void;
}) => (
  <Stack
    width={isJobPreviewPage ? "auto" : "100vw"}
    alignItems="center"
    sx={{
      background: "#F9FAFB",
    }}
    px={desktop && !isJobPreviewPage ? 0 : 3}
  >
    <StyledCompanyHeaderContainer
      companyColor={company.color}
      desktop={desktop}
      isJobPreviewPage={isJobPreviewPage}
    >
      <Stack
        height={desktop ? 64 : "auto"}
        width="100%"
        direction={desktop ? "row" : "column"}
        spacing={3}
        flexWrap={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !desktop && company?.title?.length > 24 ? "wrap" : "nowrap"
        }
        alignItems={
          desktop
            ? shortCompanyDescription.length
              ? "center"
              : "center"
            : "normal"
        }
      >
        {company.logo && <img className="logo" src={company.logo} alt="logo" />}
        <Stack
          width="100%"
          justifyContent={desktop ? "space-between" : "flex-start"}
          spacing={desktop ? 2 : 0}
        >
          <Typography
            variant="h5"
            color="#131E30"
            overflow="hidden"
            textOverflow="ellipsis"
            width="90vw"
            sx={{
              display: "-webkit-box",
              "-webkit-line-clamp": "2",
              "-webkit-box-orient": "vertical",
            }}
          >
            {company.title}
          </Typography>
          {!isLogoAndTitleOnly && (
            <Stack
              mt={desktop ? 0 : 1.5}
              spacing={desktop ? 3.25 : 1}
              direction={desktop ? "row" : "column"}
            >
              <Stack direction="row" gap={1} alignItems="center">
                <UsersIcon className="icon" />
                <Typography
                  variant="subtitle2"
                  display="inline"
                  lineHeight="143%"
                >
                  {company?.employees} {t("company_page.employees")}
                </Typography>
              </Stack>
              {industry && (
                <Stack direction="row" gap={1} alignItems="center">
                  <BuildingOffice2Icon className="icon" />
                  <Tooltip title={industry}>
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      lineHeight="143%"
                      maxWidth={isJobPreviewPage && desktop ? 250 : "auto"}
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {industry}
                    </Typography>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
      {company?.about_us && !isLogoAndTitleOnly && (
        <Stack mt={3} width={desktop ? "100%" : "85%"}>
          {!isShowMoreExist && shortCompanyDescription.length < 4 && (
            <StyledCompanyDescription
              className="company-about-us"
              isShowMore={isShowMore}
              variant="subtitle2"
              color="textSecondary"
            >
              {company.about_us}
            </StyledCompanyDescription>
          )}
          <>
            {isShowMore ? (
              <Typography
                className="company-about-us"
                display="inline"
                variant="subtitle2"
                color="textSecondary"
                sx={{ wordBreak: "break-word" }}
              >
                {isShowMoreExist ? shortCompanyDescription : company.about_us}
                {isShowMoreExist && (
                  <Typography
                    display="inline"
                    variant="subtitle2"
                    color="secondary"
                    whiteSpace="nowrap"
                    onClick={onShowMoreLess}
                    sx={{ cursor: "pointer" }}
                  >
                    {" "}
                    {t("company_page.show_more")}
                  </Typography>
                )}
              </Typography>
            ) : (
              <Typography
                display="inline"
                variant="subtitle2"
                color="textSecondary"
                sx={{ wordBreak: "break-word" }}
              >
                {company.about_us}{" "}
                <Typography
                  display="inline"
                  variant="subtitle2"
                  color="secondary"
                  whiteSpace="nowrap"
                  onClick={onShowMoreLess}
                  sx={{ cursor: "pointer" }}
                >
                  {t("company_page.show_less")}
                </Typography>
              </Typography>
            )}
          </>
        </Stack>
      )}
      {!isLogoAndTitleOnly && (
        <Stack
          mt={desktop ? (company?.about_us || isJobPreviewPage ? 3 : 9) : 2}
          direction="row"
          spacing={1}
          position="relative"
          right={desktop ? 0 : 10}
        >
          <WebSiteItem
            text={t("company_page.website")}
            icon={<GlobeAltIcon className="icon" />}
            url={company.website}
            companyColor={company?.color}
          />
          {company.linkedin_url && (
            <WebSiteItem
              text="Linkedin"
              icon={<img src={linkedinIcon} className="icon" />}
              url={company.linkedin_url}
              companyColor={company?.color}
            />
          )}
          {company.xing_url && (
            <WebSiteItem
              text="Xing"
              icon={<img src={xingIcon} className="icon" />}
              url={company.xing_url}
              companyColor={company?.color}
            />
          )}
        </Stack>
      )}
    </StyledCompanyHeaderContainer>
  </Stack>
);

export default CompanyHeader;
