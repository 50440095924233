import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import SingleApplicationHeaderComponent from "./component";
import {
  getJobApplicationsLoadingState,
  getSelectedJobApplications,
} from "../../../store/selectors/Applications";
import { getTopHeaderState } from "../../../store/selectors/TopHeader";
import {
  setTopHeaderSubtitle,
  setTopHeaderTitle,
} from "../../../store/reducers/TopHeader";
import { APPLICATION_STATUSES } from "../../../models/Applications";
import { ITalentPool } from "../../../models/TalentPools";
import {
  fetchActivityLog,
  fetchSelectedJobApplications,
  resetCurrentApplication,
  updateApplicationStatus,
} from "../../../store/reducers/Applications";
import { getTalentPoolState } from "../../../store/selectors/Talentpool";
import { deleteCandidate } from "../../../store/reducers/TalentPools";
import { getCurrentUserData } from "../../../store/selectors/CurrentUser";
import { TSelectedJobCandidateApplication } from "../../../models/ApplicationPage";
import { getWorkflowState } from "../../../store/selectors/Workflow";
import { setIsAddCandidateToJobDialogOpen } from "../../../store/reducers/dialogs";

const SingleApplicationHeader = ({
  desktop,
}: {
  readonly desktop: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { job_id, talentpoolId } = useParams();
  const { title, subtitle } = useSelector(getTopHeaderState);
  const { isAgency, firstName } = useSelector(getCurrentUserData);
  const isLoading = useSelector(getJobApplicationsLoadingState);
  const currentApplication = useSelector(getSelectedJobApplications)?.[0];
  const { talentPools } = useSelector(getTalentPoolState);
  const { selectedTalentPool } = useSelector(getTalentPoolState);
  const { workflows, isListLoading } = useSelector(getWorkflowState);

  const [status, setStatus] = useState<APPLICATION_STATUSES | undefined>(
    undefined
  );
  const [targetJob, setTargetJob] = useState<{ title: string; id: string }>({
    title: "",
    id: "",
  });

  const defaultStatuses = [
    {
      label: t("status.new"),
      value: "new",
      color: "#818CF8",
    },
    {
      label: t("status.in_progress"),
      value: "review",
      color: "#F59E0B",
    },
    {
      label: t("status.hired"),
      value: "hired",
      color: "#22C55E",
    },
    {
      label: t("status.rejected"),
      value: "rejected",
      color: "#EF4444",
    },
  ];

  const applicationWorkflowStatuses = useMemo(() => {
    const findWorkflow = workflows.find(
      (workflow) => workflow.id === currentApplication?.workflow_id
    );
    if (findWorkflow) {
      return findWorkflow.statuses;
    }
    return defaultStatuses;
  }, [workflows]);

  const findFormattedLabelValue = applicationWorkflowStatuses?.find(
    (item) => item.value.toLowerCase() == status?.toLowerCase()
  );

  const defaultLabelValue = {
    label: t("status.new"),
    value: "new",
    color: "#178CF2",
  };

  const formattedLabelValue = findFormattedLabelValue
    ? findFormattedLabelValue
    : currentApplication?.status
      ? defaultLabelValue
      : undefined;

  const handleOnUpdateStatus = (newStatus: APPLICATION_STATUSES) => {
    if (currentApplication) {
      if (currentApplication?.is_talentpooled == 1) {
        const findTalentPool = talentPools.find(
          (item: ITalentPool) =>
            item.title === currentApplication?.talentpool_title
        );
        dispatch(
          updateApplicationStatus({
            applicationId: currentApplication.application_id,
            newStatus,
            index: "0",
            callback: () =>
              dispatch(
                deleteCandidate({
                  id: findTalentPool?.id as number,
                  applicationId:
                    currentApplication?.application_id as unknown as number,
                  recordId: currentApplication?.record_id as string,
                  callback: () => {
                    setStatus(newStatus);
                    dispatch(
                      fetchSelectedJobApplications({
                        job_id: job_id as string,
                        candidateUrlKey: currentApplication?.candidate_url_key,
                      })
                    );
                  },
                })
              ),
          })
        );
      } else {
        dispatch(
          updateApplicationStatus({
            applicationId: currentApplication.application_id,
            newStatus,
            index: "0",
            callback: () => {
              setStatus(newStatus);
              if (!isAgency) {
                dispatch(
                  fetchActivityLog({
                    applicationId: currentApplication.application_id,
                  })
                );
              }
            },
          })
        );
      }
    }
  };

  const handleOnNavigateBack = () => {
    if (location.pathname.includes("talentpool")) {
      navigate(`/talentpool/${talentpoolId}`);
    } else if (location.pathname.includes("job")) {
      navigate(`/jobs/${job_id}/applications`);
    } else {
      navigate("/applications");
    }
    dispatch(resetCurrentApplication());
  };

  const handleOpenAddCandidateToJobDialog = () => {
    dispatch(setIsAddCandidateToJobDialogOpen(true));
  };

  useEffect(() => {
    if (currentApplication) {
      const candidateName = `${currentApplication.firstname} ${currentApplication.lastname}`;
      dispatch(setTopHeaderTitle(candidateName));
      const subtitle = currentApplication.application_id
        ? `${t("single_job.applied_on")} ${moment(
            currentApplication.timestamp
          ).format("DD.MM.YYYY")}`
        : `${t("talentPools.created_at")} ${moment(
            (
              currentApplication as TSelectedJobCandidateApplication & {
                created_at: string;
              }
            ).created_at
          ).format("DD.MM.YYYY")} ${t(
            "talentPools.in_the_talentpool"
          )} ${selectedTalentPool?.title}`;
      dispatch(setTopHeaderSubtitle(subtitle));
      setStatus(currentApplication.status);
      setTargetJob({
        title: currentApplication.title,
        id: currentApplication.job_id as string,
      });
    }
  }, [currentApplication]);

  return (
    <SingleApplicationHeaderComponent
      t={t}
      title={title}
      subtitle={subtitle}
      isLoading={isLoading || isListLoading || !firstName}
      status={formattedLabelValue}
      targetJob={targetJob}
      currentApplication={currentApplication}
      desktop={desktop}
      onOpenAddCandidateToJobDialog={handleOpenAddCandidateToJobDialog}
      onUpdateStatus={handleOnUpdateStatus}
      onNavigateBack={handleOnNavigateBack}
    />
  );
};

export default SingleApplicationHeader;
