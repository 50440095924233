import React from "react";
import { Stack, Typography } from "@mui/material";
import { UserIcon } from "@heroicons/react/24/outline";
import { StyledApplicationsNumber } from "../styles";
import { IDataGridApplicationsNumber } from "../../../models/DataGrid";

const ApplicationsNumber = ({
  applicantsNumber,
  onClick,
}: IDataGridApplicationsNumber) => (
  <StyledApplicationsNumber onClick={onClick}>
    <Stack className="icon-container">
      <UserIcon />
    </Stack>
    <Typography
      variant="subtitle2"
      fontSize={12}
      color="#6B7280"
      fontWeight={500}
    >
      {applicantsNumber}
    </Typography>
  </StyledApplicationsNumber>
);

export default ApplicationsNumber;
