import React from "react";
import { Stack, Typography } from "@mui/material";
import ProductItem from "./ProductItem";
import BuyProductDialog from "../../components/dialogs/BuyProductDialog";
import PremiumPackagesComponent from "../SingleJob/PremiumJobBoards/Packages";
import { TPremiumProductItem } from "../../models/SingleJob";
import { StyledPremiumPackagesProductsContainer } from "../SingleJob/styles";
import { IProductPage } from "../../models/Product";

const ProductPageComponent = ({
  t,
  activeProduct,
  activeProductId,
  productPackages,
  onOpenProduct,
  isSelected,
  onChangeActiveProductId,
  onOpenBuyProductDialog,
  onHoverLeaveSelectPackageButton,
}: IProductPage) => (
  <Stack spacing={2} mt={3}>
    <StyledPremiumPackagesProductsContainer>
      <Typography variant="body1">
        {t("create_job_fourth_step.products")}
      </Typography>
      <Stack mt={3} spacing={2}>
        {activeProduct?.products.map((product: TPremiumProductItem) => (
          <ProductItem
            key={product.id}
            t={t}
            productItem={product}
            activeProductId={activeProductId}
            onChangeActiveProductId={onChangeActiveProductId}
            onOpenBuyProductDialog={onOpenBuyProductDialog}
          />
        ))}
      </Stack>
      <BuyProductDialog />
    </StyledPremiumPackagesProductsContainer>
    {!!productPackages.length && (
      <PremiumPackagesComponent
        t={t}
        packages={productPackages}
        isSelected={isSelected}
        onAddPackage={() => {}}
        onOpenProduct={onOpenProduct}
        onRemoveFromCart={() => {}}
        onResetFilters={() => {}}
        onHoverLeaveSelectPackageButton={onHoverLeaveSelectPackageButton}
      />
    )}
  </Stack>
);

export default ProductPageComponent;
