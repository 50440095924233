import React from "react";
import ReactApexChart from "react-apexcharts";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import IconWithTooltip from "./IconWithTooltip";
import {
  getAnalyticsNOAIsLoadingState,
  getAnalyticsSOHTargetSources,
  getAnalyticsSOHTargetValues,
} from "../../store/selectors/Analytics";
import {
  StyledChartContainer,
  StyledChartContainerTitle,
  StyledNoDataContainer,
} from "./styles";
import { getPerformedName, SOHChartOptions } from "./utils";

const SourceOfHire = () => {
  const { t } = useTranslation();
  const isLoading = useSelector(getAnalyticsNOAIsLoadingState);
  const values = useSelector(getAnalyticsSOHTargetValues);
  const sources = useSelector(getAnalyticsSOHTargetSources);

  return (
    <StyledChartContainer>
      <StyledChartContainerTitle>
        <h4>{t("analytics.sourceOfHire")}</h4>
        <IconWithTooltip title={t("analytics.infoText.sourceOfHire")} />
      </StyledChartContainerTitle>
      {isLoading ? (
        <Stack maxHeight={350}>
          <Loading />
        </Stack>
      ) : values?.length ? (
        <ReactApexChart
          series={values}
          options={{
            ...SOHChartOptions({ isMultiple: values.length > 1 }),
            labels:
              sources.map((source) =>
                source
                  ? t(
                      getPerformedName({
                        name: source,
                        path: "source",
                      })
                    )
                  : t("analytics.unknown")
              ) || [],
          }}
          height={350}
          type={"radialBar"}
        />
      ) : (
        <StyledNoDataContainer>
          <h5>{t("analytics.noDataText")}</h5>
          <h6>{t("analytics.changeDateFilter")}</h6>
        </StyledNoDataContainer>
      )}
    </StyledChartContainer>
  );
};

export default SourceOfHire;
