import React, { useEffect, useMemo, useState } from "react";
import { InputAdornment } from "@mui/material";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { debounce } from "debounce";
import { ISimpleFilter } from "../../../models/filters";
import { StyledSimpleFilterSearchField } from "../styles";

const SimpleFilter = ({
  onChange,
  placeholder,
  value,
  className,
  testId,
}: ISimpleFilter) => {
  const [currentFilter, setCurrentFilter] = useState(value);

  useEffect(() => {
    setCurrentFilter(value);
  }, [value]);

  const debouncedOnChangeCB = useMemo(
    () => debounce(onChange, 500),
    [onChange]
  );
  const handleOnChange = (value: string) => {
    setCurrentFilter(value);
    debouncedOnChangeCB(value);
  };

  return (
    <StyledSimpleFilterSearchField
      data-testid={testId}
      value={currentFilter}
      onChange={(e) => {
        handleOnChange(e.target.value);
      }}
      className={className}
      placeholder={placeholder}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlassIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SimpleFilter;
