import React from "react";
import { Typography } from "@mui/material";
import {
  BriefcaseIcon,
  FolderOpenIcon,
  MapPinIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  StyledJobListItemContainer,
  StyledJobListItemDescriptionContainer,
  StyledJobListItemIconContainer,
  StyledJobTitle,
} from "../styles";
import { IDashboardJobListItem } from "../../../models/Dashboard";

const JobListItem = ({
  t,
  applicationsCount,
  city,
  positionType,
  title,
  workType,
  onJobNavigate,
}: IDashboardJobListItem) => (
  <StyledJobListItemContainer data-testid={"dashboard-job-list-item-container"}>
    <StyledJobTitle onClick={onJobNavigate}>{title}</StyledJobTitle>
    <StyledJobListItemDescriptionContainer>
      <StyledJobListItemIconContainer>
        <MapPinIcon />
        <Typography variant="subtitle2" display="inline" color="textSecondary">
          {city}
        </Typography>
      </StyledJobListItemIconContainer>
      <StyledJobListItemIconContainer>
        <BriefcaseIcon />
        <Typography variant="subtitle2" display="inline" color="textSecondary">
          {positionType}
        </Typography>
      </StyledJobListItemIconContainer>
      <StyledJobListItemIconContainer>
        <FolderOpenIcon />
        <Typography variant="subtitle2" display="inline" color="textSecondary">
          {workType}
        </Typography>
      </StyledJobListItemIconContainer>
      <StyledJobListItemIconContainer
        className={"link"}
        onClick={onJobNavigate}
      >
        <UserIcon />
        <Typography variant="subtitle2" display="inline">
          {applicationsCount} {t("dashboard.jobs.applications")}
        </Typography>
      </StyledJobListItemIconContainer>
    </StyledJobListItemDescriptionContainer>
  </StyledJobListItemContainer>
);

export default JobListItem;
