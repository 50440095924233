import React from "react";
import { Button, Stack } from "@mui/material";
import TalentPoolFilters from "./filters";
import DataGrid from "../../components/DataGrid";
import Loading from "../../components/Loading";
import EmptyData from "../../components/EmptyData";
import Pagination from "../../components/Pagination";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddProfileDialog from "./AddProfileDialog";
import AddCandidateToJobDialog from "../../components/dialogs/AddCandidateToJobDialog";
import { DATA_GRID_LIST_TYPE } from "../../models/DataGrid";
import {
  ITalentPoolCandidate,
  ITalentPoolComponent,
} from "../../models/TalentPools";
import { DIALOG_TYPE } from "../../models/common";

const TalentPoolComponent = ({
  t,
  isListLoading,
  candidates,
  pageSize,
  pageNum,
  totalCount,
  dialogType,
  selectedCandidate,
  jobs,
  jobsCount,
  jobsPageNum,
  jobsPageSize,
  jobsQuickSearch,
  selectedJob,
  onAddProfile,
  onConfirmAddProfile,
  onOpenDialog,
  onCloseDialog,
  onApplyCandidateToJob,
  onDeleteCandidateFromTalentPool,
  onPaginate,
  onJobsPaginate,
  onChangeSimpleFilter,
  onOpenApplication,
}: ITalentPoolComponent) => (
  <Stack mt={3} minHeight="80vh">
    <Stack direction={"row"} flex={1} spacing={2}>
      <TalentPoolFilters />
      <Button
        style={{ minWidth: 150 }}
        variant={"contained"}
        onClick={onAddProfile}
      >
        {t("candidate.addProfile")}
      </Button>
    </Stack>
    {isListLoading || !candidates ? (
      <Loading />
    ) : (
      <Stack height="100%" mt={3}>
        {candidates?.length > 0 ? (
          <Stack height="100%">
            <DataGrid
              t={t}
              list={candidates}
              type={DATA_GRID_LIST_TYPE.TALENT_POOL_CANDIDATE}
              actions={{
                onEdit: (candidate) =>
                  onOpenApplication(candidate as ITalentPoolCandidate),
                onRemove: (data) => onOpenDialog(data, DIALOG_TYPE.DELETE),
                onAddCandidateToActiveJob: (data) =>
                  onOpenDialog(data, DIALOG_TYPE.ADD_CANDIDATE_TO_JOB),
              }}
            />
            <Pagination
              totalPosts={totalCount}
              itemsPerPage={pageSize}
              page={pageNum}
              paginate={onPaginate}
            />
          </Stack>
        ) : (
          <EmptyData
            title={t("talentPools.noCandidatesInTalentPoolTitle")}
            subtitle={
              t("talentPools.noCandidatesInTalentPoolSubTitle") as string
            }
          />
        )}
      </Stack>
    )}
    <DeleteDialog
      t={t}
      isOpen={dialogType === DIALOG_TYPE.DELETE}
      title={t("talentPools.deleteCandidateFromTalentPoolTitle")}
      body={t("talentPools.deleteCandidateFromTalentPoolSubTitle")}
      onCancel={onCloseDialog}
      onDelete={onDeleteCandidateFromTalentPool}
    />
    <AddCandidateToJobDialog
      t={t}
      isOpen={dialogType === DIALOG_TYPE.ADD_CANDIDATE_TO_JOB}
      selectedCandidate={selectedCandidate}
      jobs={jobs}
      selectedJob={selectedJob}
      jobsCount={jobsCount}
      pageNum={jobsPageNum}
      pageSize={jobsPageSize}
      quickSearch={jobsQuickSearch}
      onClose={onCloseDialog}
      onJobsPaginate={onJobsPaginate}
      onAddCandidateToJob={onApplyCandidateToJob}
      onChangeSimpleFilter={onChangeSimpleFilter}
    />
    <AddProfileDialog
      onAdd={onConfirmAddProfile}
      isOpen={dialogType === DIALOG_TYPE.ADD_PROFILE}
      onClose={onCloseDialog}
    />
  </Stack>
);

export default TalentPoolComponent;
