import React, { KeyboardEventHandler, MouseEventHandler } from "react";
import { Stack } from "@mui/material";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { INewMessageComponent } from "../../models/Messages";
import {
  StyledNewMessageContainer,
  StyledChatTextarea,
  StyledMobileChatTextarea,
  StyledChatDivider,
} from "./styles";

const NewMessage = ({ t, desktop, sendMessage }: INewMessageComponent) => {
  return (
    <StyledNewMessageContainer
      id="messages-area"
      padding={desktop ? "10px 24px" : "0px"}
      borderTop={desktop ? "1px solid #E5E7EB" : "none"}
      borderBottom={desktop ? "1px solid #E5E7EB" : "none"}
      sx={{ background: "#fff" }}
    >
      {desktop ? (
        <Stack direction="row" width="100%" spacing={3}>
          <StyledChatTextarea
            id="desktop-chat-area"
            placeholder={t("messages_page.new_message_placeholder") as string}
            rows={4}
            onKeyDown={sendMessage as KeyboardEventHandler}
          />
          <Stack
            width={40}
            height={40}
            justifyContent="center"
            alignItems="center"
            borderRadius={1.5}
            alignSelf={desktop ? "flex-end" : "center"}
            sx={{
              cursor: "pointer",
              backgroundColor: "#178CF2",
              "&:hover": {
                backgroundColor: "#304BA8 !important",
              },
            }}
            onClick={sendMessage as MouseEventHandler}
          >
            <PaperAirplaneIcon
              width={14}
              height={14}
              stroke="#fff"
              cursor="pointer"
            />
          </Stack>
        </Stack>
      ) : (
        <Stack
          width="100vw"
          position="fixed"
          bottom={0}
          height={64}
          spacing={1.5}
          sx={{
            background: "#fff",
          }}
        >
          <StyledChatDivider />
          <Stack>
            <Stack direction="row" spacing={3}>
              <StyledMobileChatTextarea
                id="mobile-chat-area"
                placeholder={
                  t("messages_page.new_message_placeholder") as string
                }
                rows={4}
                onKeyDown={sendMessage as KeyboardEventHandler}
              />
              <Stack width={85}>
                <Stack
                  width={40}
                  height={40}
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={1.5}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "#178CF2",
                    "&:hover": {
                      backgroundColor: "#304BA8 !important",
                    },
                  }}
                  onClick={sendMessage as MouseEventHandler}
                >
                  <PaperAirplaneIcon
                    width={14}
                    height={14}
                    stroke="#fff"
                    cursor="pointer"
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
    </StyledNewMessageContainer>
  );
};

export default NewMessage;
