import React, { useMemo } from "react";
import CandidateNoteInputFieldComponent from "./component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getSelectedJobOwners } from "../../../../store/selectors/Applications";
import { IApplicationNotesInputField } from "../../../../models/Applications";

const CandidateNoteInputField = ({
  inputValue,
  onChangeInput,
  onSubmit,
}: IApplicationNotesInputField) => {
  const { t } = useTranslation();
  const selectedJobOwners = useSelector(getSelectedJobOwners);

  const userList = useMemo(
    () =>
      selectedJobOwners?.map((jobOwner) => ({
        ...jobOwner,
        display: `@${jobOwner.firstname} ${jobOwner.lastname}`,
        name: `${jobOwner.firstname} ${jobOwner.lastname}`,
      })) || [],
    []
  );

  const isDisabled = useMemo(() => !inputValue.trim().length, [inputValue]);

  return (
    <CandidateNoteInputFieldComponent
      t={t}
      isDisabled={isDisabled}
      userList={userList}
      value={inputValue}
      onChangeInput={onChangeInput}
      onSubmit={onSubmit}
    />
  );
};

export default CandidateNoteInputField;
