import React from "react";
import { Button, Divider, Stack } from "@mui/material";
import { EyeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import JobListItem from "./JobListItem";
import Loading from "../../../components/Loading";
import { JOB_STATUSES } from "../../../models/JobList";
import {
  StyledDashboardItemContainer,
  StyledDashboardItemTitle,
  StyledDashboardSecondaryText,
  StyledJobListContainer,
  StyledJobsEmptyListContainer,
} from "../styles";
import { IDashboardJobs } from "../../../models/Dashboard";

const DashboardJobsComponent = ({
  t,
  jobList,
  isLoading,
  onJobNavigate,
  onOpenJobEditor,
}: IDashboardJobs) => (
  <StyledDashboardItemContainer data-testid={"dashboard-jobs-wrapper"}>
    <Stack spacing={3} className={"full-height"}>
      <Stack direction={"row"}>
        <StyledDashboardItemTitle>
          {t("dashboard.jobs.title")}
        </StyledDashboardItemTitle>{" "}
        <StyledDashboardSecondaryText className={"title-row"}>
          <Link className={"link"} to={`/jobs/${JOB_STATUSES.ACTIVE}`}>
            {t("dashboard.jobs.titleBtn")}
          </Link>
        </StyledDashboardSecondaryText>
      </Stack>
      <Divider />
      {isLoading ? (
        <Loading />
      ) : jobList.length ? (
        <StyledJobListContainer data-testid={"dashboard-job-list-wrapper"}>
          {jobList.map((jobItem) => (
            <JobListItem
              key={jobItem.id}
              t={t}
              applicationsCount={jobItem.applicationsCount}
              city={jobItem?.locations[0]?.city || ""}
              positionType={jobItem.position_type}
              title={jobItem.title}
              workType={jobItem.field_of_work}
              onJobNavigate={() => {
                onJobNavigate(jobItem.id);
              }}
            />
          ))}
        </StyledJobListContainer>
      ) : (
        <StyledJobsEmptyListContainer
          data-testid={"dashboard-jobs-empty-list-wrapper"}
        >
          <Stack className={"icon-container"}>
            <EyeIcon />
          </Stack>
          <StyledDashboardSecondaryText>
            {t("dashboard.jobs.emptyList")}
          </StyledDashboardSecondaryText>
          <Button variant={"contained"} onClick={onOpenJobEditor}>
            {t("dashboard.jobs.createBtn")}
          </Button>
        </StyledJobsEmptyListContainer>
      )}
    </Stack>
  </StyledDashboardItemContainer>
);

export default DashboardJobsComponent;
