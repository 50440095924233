import React from "react";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Button,
  Menu,
  Divider,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../../../components/filters/AutocompleteFilter";
import TextEditorCustomToolbar from "../../../../components/TextEditorCustomToolbar";
import { StyledSendMailFormContainer } from "../../style";
import { TListOption } from "../../../../models/common";
import { ISendEmailComponent } from "../../../../models/Automation";

const SendEmailComponent = ({
  t,
  open,
  anchorEl,
  formattedMembers,
  formattedVariables,
  maximumFormHeight,
  selectedAction,
  errors,
  control,
  selectedEmails,
  selectedCC,
  selectedBCC,
  onChangeEmails,
  onChangeBCC,
  onChangeCC,
  register,
  onChangeCCorBcc,
  onCloseAction,
  onCloseAnchorEl,
  onOpenVariables,
  onSelectVariable,
  onSubmit,
  handleSubmit,
}: ISendEmailComponent) => (
  <Stack
    width="100%"
    padding="0px 16px 16px 16px"
    borderRadius={1.5}
    sx={{ background: "#fff" }}
  >
    <Stack spacing={2}>
      <Stack
        mt="0px !important"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize={16} fontWeight={500}>
          {t("automation.sendEmailTo")}
        </Typography>
        <Stack height="100%" alignItems="center">
          <IconButton onClick={onCloseAction}>
            <XMarkIcon stroke="#111827" width={20} height={20} />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSendMailFormContainer maxHeight={maximumFormHeight} spacing={1}>
        <Stack>
          <InputLabel className="required-label">
            {t("automation.to")}
          </InputLabel>
          <AutocompleteFilter
            listBoxProps={{
              className: "multiple-selection-autocomplete",
            }}
            multiple
            size="small"
            placeholder={t("automation.emailPlaceholder")}
            options={formattedMembers as unknown as TListOption[]}
            value={selectedEmails}
            errors={errors.to}
            onChange={onChangeEmails}
          />
        </Stack>
        {selectedAction?.params?.isCCorBCC ? (
          <Stack
            mt={errors?.to?.message ? "16px !important" : "8px !important"}
            spacing={1}
          >
            <Stack>
              <InputLabel>CC</InputLabel>
              <AutocompleteFilter
                listBoxProps={{
                  className: "multiple-selection-autocomplete",
                }}
                multiple
                size="small"
                placeholder={t("automation.emailPlaceholder")}
                options={formattedMembers as unknown as TListOption[]}
                value={selectedCC}
                onChange={onChangeCC}
              />
            </Stack>
            <Stack>
              <InputLabel>BCC</InputLabel>
              <AutocompleteFilter
                listBoxProps={{
                  className: "multiple-selection-autocomplete",
                }}
                multiple
                size="small"
                placeholder={t("automation.emailPlaceholder")}
                options={formattedMembers as unknown as TListOption[]}
                value={selectedBCC}
                onChange={onChangeBCC}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction="row"
            mt={errors?.to?.message ? "16px !important" : "8px !important"}
            spacing={1}
          >
            <Typography
              className="cc-bcc"
              onClick={() => onChangeCCorBcc(true)}
            >
              CC
            </Typography>
            <Typography
              className="cc-bcc"
              onClick={() => onChangeCCorBcc(true)}
            >
              BCC
            </Typography>
          </Stack>
        )}
        <Stack
          mt={
            selectedAction?.params?.isCCorBCC
              ? "8px !important"
              : "4px !important"
          }
          spacing={0.25}
        >
          <InputLabel className="required-label">
            {t("automation.subject")}
          </InputLabel>
          <TextField
            {...register("subject")}
            placeholder={t("automation.subjectPlaceholder") as string}
            fullWidth
            error={Boolean(errors.subject)}
            helperText={errors?.subject?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={onOpenVariables}
                    fontSize={14}
                    fontWeight={500}
                    color="#6B7280"
                    sx={{ cursor: "pointer" }}
                  >
                    {`{ }`}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack spacing={0.25}>
          <InputLabel className="required-label">
            {t("automation.content")}
          </InputLabel>
          <Controller
            name="message"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextEditorCustomToolbar
                items={formattedVariables as unknown as TListOption[]}
                value={value}
                onChange={onChange}
                errorMessage={errors?.message?.message as string}
              />
            )}
          />
        </Stack>
      </StyledSendMailFormContainer>
      <Stack
        mt={2}
        direction="row"
        width="100%"
        justifyContent="flex-end"
        spacing={1.5}
      >
        <Button variant="outlined" onClick={onCloseAction}>
          {t("button.secondCancel")}
        </Button>
        <Button variant="contained" type="submit">
          {t("button.save")}
        </Button>
      </Stack>
    </form>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onCloseAnchorEl}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {formattedVariables.map((item: TListOption) => (
        <MenuItem
          key={item.value}
          onClick={() => onSelectVariable(`{${item.value}}`)}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  </Stack>
);

export default SendEmailComponent;
