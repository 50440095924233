import { TFunction } from "i18next";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetApplicationsFilters } from "../../../store/reducers/Applications";
import { getApplicationsFiltersState } from "../../../store/selectors/Applications";
import JobListNoResultComponent from "./component";

const ApplicationsNoResultOverlay = ({
  t,
  showPremiumButton = true,
  customTitle,
  customBodyText,
}: {
  readonly t: TFunction;
  readonly showPremiumButton?: boolean;
  readonly customTitle?: string;
  readonly customBodyText?: string;
}) => {
  const dispatch = useDispatch();

  const { quickSearch, status, appliedTo } = useSelector(
    getApplicationsFiltersState
  );

  const hasFilters = useMemo(
    () => Boolean(status.value || appliedTo.value || quickSearch),
    [status, appliedTo, quickSearch]
  );

  const titleText = useMemo(() => {
    if (customTitle) {
      return customTitle;
    }
    return t(
      hasFilters
        ? "joblist.empty_first_text_filters_exists"
        : "applications.empty_first_text"
    );
  }, [hasFilters]);

  const bodyText = useMemo(() => {
    if (customBodyText) {
      return customBodyText;
    }
    return t(hasFilters ? "" : "applications.empty_second_text");
  }, [hasFilters]);

  const handleOnResetFilters = () => {
    dispatch(resetApplicationsFilters());
  };

  return (
    <JobListNoResultComponent
      t={t}
      bodyText={bodyText}
      titleText={titleText}
      hasFilters={hasFilters}
      onResetFilters={handleOnResetFilters}
      showPremiumButton={showPremiumButton}
    />
  );
};

export default ApplicationsNoResultOverlay;
