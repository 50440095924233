import React from "react";
import { Stack, Typography } from "@mui/material";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { TFunction } from "i18next";
import { SuccessScreenContainer } from "../styles";

const AdditionalQuestionsSuccessScreen = ({
  t,
  desktop,
  isSubmitted,
}: {
  t: TFunction;
  desktop: boolean;
  isSubmitted: boolean;
}) => (
  <Stack width={desktop ? "100%" : "75vw"}>
    <SuccessScreenContainer desktop={desktop}>
      <CheckCircleIcon />
      <Typography
        variant="body1"
        fontWeight={500}
        fontSize={desktop ? 20 : 16}
        paddingTop={2.5}
        paddingBottom={2.5}
        textAlign="center"
      >
        {isSubmitted
          ? t("application_complete.title")
          : t("additional_questions.confirmation_title")}
      </Typography>
      <Typography variant="body2" color="textSecondary" textAlign="center">
        {isSubmitted
          ? t("application_complete.subtitle")
          : t("additional_questions.confirmation_subtitle")}
      </Typography>
    </SuccessScreenContainer>
  </Stack>
);

export default AdditionalQuestionsSuccessScreen;
