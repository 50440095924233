import React from "react";
import { Divider, Stack } from "@mui/material";
import ProgressBox from "./ProgressBox";
import {
  StyledDashboardItemContainer,
  StyledDashboardItemTitle,
  StyledFirstStepsContainer,
} from "../styles";
import { IFilterStepsBox } from "../../../models/Dashboard";

const FirstStepsBoxComponent = ({
  t,
  isCompanyInfoFull,
  isJobExist,
  isLinkedinExist,
  isLogoExist,
  isXingExist,
  onNavigateCompanySettings,
  onOpenConnectDialog,
  onOpenJobEditor,
  onOpenUploadLogoDialog,
}: IFilterStepsBox) => (
  <StyledDashboardItemContainer data-testid={"dashboard-first-steps-wrapper"}>
    <Stack spacing={3} className={"full-height"}>
      <StyledDashboardItemTitle>
        {t("dashboard.firstSteps.title")}
      </StyledDashboardItemTitle>
      <Divider />
      <StyledFirstStepsContainer>
        <ProgressBox progress={100} title={t("dashboard.firstSteps.stepOne")} />
        <ProgressBox
          actionType={"uploadLogo"}
          onAction={onOpenUploadLogoDialog}
          progress={isLogoExist ? 100 : 0}
          title={t("dashboard.firstSteps.stepTwo")}
        />
        <ProgressBox
          actionType={"updateCompanyInfo"}
          onAction={onNavigateCompanySettings}
          progress={isCompanyInfoFull ? 100 : 0}
          title={t("dashboard.firstSteps.stepThree")}
        />
        <ProgressBox
          actionType={"connectLinkedin"}
          onAction={() => onOpenConnectDialog("Linkedin")}
          progress={isLinkedinExist ? 100 : 0}
          title={t("dashboard.firstSteps.stepFour")}
        />
        <ProgressBox
          actionType={"connectXing"}
          onAction={() => onOpenConnectDialog("Onlyfy")}
          progress={isXingExist ? 100 : 0}
          title={t("dashboard.firstSteps.stepFive")}
        />
        <ProgressBox
          actionType={"createJob"}
          onAction={onOpenJobEditor}
          progress={isJobExist ? 100 : 0}
          title={t("dashboard.firstSteps.stepSix")}
        />
      </StyledFirstStepsContainer>
    </Stack>
  </StyledDashboardItemContainer>
);

export default FirstStepsBoxComponent;
