import React from "react";
import {
  Stack,
  Typography,
  IconButton,
  Divider,
  InputLabel,
  Button,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AutocompleteFilter from "../../../../components/filters/AutocompleteFilter";
import { IChangeApplicationStatusComponent } from "../../../../models/Automation";

const ChangeApplicationStatusComponent = ({
  t,
  statuses,
  selectedStatus,
  onCloseAction,
  onChangeStatus,
  onSave,
}: IChangeApplicationStatusComponent) => (
  <Stack>
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={16} fontWeight={500}>
          {t("automation.changeApplicationStatus")}
        </Typography>
        <Stack height="100%" alignItems="center">
          <IconButton onClick={onCloseAction}>
            <XMarkIcon stroke="#111827" width={20} height={20} />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
    <Stack mt={2} spacing={0.5}>
      <InputLabel className="required-label">
        {t("automation.selectNewStatus")}
      </InputLabel>
      <AutocompleteFilter
        placeholder="Status"
        options={statuses}
        value={selectedStatus || ""}
        onChange={(value: any) => onChangeStatus(value)}
        hasColors
      />
    </Stack>
    <Stack
      mt={2}
      direction="row"
      width="100%"
      justifyContent="flex-end"
      spacing={1.5}
    >
      <Button variant="outlined" onClick={onCloseAction}>
        {t("button.secondCancel")}
      </Button>
      <Button disabled={!selectedStatus} variant="contained" onClick={onSave}>
        {t("button.save")}
      </Button>
    </Stack>
  </Stack>
);

export default ChangeApplicationStatusComponent;
