import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CandidateDashboardComponent from "./component";
import {
  getCandidateApplications,
  getCandidateState,
} from "../../store/selectors/Candidate";
import { TCandidateApplication } from "../../models/ApplicationPage";
import { fetchCandidateApplications } from "../../store/reducers/Candidate";
import {
  getCurrentUserIsCandidate,
  getCurrentUserState,
} from "../../store/selectors/CurrentUser";

const CandidateDashboard = ({ desktop }: { desktop: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const applications = useSelector(getCandidateApplications);
  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const { isLoading } = useSelector(getCandidateState);
  const { statuses } = useSelector(getCurrentUserState);

  const allStatuses = statuses?.map((status) => status.value);

  const handleOnSelectJob = (application: TCandidateApplication) => {
    // TODO refactor later within refactoring TOP Header
    navigate(`/candidate-dashboard/job/${application.job.url_key}`);
  };

  useEffect(() => {
    if (isCandidate !== undefined) {
      if (!isCandidate) {
        navigate("/dashboard");
      } else {
        dispatch(fetchCandidateApplications());
      }
    }
  }, [isCandidate]);

  return (
    <CandidateDashboardComponent
      t={t}
      applications={applications}
      desktop={desktop}
      isLoading={isLoading}
      isCandidate={isCandidate}
      allStatuses={allStatuses}
      onSelectJob={handleOnSelectJob}
    />
  );
};

export default CandidateDashboard;
