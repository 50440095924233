import React from "react";
import ReactApexChart from "react-apexcharts";
import { IStackedBarChart } from "../../models/Chart";

const StackedBarChart = ({
  height = 350,
  series,
  categories,
  colors,
  stages,
  jobs,
}: IStackedBarChart) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      height,
      stacked: true,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories,
    },
    fill: {
      colors,
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      markers: {
        fillColors: colors,
      },
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val} days`,
      },
      x: {
        formatter: (val: number, { dataPointIndex, seriesIndex }) => {
          const targetStage = stages ? stages[seriesIndex] : 0;
          const { quantity = 0 } =
            jobs?.[dataPointIndex]?.sumStat[targetStage] || {};
          if (jobs && stages) {
            return `<div><div>${val}</div><div>Applicants ${quantity}</div></div>`;
          }
          return `<div>${val}</div>`;
        },
      },
    },
    colors: colors,
  };

  return (
    <ReactApexChart
      type="bar"
      options={options}
      series={series}
      height={height}
    />
  );
};

export default StackedBarChart;
