import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import JobPreviewComponent from "./component";
import Loading from "../../components/Loading";
import NoData from "../../components/NoData";
import { getJobPostingFormat } from "../../utils/meta";
import { getCompanySettingsCompanyState } from "../../store/selectors/Company";
import { fetchJob } from "../../store/reducers/JobPreview";
import { getJobPreviewState } from "../../store/selectors/JobPreview";
import { formatLogoUrl } from "../../utils";

const JobPreview = ({ desktop }: { desktop: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { key: jobUrlKey } = useParams();

  const {
    jobTemplate,
    isLoading,
    jobLocations,
    companyColor,
    totalJobs,
    error,
    isJobLoadingFinished,
  } = useSelector(getJobPreviewState);
  const { company } = useSelector(getCompanySettingsCompanyState);

  useEffect(() => {
    if (!isLoading && jobUrlKey) dispatch(fetchJob({ jobUrlKey }));
  }, [jobUrlKey]);

  const jobPostingFormat = getJobPostingFormat({
    jobTemplate,
    jobLocations,
    company,
  });

  const mainLogo = jobTemplate?.logo;
  const formattedLogo = mainLogo ? formatLogoUrl(mainLogo) : null;

  const metaData = useMemo(() => {
    const title = `${jobTemplate?.company} - ${jobTemplate?.title}`;
    const description = `${jobTemplate?.company} has a vacancy for ${jobTemplate?.title} in ${jobLocations[0]?.city}.
      Apply now or check out the other available jobs.`;

    return {
      title,
      description,
      imgUrl: "https://i.imgur.com/" + jobTemplate?.logo,
    };
  }, [jobTemplate]);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{`${JSON.stringify(
          jobPostingFormat
        )}`}</script>
      </Helmet>
      {isLoading && !isJobLoadingFinished ? (
        <Loading />
      ) : (
        <>
          {jobTemplate && !error ? (
            <JobPreviewComponent
              metaData={metaData}
              companyColor={companyColor}
              jobPostingFormat={jobPostingFormat}
              logo={formattedLogo}
              t={t}
              desktop={desktop}
              totalJobs={totalJobs}
            />
          ) : error ? (
            <NoData title={t("apply_page.no_job_text")} />
          ) : null}
        </>
      )}
    </>
  );
};

export default JobPreview;
