import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { StyledStatusChip } from "../../../../components/DataGrid/styles";
import StyledSectionTitle from "../../../../components/SectionTitle";
import { IJobDocumnetsPreviewComponent } from "../../../../models/SingleJob";
import { getJobDocumentsFields } from "./config";
import { StyledSectionContainer } from "./styles";

const JobDocuments = ({
  t,
  jobDetails,
  onEdit,
}: IJobDocumnetsPreviewComponent) => (
  <StyledSectionContainer spacing={2}>
    <StyledSectionTitle
      title={t("singleJob.jobDetails.titles.requiredDocuments")}
      onClick={onEdit}
    />
    <Divider />
    {getJobDocumentsFields({ t, jobDetails }).map((field) => {
      return (
        <Stack
          key={field.title}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant={"subtitle2"} color={"#6B7280"}>
            {field.title}
          </Typography>
          <StyledStatusChip label={field.status} type={field.status} />
        </Stack>
      );
    })}
  </StyledSectionContainer>
);

export default JobDocuments;
