import React, { useRef } from "react";
import { Button, CircularProgress, Dialog } from "@mui/material";
import { TFunction } from "i18next";
import { useSelector } from "react-redux";
import JobApplicationProcessForm from "../../../../components/JobDialogs/JobApplicationProcess";
import {
  TJobAdditionalQuestionsFormFields,
  TJobAdditionalQuestionsFormFieldsPayload,
} from "../../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../../models/SingleJob";
import { getJobEditorState } from "../../../../store/selectors/JobEditor";
import { getSingleJobState } from "../../../../store/selectors/SingleJob";
import { StyledDialogActions } from "../../styles";

const JobAdditionalQuestionsEditDialog = ({
  t,
  isOpen,
  onClose,
  onUpdateJob,
}: {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  onUpdateJob: (data: TJobAdditionalQuestionsFormFieldsPayload) => void;
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { jobQuestions } = useSelector(getSingleJobState);
  const { isLoading } = useSelector(getJobEditorState);

  const handleOnSubmit = (data: TJobAdditionalQuestionsFormFields) => {
    const apiPayload = {
      questions: data.questions.map((qItem) => ({
        question: qItem.question,
        answer: qItem.is_answer_type_yesno,
      })),
    };
    onUpdateJob(apiPayload);
  };

  return (
    <Dialog open={isOpen} maxWidth={"md"} fullWidth onClose={onClose}>
      {jobQuestions && (
        <JobApplicationProcessForm
          t={t}
          defaultValues={{ questions: jobQuestions }}
          selectedSections={[
            SELECTED_EDIT_SECTIONS.JOB_APPLICATION_PROCESS_ADDITIONAL_QUESTIONS,
          ]}
          formRef={formRef}
          onSubmit={handleOnSubmit}
        />
      )}
      <StyledDialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          {t("button.secondCancel")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          disabled={isLoading}
          onClick={() => formRef.current?.requestSubmit()}
        >
          {t("button.save")}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default JobAdditionalQuestionsEditDialog;
