import React, { useEffect } from "react";
import i18n from "i18next";
import { useMediaQuery } from "@mui/material";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material/styles";
import LanguageDetector from "i18next-browser-languagedetector";
import getRouter from "./router";
import translationDe from "./utils/translationDe";
import translationEn from "./utils/translationEn";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
  getCurrentUserJassId,
  getCurrentUserLoadingState,
} from "./store/selectors/CurrentUser";
import { fetchUser } from "./store/reducers/CurrentUser";
import { Dark } from "./layouts/Themes/Dark";
import { setIsCompanyAddressDialogOpen } from "./store/reducers/dialogs";
import { fetchTalentPools } from "./store/reducers/TalentPools";
import { fetchWorkflows } from "./store/reducers/Workflow";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en_US: { translation: translationEn },
      de_DE: { translation: translationDe },
    },
    fallbackLng: "de_DE",
    interpolation: { escapeValue: false },
  });

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const theme = createTheme(Dark as unknown as ThemeOptions);
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const jassId = useSelector(getCurrentUserJassId);
  const isLoading = useSelector(getCurrentUserLoadingState);

  const { username, isAgency, lang } = useSelector(getCurrentUserData);

  const defaultWorkflows = [
    {
      id: 0.1,
      defaultWorkflow: true,
      title: t("workflow.defaultWorkflow"),
      is_default: 1,
      created_at: "2024-08-09 15:05:56",
      updated_at: "2024-08-12 13:32:44",
      statuses: [
        {
          id: "0",
          label: t("status.new"),
          value: "new",
          color: "#818CF8",
          defaultStatus: true,
        },
        {
          id: "1",
          label: t("status.in_progress"),
          value: "review",
          color: "#F59E0B",
          defaultStatus: true,
        },
        {
          id: "2",
          label: t("status.hired"),
          value: "hired",
          color: "#22C55E",
          defaultStatus: true,
        },
        {
          id: "3",
          label: t("status.rejected"),
          value: "rejected",
          color: "#EF4444",
          defaultStatus: true,
        },
      ],
    },
  ];

  useEffect(() => {
    if (token && !username && !isLoading) {
      dispatch(fetchUser());
    }
  }, [token, username, isLoading]);

  useEffect(() => {
    if (username && isCandidate === false && !jassId && !isAgency) {
      dispatch(setIsCompanyAddressDialogOpen(true));
    } else {
      dispatch(setIsCompanyAddressDialogOpen(false));
    }
  }, [isCandidate, jassId, username]);

  useEffect(() => {
    if (isCandidate === false && username) {
      dispatch(fetchTalentPools());
    }
  }, [username, isCandidate]);

  useEffect(() => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    if (isCandidate === false) {
      dispatch(fetchWorkflows(defaultWorkflows));
    }
  }, [i18n.language, isCandidate]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider
        router={getRouter({
          desktop,
          t,
          token,
          isCandidate: isCandidate as boolean,
        })}
      />
    </ThemeProvider>
  );
};

export default App;
