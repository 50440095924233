import React from "react";
import { Pagination as MuiPagination, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TPagination, TRenderPagination } from "../../models/common";

const RenderDesktop = ({
  t,
  page,
  indexOfFirstItem,
  indexOfLastItem,
  itemsPerPage,
  totalPosts,
  paginate,
}: TRenderPagination) => (
  <Stack direction="row" width="100%" mt={3} px={1}>
    <Stack flex={1} justifyContent="center">
      <Typography variant="subtitle2" color="textSecondary">
        {t("showing")} {indexOfFirstItem + 1} -{" "}
        {totalPosts > itemsPerPage && itemsPerPage * page < totalPosts
          ? indexOfLastItem
          : totalPosts}{" "}
        {t("basic_job_board.of")} {totalPosts} {t("results")}
      </Typography>
    </Stack>
    <Stack flex={1} alignItems="flex-end">
      <MuiPagination
        count={Math.ceil(totalPosts / itemsPerPage) || 1}
        onChange={(e, currentPage) => {
          paginate(currentPage);
        }}
        page={page}
        color="secondary"
      />
    </Stack>
  </Stack>
);

const RenderMobile = ({
  t,
  page,
  indexOfFirstItem,
  indexOfLastItem,
  itemsPerPage,
  totalPosts,
  paginate,
}: TRenderPagination) => (
  <Stack width="100%" mt={3} spacing={2.25}>
    <Stack alignItems="center">
      <Typography variant="subtitle2" color="textSecondary">
        {t("showing")} {indexOfFirstItem + 1} -{" "}
        {totalPosts > itemsPerPage && itemsPerPage * page < totalPosts
          ? indexOfLastItem
          : totalPosts}{" "}
        {t("basic_job_board.of")} {totalPosts} {t("results")}
      </Typography>
    </Stack>
    <Stack alignItems="center">
      <MuiPagination
        count={Math.ceil(totalPosts / itemsPerPage) || 1}
        onChange={(e, currentPage) => {
          paginate(currentPage);
        }}
        page={page}
        color="secondary"
      />
    </Stack>
  </Stack>
);

export default function Pagination({
  desktop = true,
  page,
  itemsPerPage,
  totalPosts,
  paginate,
}: TPagination) {
  const { t } = useTranslation();
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  return desktop ? (
    <RenderDesktop
      t={t}
      page={page}
      indexOfFirstItem={indexOfFirstItem}
      indexOfLastItem={indexOfLastItem}
      itemsPerPage={itemsPerPage}
      totalPosts={totalPosts as number}
      paginate={paginate}
    />
  ) : (
    <RenderMobile
      t={t}
      page={page}
      indexOfFirstItem={indexOfFirstItem}
      indexOfLastItem={indexOfLastItem}
      itemsPerPage={itemsPerPage}
      totalPosts={totalPosts as number}
      paginate={paginate}
    />
  );
}
