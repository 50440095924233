import React from "react";
import { EyeIcon } from "@heroicons/react/24/outline";
import { TFunction } from "i18next";
import { StyledCandidateMessagesEmptyScreen } from "../styles";

const CandidateMessagesEmptyScreen = ({ t }: { t: TFunction }) => (
  <StyledCandidateMessagesEmptyScreen>
    <EyeIcon />
    <p>{t("candidate.no_notes")}</p>
  </StyledCandidateMessagesEmptyScreen>
);

export default CandidateMessagesEmptyScreen;
