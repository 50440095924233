import React from "react";
import Flag from "react-country-flag";
import {
  Divider,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import StyledSectionTitle from "../../../../components/SectionTitle";
import { IJodDetailsPreviewComponent } from "../../../../models/SingleJob";
import { getJobDetailsFields } from "./config";
import {
  StyledFieldsContainer,
  StyledFlagContainer,
  StyledSectionContainer,
} from "./styles";
import { StyledRadioButtonContainer } from "../../../../components/JobDialogs/styles";

const JobDetailsPreview = ({
  t,
  jobDetails,
  jobLocations,
  jobTypes,
  onEdit,
}: IJodDetailsPreviewComponent) => (
  <StyledSectionContainer spacing={2}>
    <StyledSectionTitle
      title={t("singleJob.jobDetails.titles.jobDetails")}
      onClick={onEdit}
    />
    <Divider />
    {jobLocations?.map((location, index) => (
      <Stack key={index} width="100%" direction="row" spacing={2}>
        <Stack flex={1}>
          <InputLabel>{t("update_company_information.country")}</InputLabel>
          <TextField
            className={"disabled-field"}
            value={location.nicename}
            placeholder={t("update_company_information.country") as string}
            fullWidth
            InputProps={{
              startAdornment: location?.country && (
                <StyledFlagContainer position="start">
                  <Flag countryCode={location.country as string} svg />
                </StyledFlagContainer>
              ),
            }}
          />
        </Stack>
        <Stack flex={1} direction="row" spacing={2}>
          <Stack flex={1}>
            <InputLabel>{t("update_company_information.city")}</InputLabel>
            <TextField
              className={"disabled-field"}
              value={location.city}
              placeholder={t("update_company_information.city") as string}
              fullWidth
            />
          </Stack>
          <Stack flex={1}>
            <InputLabel>{t("update_company_information.postcode")}</InputLabel>
            <TextField
              className={"disabled-field"}
              value={location.zip}
              placeholder={t("update_company_information.postcode") as string}
              fullWidth
            />
          </Stack>
        </Stack>
      </Stack>
    ))}
    <RadioGroup>
      <Stack direction={"row"} spacing={3}>
        {jobTypes.map((jobType) => (
          <StyledRadioButtonContainer
            sx={{ cursor: "default" }}
            key={jobType.id}
          >
            <FormControlLabel
              disabled
              value={jobType.id}
              control={
                <Radio
                  size={"small"}
                  checked={jobDetails?.job_type_id === jobType.id}
                />
              }
              label={t(
                `create_job_second_step.jobTypes.${jobType.title.toLowerCase()}`
              )}
            />
          </StyledRadioButtonContainer>
        ))}
      </Stack>
    </RadioGroup>
    {getJobDetailsFields({ t, jobDetails }).map((fieldsRow) => (
      <Stack direction={"row"} key={jobDetails?.id} spacing={2}>
        {fieldsRow.map((field) => (
          <StyledFieldsContainer
            key={field.placeholder}
            fullWidth={fieldsRow.length === 2}
          >
            <InputLabel>{field.placeholder}</InputLabel>
            <TextField
              className={"disabled-field"}
              value={field.value}
              placeholder={field.placeholder as string}
              fullWidth
              InputProps={{
                startAdornment: field?.flags && (
                  <StyledFlagContainer position="start">
                    <Flag countryCode={field.flags[0] as string} svg />
                  </StyledFlagContainer>
                ),
              }}
            />
          </StyledFieldsContainer>
        ))}
      </Stack>
    ))}
  </StyledSectionContainer>
);

export default JobDetailsPreview;
