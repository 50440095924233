import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import {
  ClockIcon,
  EyeIcon,
  CursorArrowRaysIcon,
} from "@heroicons/react/24/outline";
import {
  IDataGridIconAndText,
  IDataGridRenderIcon,
} from "../../../models/DataGrid";

const avatarStyle = {
  width: 32,
  height: 32,
};

const imageStyle = {
  height: 24,
  minWidth: 88,
  maxWidth: 88,
};

const RenderIcon = ({ variant, src, iconColor }: IDataGridRenderIcon) => {
  switch (variant) {
    case "clock":
      return (
        <ClockIcon width={20} height={20} stroke="#6B7280" strokeWidth={1.25} />
      );
    case "views":
      return (
        <EyeIcon width={20} height={20} stroke="#6B7280" strokeWidth={1.25} />
      );
    case "clicks":
      return (
        <CursorArrowRaysIcon
          width={20}
          height={20}
          stroke="#6B7280"
          strokeWidth={1.25}
        />
      );
    case "photo":
      return <img src={src} style={imageStyle} alt="logo" />;
    case "avatar":
      return <Avatar style={avatarStyle} src={src} alt="logo" />;
    case "dot":
      return (
        <Stack
          mr={1}
          height={8}
          width={8}
          borderRadius="50%"
          sx={{
            backgroundColor: iconColor,
          }}
        />
      );
    default:
      return <></>;
  }
};

const iconAndText = ({
  text,
  variant,
  src,
  iconColor = "#EF4444",
}: IDataGridIconAndText) => (
  <Stack direction="row" spacing={1} alignItems="center">
    {/*TODO wait for BE fix , imgSrc should be an empty string/null , in other case it should return correct src*/}
    {variant === "photo" ? (
      src &&
      src !== "https://i.staging.karriera.de/uploads/companies/" && (
        <RenderIcon variant={variant} src={src} iconColor={iconColor} />
      )
    ) : (
      <RenderIcon variant={variant} src={src} iconColor={iconColor} />
    )}
    <Typography
      variant="subtitle2"
      color="textSecondary"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {text}
    </Typography>
  </Stack>
);

export default iconAndText;
