import React from "react";
import { Stack, Typography, Divider, Button, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import Loading from "../../../../components/Loading";
import AutocompleteFilter from "../../../../components/filters/AutocompleteFilter";
import { IApplicationCreatedTriggerComponent } from "../../../../models/Automation";

const ApplicationCreatedTriggerComponent = ({
  t,
  jobs,
  currentAutomation,
  isLoading,
  selectedJobs,
  control,
  errors,
  onChangeJobs,
  onSubmit,
  handleSubmit,
}: IApplicationCreatedTriggerComponent) =>
  isLoading ? (
    <Loading />
  ) : (
    <Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Stack
            mt="0px !important"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={16} fontWeight={500}>
              {currentAutomation?.trigger?.name}
            </Typography>
          </Stack>
          <Divider />
          <Stack spacing={0.25}>
            <InputLabel>{t("jobs.navigation.all_jobs")}</InputLabel>
            <Controller
              name="jobs"
              control={control}
              render={() => (
                <AutocompleteFilter
                  listBoxProps={{
                    className: "multiple-selection-autocomplete",
                  }}
                  multiple
                  size="small"
                  placeholder="Jobs"
                  options={jobs}
                  value={selectedJobs}
                  errors={errors.jobs}
                  onChange={onChangeJobs}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack
          mt={2}
          direction="row"
          width="100%"
          justifyContent="flex-end"
          spacing={1.5}
        >
          <Button type="submit" variant="contained">
            {t("button.save")}
          </Button>
        </Stack>
      </form>
    </Stack>
  );

export default ApplicationCreatedTriggerComponent;
