import React, { useMemo } from "react";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import NoArchivedJobsImg from "../../../assets/no_archived_jobs.png";
import NoJobsImg from "../../../assets/no_jobs.png";
import JobListNoResultComponent from "./component";
import { IJobListState, JOB_STATUSES } from "../../../models/JobList";
import { resetJobsFilters } from "../../../store/reducers/JobList";
import {
  getCurrentStatusJobListFiltersState,
  getJobListNavigationState,
} from "../../../store/selectors/JobList";
import { fetchJobData, openJobEditor } from "../../../store/reducers/JobEditor";
import { setIsLimitDialogOpen } from "../../../store/reducers/dialogs";
import { getCompanySettingsCompanyState } from "../../../store/selectors/Company";
import { getCurrentUserData } from "../../../store/selectors/CurrentUser";
import {
  getJobEditorJobDataState,
  getJobEditorState,
} from "../../../store/selectors/JobEditor";

const JobListNoResultOverlay = ({
  t,
  onOpenCustomerDialog,
}: {
  readonly t: TFunction;
  readonly onOpenCustomerDialog: () => void;
}) => {
  const dispatch = useDispatch();
  const { selectedJobStatus }: IJobListState["navigation"] = useSelector(
    getJobListNavigationState
  );

  const jobData = useSelector(getJobEditorJobDataState);
  const { jobDataError } = useSelector(getJobEditorState);
  const { isAgency } = useSelector(getCurrentUserData);
  const { company } = useSelector(getCompanySettingsCompanyState);
  const { jobType, seniority, quickSearch, locations } = useSelector(
    getCurrentStatusJobListFiltersState
  );

  const hasFilters = useMemo(
    () => Boolean(jobType.value || seniority.value || quickSearch || locations),
    [jobType, seniority, quickSearch, locations]
  );

  const actionText = useMemo(
    () => t(`joblist.${hasFilters ? "reset_filters_button" : "empty_button"}`),
    [hasFilters]
  );

  const bodyText = useMemo(
    () =>
      hasFilters
        ? ""
        : selectedJobStatus === "active"
          ? t("joblist.empty_second_text")
          : "",
    [hasFilters]
  );

  const titleText = useMemo(
    () =>
      t(
        `joblist.${
          hasFilters
            ? "empty_first_text_filters_exists"
            : selectedJobStatus === "active"
              ? "empty_first_text"
              : selectedJobStatus === "archived"
                ? "no_archived_job_title"
                : "no_draft_job_title"
        }`
      ),
    [hasFilters]
  );
  const imgSrc = useMemo(
    () =>
      selectedJobStatus === JOB_STATUSES.ACTIVE ? NoJobsImg : NoArchivedJobsImg,
    [selectedJobStatus]
  );

  const onFetchJobData = () => {
    if (!jobDataError) {
      if (isAgency) {
        onOpenCustomerDialog();
      } else if (company?.jobs_creation_allowed) {
        dispatch(openJobEditor());
      } else {
        dispatch(setIsLimitDialogOpen(true));
      }
    }
  };

  const handleOnOpenCreateJobDialog = () => {
    if (Object.keys(jobData).length < 1) {
      dispatch(
        fetchJobData({
          callback: onFetchJobData,
        })
      );
    } else {
      if (isAgency) {
        onOpenCustomerDialog();
      } else if (company?.jobs_creation_allowed) {
        dispatch(openJobEditor());
      } else {
        dispatch(setIsLimitDialogOpen(true));
      }
    }
  };

  const handleOnResetFilters = () => {
    dispatch(resetJobsFilters());
  };

  return (
    <JobListNoResultComponent
      actionText={actionText}
      bodyText={bodyText}
      hasFilters={hasFilters}
      titleText={titleText}
      imgSrc={imgSrc}
      onResetFilters={handleOnResetFilters}
      onToggleDialog={handleOnOpenCreateJobDialog}
      showButton={selectedJobStatus === "active" ? true : false}
    />
  );
};

export default JobListNoResultOverlay;
