import React from "react";
import PhoneInput from "react-phone-input-2";
import { Box, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { TFunction } from "i18next";
import { StyledAdditionalQuestionsFieldContainer } from "../styles";
import { IAdditionalQuestionSimpleSection } from "../../../models/JobPreview";

const AdditionalQuestionsPhone = ({
  t,
  desktop,
  control,
}: IAdditionalQuestionSimpleSection & { readonly t: TFunction }) => (
  <StyledAdditionalQuestionsFieldContainer desktop={desktop}>
    <Stack className={"title"}>
      <Typography variant="subtitle2">
        {t("additional_questions.phone_number")}
      </Typography>
    </Stack>
    <Controller
      name={"phone"}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box className={"input-field"}>
          <PhoneInput
            containerStyle={{ width: "100%" }}
            inputStyle={{
              width: "100%",
              padding: "10px 16px 10px 60px",
              borderColor: "#E5E7EB",
            }}
            country={"de"}
            value={value}
            onChange={onChange}
            preferredCountries={["de", "at", "ch"]}
          />
        </Box>
      )}
    />
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsPhone;
