import React from "react";
import {
  IconButton,
  InputLabel,
  Stack,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Controller } from "react-hook-form";
import TextEditorCustomToolbar from "../../../../components/TextEditorCustomToolbar";
import { TListOption } from "../../../../models/common";
import { ICreateNoteComponent } from "../../../../models/Automation";

const CreateNoteComponent = ({
  t,
  formattedVariables,
  errors,
  control,
  selectedAction,
  onCloseAction,
  onSubmit,
  handleSubmit,
}: ICreateNoteComponent) => (
  <Stack
    width="100%"
    padding="0px 16px 16px 16px"
    borderRadius={1.5}
    sx={{ background: "#fff" }}
  >
    <Stack spacing={2}>
      <Stack
        mt="0px !important"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize={16} fontWeight={500}>
          {selectedAction?.name}
        </Typography>
        <Stack height="100%" alignItems="center">
          <IconButton onClick={onCloseAction}>
            <XMarkIcon stroke="#111827" width={20} height={20} />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack mt={2} spacing={0.25}>
        <InputLabel className="required-label">
          {t("automation.content")}
        </InputLabel>
        <Controller
          name="message"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextEditorCustomToolbar
              isOnlyVariables
              items={formattedVariables as unknown as TListOption[]}
              value={value}
              onChange={onChange}
              errorMessage={errors?.message?.message as string}
            />
          )}
        />
      </Stack>
      <Stack
        mt={2}
        direction="row"
        width="100%"
        justifyContent="flex-end"
        spacing={1.5}
      >
        <Button variant="outlined" onClick={onCloseAction}>
          {t("button.secondCancel")}
        </Button>
        <Button variant="contained" type="submit">
          {t("button.save")}
        </Button>
      </Stack>
    </form>
  </Stack>
);

export default CreateNoteComponent;
