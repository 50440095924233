import React, { useMemo, useState } from "react";
import { Typography } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TTextEditor } from "../../models/common";

const TextEditor = ({ ref, value, errorMessage, onChange }: TTextEditor) => {
  const [isFocused, setIsFocused] = useState(false);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleOnChange = (value: string) => {
    setRTEValue(value);
    onChange(value);
  };

  const [RTEValue, setRTEValue] = useState(value);

  const defaultValue = useMemo(() => value, [value]);

  return (
    <>
      <ReactQuill
        ref={ref}
        className={
          errorMessage
            ? "quill-container quill-error"
            : isFocused
              ? "quill-container quill-focused"
              : "quill-container"
        }
        modules={modules}
        value={RTEValue || defaultValue}
        onChange={handleOnChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        theme="snow"
      />
      {errorMessage && (
        <Typography color="error" variant="subtitle2">
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default TextEditor;
