import React from "react";
import { Stack, Tab, Tabs } from "@mui/material";
import ApplyJobDialog from "../../components/dialogs/ApplyJobDialog";
import SelectCustomerDialog from "../../components/dialogs/SelectCustomerDialog";
import DeleteJobModal from "./DeleteJobModal";
import DataGrid from "../../components/DataGrid";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import JobListFilters from "./filters";
import JobListToolbar from "./JobListToolbar";
import JobListNoResultOverlay from "./NoResultOverlay";
import { DIALOG_TYPE } from "../../models/common";
import {
  DATA_GRID_LIST_TYPE,
  IDataGrid,
  TDataGridActions,
} from "../../models/DataGrid";
import { IJobListComponent, JOB_STATUSES } from "../../models/JobList";
import { StyledJobsContainer, StyledJobsTabsContainer } from "./styles";

const JobListComponent = ({
  t,
  actions,
  openedDialogType,
  selectedJobStatus,
  jobStatuses,
  jobList,
  isLoading,
  isListLoading,
  pageNum,
  pageSize,
  jobCount,
  isAgency,
  isApplyJobDialogOpen,
  onCloseApplyJobDialog,
  onCloseCustomerDialog,
  onOpenApplyJobDialog,
  onPaginate,
  onCloseDialog,
  onSelect,
  onTabChange,
  onConfirmDeleteJob,
  onOpenCustomerDialog,
}: IJobListComponent) => (
  <StyledJobsContainer data-testid={"jobs-list-component-wrapper"}>
    <StyledJobsTabsContainer>
      <Tabs value={selectedJobStatus} onChange={onTabChange}>
        {jobStatuses.map((tab: JOB_STATUSES, index: number) => (
          <Tab key={tab + index} label={t(tab)} value={tab} />
        ))}
      </Tabs>
    </StyledJobsTabsContainer>
    <JobListFilters t={t} isAgency={isAgency} />
    {selectedJobStatus === JOB_STATUSES.ACTIVE && (
      <JobListToolbar
        t={t}
        onArchive={actions.onArchiveRestore}
        onExport={actions.onExport}
        onPreview={actions.onPreview}
        onAddApplication={onOpenApplyJobDialog}
      />
    )}
    {isListLoading ? (
      <Loading />
    ) : jobList?.length ? (
      <Stack
        data-testid={"jobs-list-data-grid-wrapper"}
        mt={selectedJobStatus === JOB_STATUSES.ACTIVE ? 0 : 1.5}
        height={"100%"}
      >
        {isAgency ? (
          <DataGrid
            t={t}
            list={jobList}
            type={
              selectedJobStatus === JOB_STATUSES.ACTIVE
                ? DATA_GRID_LIST_TYPE.AGENCY_JOB_LIST_ACTIVE
                : selectedJobStatus === JOB_STATUSES.ARCHIVED
                  ? DATA_GRID_LIST_TYPE.AGENCY_JOB_LIST_ARCHIVED
                  : DATA_GRID_LIST_TYPE.AGENCY_JOB_LIST_DRAFT
            }
            actions={actions as TDataGridActions}
            onSelect={onSelect as IDataGrid["onSelect"]}
          />
        ) : (
          <DataGrid
            t={t}
            list={jobList}
            type={
              selectedJobStatus === JOB_STATUSES.ACTIVE
                ? DATA_GRID_LIST_TYPE.JOB_LIST_ACTIVE
                : selectedJobStatus === JOB_STATUSES.ARCHIVED
                  ? DATA_GRID_LIST_TYPE.JOB_LIST_ARCHIVED
                  : DATA_GRID_LIST_TYPE.JOB_LIST_DRAFT
            }
            actions={actions as TDataGridActions}
            onSelect={onSelect as IDataGrid["onSelect"]}
          />
        )}
        <Pagination
          totalPosts={jobCount}
          itemsPerPage={pageSize}
          page={pageNum}
          paginate={onPaginate}
        />
      </Stack>
    ) : (
      <JobListNoResultOverlay
        t={t}
        onOpenCustomerDialog={onOpenCustomerDialog}
      />
    )}
    <DeleteJobModal
      t={t}
      isLoading={isLoading}
      isOpen={openedDialogType === DIALOG_TYPE.DELETE_JOB}
      onClose={onCloseDialog}
      onConfirmDeleteJob={onConfirmDeleteJob}
    />
    <ApplyJobDialog
      isOpen={isApplyJobDialogOpen}
      onCancel={onCloseApplyJobDialog}
    />
    <SelectCustomerDialog
      t={t}
      onClose={onCloseCustomerDialog}
      onSave={() => {}}
    />
  </StyledJobsContainer>
);

export default JobListComponent;
