import React from "react";
import { Typography } from "@mui/material";
import { ICandidateItemElementComponent } from "../../models/Kanban";
import { StyledCandidateItemElement } from "./style";

const CandidateItemElement = ({
  icon,
  text,
}: ICandidateItemElementComponent) => (
  <StyledCandidateItemElement>
    {icon}
    <Typography
      variant="subtitle2"
      color="textSecondary"
      maxWidth={150}
      textOverflow="ellipsis"
      overflow="hidden"
    >
      {text}
    </Typography>
  </StyledCandidateItemElement>
);

export default CandidateItemElement;
