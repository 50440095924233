import React, { useEffect } from "react";
import TalentPoolsComponent from "./component";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTalentPoolState } from "../../store/selectors/Talentpool";
import {
  fetchTalentPools,
  setEditedTalentPool,
  setSelectedTalentPool,
  setTalentPoolsQuickSearch,
} from "../../store/reducers/TalentPools";
import { DIALOG_TYPE } from "../../models/common";
import {
  setIsLimitDialogOpen,
  setIsTalentPoolsDialogOpen,
} from "../../store/reducers/dialogs";
import { ITalentPool } from "../../models/TalentPools";
import { getCompanySettingsCompanyState } from "../../store/selectors/Company";

const TalentPools = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { talentPools, isListLoading, talentPoolsQuickSearch } =
    useSelector(getTalentPoolState);

  const { company } = useSelector(getCompanySettingsCompanyState);

  const handleOpenCreateTalentPoolDialog = (data: ITalentPool | null) => {
    if (data) {
      dispatch(setEditedTalentPool(data));
      dispatch(setIsTalentPoolsDialogOpen(DIALOG_TYPE.CREATE_EDIT_TALENT_POOL));
    } else if (company?.talentpools_creation_allowed) {
      dispatch(setIsTalentPoolsDialogOpen(DIALOG_TYPE.CREATE_EDIT_TALENT_POOL));
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  const handleOnOpenDeleteTalentPoolDialog = (data: unknown) => {
    if (data) {
      dispatch(setEditedTalentPool(data as ITalentPool));
    }
    dispatch(setIsTalentPoolsDialogOpen(DIALOG_TYPE.DELETE_TALENT_POOL));
  };

  const handleOnSelectTalentPool = (data: ITalentPool) => {
    navigate(`/talentpool/${data.id}`);
    dispatch(setSelectedTalentPool(data));
  };

  const handleOnChangeTalentPoolFilter = (data: {
    readonly value: string;
    readonly filterType: keyof {
      readonly quickSearch: string;
    };
  }) => {
    dispatch(setTalentPoolsQuickSearch(data.value));
  };

  useEffect(() => {
    dispatch(fetchTalentPools());
  }, [talentPoolsQuickSearch]);

  return (
    <TalentPoolsComponent
      t={t}
      talentPools={talentPools}
      isListLoading={isListLoading}
      quickSearch={talentPoolsQuickSearch}
      onChangeFilters={handleOnChangeTalentPoolFilter}
      onSelectTalentPool={handleOnSelectTalentPool}
      onOpenCreateTalentPoolDialog={handleOpenCreateTalentPoolDialog}
      onOpenDeleteTalentPoolDialog={handleOnOpenDeleteTalentPoolDialog}
    />
  );
};

export default TalentPools;
