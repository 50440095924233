import * as yup from "yup";
import { TFunction } from "i18next";
import { TCompanyRecruiterFormFields } from "../../../models/Registration";

export const getFormSettings = (t: TFunction) => {
  return yup.object().shape({
    firstname: yup.string().required(t("register.message") as string),
    lastname: yup.string().required(t("register.message") as string),
    phone: yup
      .string()
      .min(3, t("register.message") as string)
      .required(t("register.message") as string),
    email: yup
      .string()
      .required(t("register.message") as string)
      .email(t("invalid_email_message") as string),
    password: yup.string().required(t("register.message") as string),
    checked: yup.bool().oneOf([true], t("accept_terms_text") as string),
  });
};

export const formDefaultValues: TCompanyRecruiterFormFields = {
  firstname: "",
  lastname: "",
  phone: "",
  email: "",
  password: "",
  checked: false,
};
