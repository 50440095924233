import React, { useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { Stack, Typography } from "@mui/material";
import { ChromePicker, ColorResult } from "react-color";
import { useSelector, useDispatch } from "react-redux";
import { updateJobDescription } from "../../store/reducers/JobEditor";
import { getJobEditorState } from "../../store/selectors/JobEditor";
import { setIsColorPickerOpen } from "../../store/reducers/dialogs";
import { StyledColorBlock, StyledPickerContainer } from "../ColorPicker/styles";
import { StyledBigColorPickerContainer } from "./styles";
import { TBigColorPicker } from "../../models/common";

const BigColorPicker = ({
  color,
  title,
  value,
  customStyle,
  isDefaultColor = false,
  onChange,
}: TBigColorPicker) => {
  const dispatch = useDispatch();

  const [isDisplayed, setIsDisplayed] = useState(false);
  const [currentColor, setCurrentColor] = useState(color);

  const { jobDescription } = useSelector(getJobEditorState);

  const handleOnChange = (color: ColorResult) => {
    setCurrentColor(color.hex);
    if (onChange) onChange(color.hex);
    if (value) {
      dispatch(
        updateJobDescription({
          jobDescription: {
            ...jobDescription,
            [value]: color.hex,
          },
        })
      );
    }
  };

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  useEffect(() => {
    dispatch(setIsColorPickerOpen(isDisplayed));
  }, [isDisplayed]);

  return (
    <StyledBigColorPickerContainer>
      <Stack
        width="100%"
        height="100%"
        spacing={1}
        p={1.5}
        justifyContent="center"
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          direction="row"
          height="100%"
          px={1}
          spacing={1}
        >
          <StyledColorBlock
            width="20px !important"
            height="20px !important"
            bgColor={currentColor}
            onClick={() => {
              if (!isDefaultColor && onChange) setIsDisplayed(true);
            }}
            isEditMode={Boolean(onChange)}
          />

          <Typography variant="subtitle2" color="textSecondary">
            {title}
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          direction="row"
          height="100%"
          px={1}
        >
          <Typography variant="subtitle2" color="textSecondary">
            {currentColor}
          </Typography>
        </Stack>
      </Stack>
      <StyledPickerContainer style={customStyle}>
        {isDisplayed && (
          <ClickAwayListener
            onClickAway={() => {
              setIsDisplayed(false);
            }}
          >
            <Stack>
              <ChromePicker color={currentColor} onChange={handleOnChange} />
            </Stack>
          </ClickAwayListener>
        )}
      </StyledPickerContainer>
    </StyledBigColorPickerContainer>
  );
};

export default BigColorPicker;
