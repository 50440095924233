import React from "react";
import {
  Dialog,
  Stack,
  DialogActions,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ISaveDraftDialog } from "../../../models/dialogs";

const SaveDraftDialog = ({
  t,
  isOpen,
  title,
  paragraphs,
  cancelButtonText = t("button.secondCancel") as string,
  submitButtonText,
  onClose,
  onSave,
}: ISaveDraftDialog) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    PaperProps={{
      elevation: 0,
    }}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          minWidth: 512,
          p: "16px 24px",
        },
      },
    }}
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <Stack>
        <Typography fontWeight={500}>{title}</Typography>
      </Stack>
      <Stack>
        <XMarkIcon
          width={24}
          height={24}
          stroke="#131E30"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        />
      </Stack>
    </Stack>
    <Divider />
    <Stack my={2.5} spacing={3}>
      {paragraphs.map((item: string, index: number) => (
        <Typography key={index} variant="subtitle2" color="textSecondary">
          {item}
        </Typography>
      ))}
    </Stack>
    <DialogActions>
      <Button variant="outlined" onClick={onClose} autoFocus>
        {cancelButtonText}
      </Button>
      <Button variant="contained" onClick={onSave}>
        {submitButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default SaveDraftDialog;
