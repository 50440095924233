import React, { useMemo, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import ArchiveJobComponent from "./component";
import {
  IArchiveJobFormField,
  IAutomationAction,
  ISelectedActionComponent,
} from "../../../../models/Automation";
import {
  deleteArchiveJobFormFieldsDefaultValue,
  getDeleteArchiveJobFormFields,
} from "./config";
import { getAutomationState } from "../../../../store/selectors/Automation";
import {
  fetchSelectedActionData,
  setActions,
  setSelectedAction,
} from "../../../../store/reducers/Automation";
import { TListOption } from "../../../../models/common";

const DeleteArchiveJob = ({
  t,
  selectedAction,
  onCloseAction,
}: ISelectedActionComponent) => {
  const dispatch = useDispatch();

  const { currentAutomation, selectedActionData } =
    useSelector(getAutomationState);

  const jobs = useMemo(() => {
    if (selectedActionData?.jobs) {
      const formattedJobs = Object.entries(selectedActionData.jobs).map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([value, label]) => ({
          value,
          label,
        })
      );
      return formattedJobs;
    }

    return [];
  }, [selectedActionData]);

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    defaultValues: deleteArchiveJobFormFieldsDefaultValue,
    resolver: yupResolver(getDeleteArchiveJobFormFields(t)),
  });

  const onSubmit = (data: IArchiveJobFormField) => {
    const updatedActions = currentAutomation.actions.map(
      (action: IAutomationAction) => {
        if (action.id === selectedAction?.id) {
          return {
            ...selectedAction,
            actionCompleted: true,
            params: data,
          };
        }
        return action;
      }
    );
    dispatch(setActions(updatedActions as IAutomationAction[]));
    dispatch(setSelectedAction(undefined));
  };

  useEffect(() => {
    if (selectedAction?.params.job_id !== "int") {
      setValue("job_id", selectedAction?.params.job_id as string);
    }
  }, [selectedAction]);

  useEffect(() => {
    if (selectedActionData && !Object.keys(selectedActionData?.jobs).length) {
      dispatch(fetchSelectedActionData("jobs"));
    }
  }, []);

  return (
    <ArchiveJobComponent
      t={t}
      jobs={jobs as unknown as TListOption[]}
      selectedAction={selectedAction}
      errors={errors}
      control={control}
      onCloseAction={onCloseAction}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
    />
  );
};

export default DeleteArchiveJob;
