import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ChipsManager from "../../ChipsManager";
import { ITeamsInviteDialog } from "../../../models/Team";
import { emailValidation } from "../../../utils/textField";

const InviteDialog = ({
  t,
  title,
  isLoading,
  isOpen,
  emailsToInvite,
  onCancel,
  onEmailsChange,
  onInvite,
}: ITeamsInviteDialog) => (
  <Dialog open={isOpen} onClose={onCancel} fullWidth>
    <DialogTitle sx={{ p: 3 }}>{title}</DialogTitle>
    <DialogContent sx={{ pb: 0, overflow: "hidden" }}>
      <DialogContentText>
        <ChipsManager
          chipList={[]}
          validation={{
            validationFunc: emailValidation,
            errorMessage: t("team_members.invalid_email"),
          }}
          placeholder={t("team_members.email")}
          onChange={onEmailsChange}
        />
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{ p: 3 }}>
      <Button variant={"outlined"} onClick={onCancel} disabled={isLoading}>
        {t("button.secondCancel")}
      </Button>
      <Button
        endIcon={
          isLoading ? (
            <CircularProgress
              size={16}
              style={{
                color: "#9A9FAF",
              }}
            />
          ) : null
        }
        variant={"contained"}
        onClick={onInvite}
        disabled={isLoading || !emailsToInvite.length}
      >
        {t("team_members.invite")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default InviteDialog;
