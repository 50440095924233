import { TFunction } from "i18next";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ISingleJobState } from "../../../../models/SingleJob";
import { setProductsFilter } from "../../../../store/reducers/SingleJob";
import {
  getSingleJobFilteredFilterOptions,
  getSingleJobState,
} from "../../../../store/selectors/SingleJob";
import ProductsFilterComponent from "./component";

const ProductsFilter = ({
  t,
  isJobEditorPage,
  isPremiumJobBoardsTab = false,
}: {
  readonly t: TFunction;
  readonly isJobEditorPage: boolean;
  readonly isPremiumJobBoardsTab?: boolean;
}) => {
  const dispatch = useDispatch();
  const { filterValue, filterOptions } = useSelector(getSingleJobState);
  const { industries, countries } = useSelector(
    getSingleJobFilteredFilterOptions
  );

  const translatedDurationOptions = useMemo(() => {
    return filterOptions.durations.map((option) => ({
      ...option,
      label: t(`create_job_fourth_step.filterDurations.${option.label}`),
    }));
  }, [t, filterOptions.durations]);

  const handleOnChangeFilter = ({
    type,
    value,
  }: {
    type: keyof ISingleJobState["filterValue"];
    value: string;
  }) => {
    dispatch(setProductsFilter({ type, value }));
  };

  return (
    <ProductsFilterComponent
      t={t}
      onChange={handleOnChangeFilter}
      filterValue={filterValue}
      countries={countries}
      industries={industries}
      isJobEditorPage={isJobEditorPage}
      isPremiumJobBoardsTab={isPremiumJobBoardsTab}
      durations={translatedDurationOptions}
    />
  );
};

export default ProductsFilter;
