import React from "react";
import { Box, InputLabel, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../filters/AutocompleteFilter";
import { StyledSubSectionContainer } from "../styles";
import { IJobDetailsGeneralInformationComponent } from "../../../models/JobEditor";

const GeneralSection = ({
  t,
  errors,
  control,
  contractTypes,
  workFields,
  positionTypes,
  seniority,
  industries,
  qualifications,
}: IJobDetailsGeneralInformationComponent) => (
  <>
    <Typography variant="body2">
      {t("create_job_second_step.general_information")}
    </Typography>
    <StyledSubSectionContainer spacing={2}>
      <Stack direction={"row"} spacing={2}>
        <Box sx={{ flex: 1 }}>
          <InputLabel>
            {t("create_job_second_step.contract_type") as string}
          </InputLabel>
          <Controller
            name={"contract_type"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                onChange={onChange}
                value={value}
                options={contractTypes}
                placeholder={t("create_job_second_step.contract_type")}
                errors={errors["contract_type"]}
              />
            )}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <InputLabel>{t("create_job_second_step.field_of_work")}</InputLabel>
          <Controller
            name={"field_of_work"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                onChange={onChange}
                value={value}
                options={workFields}
                placeholder={t("create_job_second_step.field_of_work")}
                errors={errors["field_of_work"]}
              />
            )}
          />
        </Box>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Box sx={{ flex: 1 }}>
          <InputLabel>
            {t("create_job_second_step.seniority") as string}
          </InputLabel>
          <Controller
            name={"seniority"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                onChange={onChange}
                value={value}
                options={seniority}
                placeholder={t("create_job_second_step.seniority")}
                errors={errors["seniority"]}
              />
            )}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <InputLabel>{t("create_job_second_step.job_type")}</InputLabel>
          <Controller
            name={"position_type"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                onChange={onChange}
                value={value}
                options={positionTypes}
                placeholder={t("create_job_second_step.job_type")}
                errors={errors["position_type"]}
              />
            )}
          />
        </Box>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <Box sx={{ flex: 1 }}>
          <InputLabel>{t("company_settings.industry")}</InputLabel>
          <Controller
            name={"industry"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                onChange={onChange}
                value={value}
                options={industries}
                placeholder={t("company_settings.industry")}
                errors={errors["industry"]}
              />
            )}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <InputLabel>
            {t("create_job_second_step.qualification_level") as string}
          </InputLabel>
          <Controller
            name={"qualification"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                onChange={onChange}
                value={value}
                options={qualifications}
                placeholder={t("create_job_second_step.qualification_level")}
                errors={errors["qualification"]}
              />
            )}
          />
        </Box>
      </Stack>
    </StyledSubSectionContainer>
  </>
);

export default GeneralSection;
