import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Autocomplete,
  Typography,
  Avatar,
  Tooltip,
  Stack,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import StatusSelectorCell from "../../DataGrid/cellRenderers/statusSelector";
import { IApplicantsListDialog } from "../../../models/dialogs";
import {
  getAllApplications,
  getSelectedJobApplications,
} from "../../../store/selectors/Applications";
import { TApplication } from "../../../models/Applications";
import { StyledTextfield } from "./style";
import {
  StyledSubtitleTypography,
  StyledJobTitleTypography,
  StyledChatTextarea,
} from "../../../pages/Messages/styles";
import { getMessagesState } from "../../../store/selectors/Messages";
import { TLatestMessage } from "../../../models/Messages";
import { postMessage, setActiveUser } from "../../../store/reducers/Messages";
import { generateFullDate } from "../../../utils";
import { fetchAllApplications } from "../../../store/reducers/Applications";
import { IWorkflow } from "../../../models/Workflow";
import { getWorkflowState } from "../../../store/selectors/Workflow";

const MessageDialog = ({
  t,
  isOpen,
  title,
  onClose,
}: IApplicantsListDialog) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedUser, setSelectedUser] = useState<TApplication>();
  const [message, setMessage] = useState<string>("");
  const allApplications = useSelector(getAllApplications);
  const { latestMessages } = useSelector(getMessagesState);

  const currentApplication = useSelector(getSelectedJobApplications)?.[0];
  const { workflows } = useSelector(getWorkflowState);

  const filteredApplications = allApplications?.filter(
    (application: TApplication) => {
      if (latestMessages) {
        return latestMessages.every(
          (message: TLatestMessage) =>
            message.candidate_id != application.candidate_id
        );
      }
    }
  );

  const allStatuses = Array.from(
    new Set(
      workflows?.flatMap((workflow: IWorkflow) =>
        workflow?.statuses?.map((status) => ({
          label: status.label,
          value: status.value,
          color: status.color,
        }))
      )
    )
  );

  const selectedUserStatus = allStatuses.find(
    (status) => status.value === selectedUser?.status
  );

  const getOptionLabel = (option: TApplication) => {
    const { firstname, lastname } = option;
    return firstname ? `${firstname} ${lastname}` : "";
  };

  const changeSelectedUser = (
    e: React.SyntheticEvent,
    value: TApplication | null
  ) => {
    const currentValue = value as TApplication;
    setSelectedUser(currentValue || null);
  };

  const findStatusLabel = (value: string) => {
    const findStatus = allStatuses.find((status) => status.value === value);
    return findStatus?.label;
  };

  const sendMessage = () => {
    const activeUserPayload = {
      recruiter_id: Number(selectedUser?.candidate_id),
      application_id: Number(selectedUser?.application_id),
      job_id: Number(selectedUser?.job_id),
    };
    dispatch(setActiveUser(activeUserPayload));
    dispatch(
      postMessage({
        job_id: selectedUser?.job_id,
        application_id: selectedUser?.application_id,
        candidate_id: selectedUser?.candidate_id,
        text: message.trim(),
      })
    );
    if (location.pathname !== "/messages") {
      navigate("/messages");
    }
    onClose();
  };

  useEffect(() => {
    if (isOpen && currentApplication) {
      setSelectedUser(currentApplication as unknown as TApplication);
    }
  }, [isOpen, currentApplication]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAllApplications());
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: 544,
            minHeight: currentApplication ? 290 : 270,
            overflow: "hidden",
          },
          "& .MuiAutocomplete-option": {
            height: 64,
          },
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Stack p={3} pt={0} spacing={3}>
        {!currentApplication && (
          <Autocomplete
            slotProps={{
              listbox: {
                className: "applicants-list-dialog",
              },
            }}
            disablePortal
            noOptionsText={t("no_options")}
            options={filteredApplications as []}
            onChange={changeSelectedUser}
            getOptionLabel={getOptionLabel}
            renderOption={(optionProps, option) => (
              <li {...optionProps} translate="no" key={option.application_id}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                  height={64}
                >
                  <Stack
                    direction="row"
                    height="100%"
                    alignItems="center"
                    spacing={1.5}
                  >
                    <Avatar src={option.photo} alt="candidate-photo" />
                    <Stack justifyContent="space-between">
                      <Typography
                        fontSize={16}
                        color="#131E30"
                        maxWidth={280}
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                      >
                        {option.firstname} {option.lastname}
                      </Typography>
                      <Stack
                        width="100%"
                        direction="row"
                        alignItems="center"
                        spacing={0.5}
                      >
                        <StyledSubtitleTypography>
                          {t("messages_page.applied_to_job_one")}{" "}
                        </StyledSubtitleTypography>
                        <Tooltip title={option.title}>
                          <StyledJobTitleTypography maxWidth="125px !important">
                            {option.title}
                          </StyledJobTitleTypography>
                        </Tooltip>
                        <StyledSubtitleTypography>
                          {t("messages_page.on")}{" "}
                          {generateFullDate(option.timestamp)}
                        </StyledSubtitleTypography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatusSelectorCell
                      label={findStatusLabel(option.status) as string}
                      type={option?.status as string}
                    />
                  </Stack>
                </Stack>
              </li>
            )}
            renderInput={(params) => (
              <StyledTextfield
                {...params}
                placeholder={t("applications.search_placeholder") as string}
              />
            )}
          />
        )}
        {selectedUser && (
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack
              direction="row"
              height="100%"
              alignItems="center"
              spacing={1.5}
            >
              <Avatar src={selectedUser.photo} alt="candidate-photo" />
              <Stack justifyContent="space-between">
                <Typography
                  fontSize={16}
                  color="#131E30"
                  maxWidth={340}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {selectedUser.firstname} {selectedUser.lastname}
                </Typography>
                <Stack
                  width="100%"
                  direction="row"
                  alignItems="center"
                  spacing={0.5}
                >
                  <StyledSubtitleTypography>
                    {t("messages_page.applied_to_job_one")}{" "}
                  </StyledSubtitleTypography>
                  <Tooltip title={selectedUser.title}>
                    <StyledJobTitleTypography maxWidth="165px !important">
                      {selectedUser.title}
                    </StyledJobTitleTypography>
                  </Tooltip>
                  <StyledSubtitleTypography>
                    {t("messages_page.on")}{" "}
                    {generateFullDate(selectedUser.timestamp)}
                  </StyledSubtitleTypography>
                </Stack>
              </Stack>
            </Stack>
            <Stack height="100%" alignItems="center" justifyContent="center">
              <StatusSelectorCell
                label={selectedUserStatus?.label as string}
                type={selectedUserStatus?.value as string}
              />
            </Stack>
          </Stack>
        )}
        <StyledChatTextarea
          sx={{
            maxWidth: 520,
          }}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={t("messages_page.new_message_placeholder") as string}
          value={message}
          rows={4}
        />
      </Stack>
      <DialogActions style={{ padding: "16px 24px", paddingTop: "0px" }}>
        <Button variant="outlined" onClick={onClose} autoFocus>
          {t("button.secondCancel")}
        </Button>
        <Button
          variant="contained"
          disabled={!message || !selectedUser}
          onClick={sendMessage}
        >
          {t("candidate.send_message")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageDialog;
