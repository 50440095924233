import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import StatusSelectorCell from "./statusSelector";
import CandidateInTalentPoolIcon from "../../CandidateInTalentPoolIcon";
import { StyledStatusInColumnText } from "../../Kanban/style";
import { TDataGridActions } from "../../../models/DataGrid";
import { daysBeetwen } from "../../../utils/singleJob";
import { getWorkflowState } from "../../../store/selectors/Workflow";

const statusSelectorWithDaysInStatus = ({
  params,
  actions,
}: {
  readonly params: ICellRendererParams;
  readonly actions: TDataGridActions;
}) => {
  const { t } = useTranslation();

  const { workflows } = useSelector(getWorkflowState);

  const showInStatusText =
    params.data.status != "rejected" && params.data.status != "hired";
  const numberOfDaysInStatus = daysBeetwen(params.data.updated_at);
  const statusType =
    numberOfDaysInStatus < 4
      ? "normal"
      : numberOfDaysInStatus < 8
        ? "orange"
        : "red";

  const defaultStatuses = [
    {
      title: t("status.new"),
      label: t("status.new"),
      value: "new",
      color: "#818CF8",
    },
    {
      title: t("status.in_progress"),
      label: t("status.in_progress"),
      value: "review",
      color: "#F59E0B",
    },
    {
      title: t("status.hired"),
      label: t("status.hired"),
      value: "hired",
      color: "#22C55E",
    },
    {
      label: t("status.rejected"),
      title: t("status.rejected"),
      value: "rejected",
      color: "#EF4444",
    },
  ];

  const currentStatuses = useMemo(() => {
    if (workflows && workflows.length > 0) {
      const allUniqueStatuses = Array.from(
        new Set(
          workflows.flatMap((workflow) =>
            workflow.statuses.map((status) => ({
              label: status.label,
              value: status.value,
              color: status.color,
            }))
          )
        )
      );
      return allUniqueStatuses;
    }
    return defaultStatuses;
  }, [workflows]);

  const findFormattedLabelValue = currentStatuses?.find(
    (item) => item.value == params.value.toLowerCase()
  );

  const formattedLabelValue = findFormattedLabelValue
    ? findFormattedLabelValue.label
    : "new";

  return (
    <Stack mt={showInStatusText ? 0.65 : 0}>
      <Stack direction="row" spacing={0.5}>
        <StatusSelectorCell
          params={params}
          label={formattedLabelValue}
          type={params.data.status}
          onSelect={(newStatus: string) => {
            if (actions.onChangeStatus)
              actions.onChangeStatus({
                isTalentPooled: params.data.is_talentpooled == 1,
                talentPoolTitle: params.data.talentpool_title,
                applicationId: params.data.application_id,
                newStatus,
              });
          }}
        />
        {params?.data.is_talentpooled == 1 && (
          <CandidateInTalentPoolIcon
            firstname={params?.data?.firstname}
            lastname={`${params?.data?.lastname} ${t(
              "talentPools.isInYourTalentPool"
            )}`}
            photo={params?.data?.photo}
          />
        )}
      </Stack>
      {showInStatusText && (
        <StyledStatusInColumnText type={statusType} paddingLeft={0.5}>
          {t("single_job.in_status_for", { numberOfDaysInStatus })}
        </StyledStatusInColumnText>
      )}
    </Stack>
  );
};

export default statusSelectorWithDaysInStatus;
