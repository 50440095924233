import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CompanySettingsComponent from "./component";
import RemoveDialog from "../../components/dialogs/RemoveDialog";
import { DIALOG_TYPE } from "../../models/common";
import { TCompanySettingsCompanyFormFields } from "../../models/CompanySettings";
import {
  disconnectAgency,
  fetchCompanyData,
  fetchCountries,
  fetchRegistrationData,
  updateCompanyData,
} from "../../store/reducers/CompanySettings";
import {
  getCompanySettingsCompanyState,
  getCompanySettingsIsFormDataLoading,
} from "../../store/selectors/Company";
import { isEqualObjects } from "../../utils/common";
import { getEmployeeId } from "../../utils/companySettings";
import { formDefaultValues, getFormSettings } from "./config";
import { getCurrentUserData } from "../../store/selectors/CurrentUser";
import { setIsUploadPictureDialogOpen } from "../../store/reducers/dialogs";
import { addMessage } from "../../store/reducers/Snackbar";

const CompanySettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { company, countries, employees, industries } = useSelector(
    getCompanySettingsCompanyState
  );
  const { agencyId } = useSelector(getCurrentUserData);

  const isFormDataLoading = useSelector(getCompanySettingsIsFormDataLoading);
  const { companyUrlKey } = useSelector(getCurrentUserData); // TODO refactor later
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [openedDialogType, setOpenedDialogType] = useState<DIALOG_TYPE | null>(
    null
  );
  const {
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(getFormSettings(t)),
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    const subscription = watch((formValues) => {
      setIsFormChanged(!isEqualObjects(company, formValues));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    dispatch(fetchRegistrationData());
    dispatch(fetchCountries());
    dispatch(fetchCompanyData());
  }, []);

  useEffect(() => {
    if (company) {
      const companyDataKeys = Object.keys(
        company
      ) as unknown as (keyof TCompanySettingsCompanyFormFields)[];
      companyDataKeys.forEach(
        (companyKey: keyof TCompanySettingsCompanyFormFields) => {
          if (companyKey === "company_color" && !company[companyKey]) {
            return;
          }
          setValue(companyKey, company[companyKey]);
        }
      );
    }
  }, [company]);

  const handleOnCopyToken = () => {
    navigator.clipboard.writeText(company?.apikey as string).then(() => {
      dispatch(
        addMessage({
          type: "success",
          title: t("company_settings.copy_message"),
        })
      );
    });
  };
  const handleOnOpenUploadDialog = () => {
    dispatch(setIsUploadPictureDialogOpen(true));
  };

  const handleOnUpdateLogo = (imageFile?: File) => {
    dispatch(
      updateCompanyData({
        type: "logo",
        data: imageFile ? { logo: imageFile } : { reset_logo: true },
        callback: () => dispatch(fetchCompanyData()),
      })
    );
  };

  const handleOnPreviewCompany = () => {
    const companyPage = `${location.origin}/companies/${companyUrlKey}`;
    window.open(companyPage, "_blank");
  };

  const handleOnSubmit = () => {
    const formValues = getValues();
    const data = {
      ...formValues,
      website: `https://${formValues.website}`,
      plz: formValues.zip,
      adress1: formValues.street,
      street_number: formValues.number,
      linkedin_url: formValues.linkedin,
      xing_url: formValues.xing,
      employees_id: getEmployeeId({ employees, value: formValues.employees }),
      logo: company?.logo,
      color: formValues.company_color,
    }; // TODO ask refactor on BE side
    dispatch(
      updateCompanyData({
        type: "form",
        data,
        callback: () => {
          dispatch(fetchCompanyData());
          dispatch(
            addMessage({
              type: "success",
              title: t("company_settings.snackbar_title"),
            })
          );
        },
      })
    );
  };

  const handleOpenDisconnectDialog = () => {
    setOpenedDialogType(DIALOG_TYPE.DISCONNECT);
  };

  const onDisconnectSuccess = () => {
    setOpenedDialogType(null);
    dispatch(fetchCompanyData());
  };

  const handleOnDisconnect = () => {
    dispatch(
      disconnectAgency({
        id: agencyId as number,
        callback: () => onDisconnectSuccess(),
      })
    );
  };

  const handleCloseDisconectDialog = () => {
    setOpenedDialogType(null);
  };

  return (
    <>
      <CompanySettingsComponent
        t={t}
        register={register}
        errors={errors}
        control={control}
        countries={countries}
        employees={employees}
        industries={industries}
        isFormChanged={isFormChanged}
        logo={company?.logo}
        isFormDataLoading={isFormDataLoading}
        company={company}
        onCopyToken={handleOnCopyToken}
        onSubmit={handleOnSubmit}
        onDeleteLogo={() => handleOnUpdateLogo()}
        onOpenUploadDialog={handleOnOpenUploadDialog}
        onPreview={handleOnPreviewCompany}
        onOpenDisconnectDialog={handleOpenDisconnectDialog}
      />
      <RemoveDialog
        t={t}
        title={t("customers.disconnect_dialog_title")}
        body={t("customers.disconnect_dialog_body")}
        submitButtonText={t("customers.disconnect")}
        isOpen={openedDialogType === DIALOG_TYPE.DISCONNECT}
        onClose={handleCloseDisconectDialog}
        onRemove={handleOnDisconnect}
      />
    </>
  );
};

export default CompanySettings;
