import React from "react";
import { Stack, Button } from "@mui/material";
import SimpleFilter from "../../components/filters/SimpleFilter";
import { IFiltersAndButtonsComponent } from "../../models/Agency";
import { StyledActionLoadingButton } from "../../components/DataGrid/styles";

const FiltersAndButtonsComponent = ({
  t,
  jassId,
  quickSearch,
  location,
  isLoading,
  onChangeFilters,
  onImportCustomers,
  onOpenDialog,
}: IFiltersAndButtonsComponent) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <Stack width="32%" maxWidth={352}>
      <SimpleFilter
        value={quickSearch}
        onChange={(value) =>
          onChangeFilters({ value, filterType: "quickSearch" })
        }
        placeholder={t("applications.search_placeholder")}
      />
    </Stack>
    <Stack direction="row" spacing={3}>
      <Stack maxWidth={190}>
        <SimpleFilter
          value={location}
          onChange={(value) =>
            onChangeFilters({ value, filterType: "location" })
          }
          placeholder={t("joblist.location_placeholder")}
        />
      </Stack>
      {jassId && (
        <StyledActionLoadingButton
          variant="outlined"
          loadingPosition="end"
          loading={isLoading}
          continueEditingButtonWidth={200}
          onClick={onImportCustomers}
        >
          {t("customers.sync")}
        </StyledActionLoadingButton>
      )}

      <Button variant="contained" onClick={onOpenDialog}>
        {t("customers.no_customers_invite")}
      </Button>
    </Stack>
  </Stack>
);
export default FiltersAndButtonsComponent;
