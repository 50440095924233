import React from "react";
import RenderAction from "./RenderAction";
import { ISnackbarActionContainer } from "../../models/Snackbar";

const SnackbarActionContainer = ({
  desktop,
  action,
  firstButtonText,
  onFirstButtonClick,
  type,
  onClose,
}: ISnackbarActionContainer) => (
  <RenderAction
    desktop={desktop}
    action={action}
    firstButtonText={firstButtonText}
    onClose={onClose}
    onFirstButtonClick={onFirstButtonClick}
    type={type}
  />
);

export default SnackbarActionContainer;
