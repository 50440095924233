import React from "react";
import { Stack, Button } from "@mui/material";
import { ITalentPoolsToolbar } from "../../models/TalentPools";

const TalentPoolToolbar = ({
  t,
  talentPools,
  onOpenCreateTalentPoolDialog,
}: ITalentPoolsToolbar) => (
  <Stack direction="row" alignItems="center" justifyContent="flex-end">
    {!!talentPools?.length && (
      <Button
        data-testid="create-talent-pool-button"
        variant="contained"
        onClick={() => onOpenCreateTalentPoolDialog(null)}
      >
        {t("talentPools.addTalentPool")}
      </Button>
    )}
  </Stack>
);
export default TalentPoolToolbar;
