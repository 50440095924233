import React from "react";
import { InputLabel, Stack, TextField } from "@mui/material";
import { StyledTwoTextfieldsContainer } from "./style";
import { TMultipleTextfields } from "../../models/common";

const TextfildsComponent = ({ items }: TMultipleTextfields) => (
  <StyledTwoTextfieldsContainer>
    <Stack direction="row" width="100%" spacing={3}>
      {items.map((item, index) => (
        <Stack key={index} flex={item.flexRatio}>
          <InputLabel>{item.inputLabel}</InputLabel>
          <TextField
            className={"disabled-field"}
            value={item.value}
            placeholder={item.placeholder}
            fullWidth
          />
        </Stack>
      ))}
    </Stack>
  </StyledTwoTextfieldsContainer>
);

export default TextfildsComponent;
