import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AdditionalQuestionsDriverLicensesComponent from "./component";
import { IAdditionalQuestionSimpleSection } from "../../../../models/JobPreview";
import { TListOption } from "../../../../models/common";
import { getJobPreviewState } from "../../../../store/selectors/JobPreview";

const AdditionalQuestionsDriverLicenses = ({
  control,
  desktop,
  companyColor,
  boldHeading,
}: IAdditionalQuestionSimpleSection) => {
  const { t } = useTranslation();
  const {
    jobData: { driversLicenses },
  } = useSelector(getJobPreviewState);

  const modifiedDriverLicenses: TListOption[] = useMemo(() => {
    if (driversLicenses)
      return driversLicenses.map((dl: { id: string; name: string }) => ({
        value: dl.id,
        label: dl.name,
      }));
    return [];
  }, [driversLicenses]);

  return (
    <AdditionalQuestionsDriverLicensesComponent
      t={t}
      boldHeading={boldHeading}
      control={control}
      desktop={desktop}
      driverLicenses={modifiedDriverLicenses}
      companyColor={companyColor}
    />
  );
};

export default AdditionalQuestionsDriverLicenses;
