import React from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import AuthLayout from "../../../layouts/Auth";
import { IRegisterResendLinkComponent } from "../../../models/Registration";

const RegisterResendLinkComponent = ({
  t,
  isLinkDisabled,
  desktop,
  resendDataExist,
  onResend,
}: IRegisterResendLinkComponent) => (
  <AuthLayout desktop={desktop}>
    <Stack>
      <Typography mt={1.5} variant="body1">
        {t("verification.title")}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" mt={1}>
        {t("verification.email_subtitle_one")}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {t("verification.email_subtitle_two")}
      </Typography>
      {resendDataExist && (
        <Box mt={3}>
          <Typography variant="subtitle2">
            <Link
              underline="none"
              display="inline"
              onClick={onResend}
              className={isLinkDisabled ? "disabled-link" : ""}
            >
              {t("verification.resend")}
            </Link>
          </Typography>
        </Box>
      )}
    </Stack>
  </AuthLayout>
);

export default RegisterResendLinkComponent;
