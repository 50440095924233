import React from "react";
import { Stack, Typography } from "@mui/material";
import ApplicationsOutdated from "../../assets/ApplicationsOutdated.png";
import { StyledActionButton } from "../../components/DataGrid/styles";
import { IOutdatedApplicationComponent } from "../../models/ApplicationPage";

const OutdatedApplication = ({
  t,
  currentApplication,
  onRequestUnlockApplication,
}: IOutdatedApplicationComponent) => (
  <Stack height="100%" alignItems="center" justifyContent="center" spacing={3}>
    <img src={ApplicationsOutdated} />
    <Stack
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      spacing={1}
    >
      <Typography>
        {t("applications.applicationsOlderTitle")} 180{" "}
        {t("create_job_fourth_step.days")}
      </Typography>
      <Typography maxWidth={470} variant="subtitle2" color="textSecondary">
        {t("applications.applicationsOlderText")}
      </Typography>
    </Stack>
    <StyledActionButton
      disabled={Boolean(
        currentApplication && currentApplication.is_unlock_requested
      )}
      onClick={onRequestUnlockApplication}
    >
      {currentApplication?.is_unlock_requested
        ? t("applications.persmissions_requested")
        : t("applications.request_permission")}
    </StyledActionButton>
  </Stack>
);

export default OutdatedApplication;
