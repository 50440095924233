import React from "react";
import { TFunction } from "i18next";
import { Stack, Typography } from "@mui/material";

const FilterComponent = ({ t }: { t: TFunction }) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <Typography fontSize={18} color={"#131E30"} fontWeight={500}>
      {t("single_job.bookings")}
    </Typography>
  </Stack>
);

export default FilterComponent;
