import React from "react";
import { Box, Stack } from "@mui/material";
import SimpleFilter from "../../../components/filters/SimpleFilter";
import { StyledApplicationsFilterContainer } from "../../../pages/Applications/styles";
import { ITalentPoolFilterComponent } from "../../../models/TalentPools";

const TalentPoolFiltersComponent = ({
  t,
  quickSearch,
  location,
  skill,
  onChangeFilters,
}: ITalentPoolFilterComponent) => (
  <StyledApplicationsFilterContainer direction="row" width="100% !important">
    <Box className={"quick-search-container"}>
      <SimpleFilter
        testId="talent-pool-simple-filter-by-name"
        value={quickSearch}
        onChange={(value) =>
          onChangeFilters({ value, filterType: "quickSearch" })
        }
        placeholder={t("applications.search_placeholder")}
      />
    </Box>
    <Stack
      direction={"row"}
      className={"advanced-filters-container"}
      spacing={4}
      alignItems="flex-end"
    >
      <Box className={"location-search-container"} maxWidth={"100%"}>
        <SimpleFilter
          testId="talent-pool-simple-filter-by-location"
          value={location}
          onChange={(value) =>
            onChangeFilters({ value, filterType: "location" })
          }
          placeholder={t("joblist.location_placeholder")}
        />
      </Box>
      <SimpleFilter
        testId="talent-pool-simple-filter-by-skill"
        value={skill}
        onChange={(value) => onChangeFilters({ value, filterType: "skill" })}
        placeholder={t("create_job_third_step.skills")}
      />
    </Stack>
  </StyledApplicationsFilterContainer>
);

export default TalentPoolFiltersComponent;
