import React, { useEffect } from "react";
import AnalyticsComponent from "./component";
import { useDispatch, useSelector } from "react-redux";
import PremiumRequestComponent from "./PremiumRequestComponent";
import {
  getAnalyticsIsLoading,
  getAnalyticsJobList,
} from "../../store/selectors/Analytics";
import {
  fetchAllAnalytics,
  fetchAnalyticsJobList,
  resetAnalytics,
} from "../../store/reducers/Analytics";
import { getCurrentUserData } from "../../store/selectors/CurrentUser";
import { getWorkflowState } from "../../store/selectors/Workflow";
import { getAllActiveJobs } from "../../store/selectors/JobList";
import { fetchAllActiveJobs } from "../../store/reducers/JobList";

const Analytics = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getAnalyticsIsLoading);
  const jobList = useSelector(getAnalyticsJobList);
  const allJobs = useSelector(getAllActiveJobs);
  const { isAnalyticsAllowed } = useSelector(getCurrentUserData);
  const { workflows } = useSelector(getWorkflowState);

  useEffect(() => {
    return () => {
      dispatch(resetAnalytics());
    };
  }, []);

  useEffect(() => {
    if (jobList && jobList.length && workflows.length && allJobs?.length) {
      dispatch(fetchAllAnalytics());
    }
  }, [jobList, workflows, allJobs]);

  useEffect(() => {
    if (!isLoading && !jobList && isAnalyticsAllowed) {
      dispatch(fetchAnalyticsJobList());
    }
  }, [jobList, isLoading]);

  useEffect(() => {
    if (!allJobs?.length) {
      dispatch(fetchAllActiveJobs());
    }
  }, [allJobs]);

  return isAnalyticsAllowed ? (
    <AnalyticsComponent isLoading={isLoading} />
  ) : (
    <PremiumRequestComponent />
  );
};

export default Analytics;
