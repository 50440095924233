import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Controller } from "react-hook-form";
import AuthLayout from "../../../layouts/Auth";
import { IRecruiterRegistrationComponent } from "../../../models/Registration";

const RegisterRecruiterComponent = ({
  t,
  control,
  errors,
  isLoading,
  showPassword,
  invitationEmail,
  desktop,
  register,
  handleSubmit,
  onSubmit,
  onSignIn,
  onShowPassword,
}: IRecruiterRegistrationComponent) => (
  <AuthLayout desktop={desktop}>
    <Typography variant="body1" lineHeight={2.5}>
      {t("recruiter.register_as_recruiter")}
    </Typography>
    <Typography variant={"subtitle2"} color={"textSecondary"}>
      {t("recruiter.set_your_recruiter_profile")}
    </Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack mt={3} mb={2.5} flex={1} direction={"row"} spacing={2}>
        <Box sx={{ width: "100%" }}>
          <InputLabel>{t("recruiter.first_name")}</InputLabel>
          <TextField
            {...register("firstname")}
            variant={"outlined"}
            size={"small"}
            fullWidth
            placeholder={t("recruiter.first_name") as string}
            error={Boolean(errors.firstname)}
            helperText={errors.firstname?.message as string}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <InputLabel>{t("recruiter.last_name")}</InputLabel>
          <TextField
            {...register("lastname")}
            variant={"outlined"}
            size={"small"}
            fullWidth
            placeholder={t("recruiter.last_name") as string}
            error={Boolean(errors.lastname)}
            helperText={errors.lastname?.message as string}
          />
        </Box>
      </Stack>
      <Box mt={2.5} mb={2}>
        <InputLabel>{t("recruiter.phone_number")}</InputLabel>
        <FormControl>
          <Controller
            name={"phone"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                inputClass={errors.phone ? "phone-input error" : "phone-input"}
                country={"de"}
                value={value}
                onChange={onChange}
                preferredCountries={["de", "at", "ch"]}
              />
            )}
          />
          <FormHelperText error>
            {errors.phone?.message as string}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box mt={2.5} mb={2}>
        <InputLabel>{t("login.e_mail")}</InputLabel>
        <TextField
          {...register("email")}
          value={invitationEmail && invitationEmail}
          disabled={invitationEmail ? true : false}
          variant={"outlined"}
          size={"small"}
          fullWidth
          placeholder={t("recruiter.email_placeholder") as string}
          error={Boolean(errors.email)}
          helperText={errors.email?.message as string}
        />
      </Box>
      <Box mt={2.5} mb={1}>
        <InputLabel>{t("login.password")}</InputLabel>
        <TextField
          {...register("password")}
          variant={"outlined"}
          size={"small"}
          fullWidth
          type={showPassword ? "text" : "password"}
          placeholder={t("login.password") as string}
          error={Boolean(errors.password)}
          helperText={errors.password?.message as string}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Stack width={19.75} height={19.75}>
                  {showPassword ? (
                    <EyeSlashIcon onClick={onShowPassword} cursor={"pointer"} />
                  ) : (
                    <EyeIcon onClick={onShowPassword} cursor={"pointer"} />
                  )}
                </Stack>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Stack mt={1} mb={3.5}>
        <FormControlLabel
          control={<Checkbox {...register("checked")} color="secondary" />}
          label={
            <Typography
              variant="body2"
              paddingTop={2.25}
              fontSize={14}
              color="textSecondary"
            >
              {t("recruiter.privacy_policy_1")}
              {
                <a
                  href="https://karriera.de/terms-and-conditions"
                  style={{ textDecoration: "none", color: "#178CF2" }}
                >
                  {t("recruiter.t_and_c")}
                </a>
              }
              {t("recruiter.privacy_policy_2")}
              {
                <a
                  href="https://karriera.de/terms-and-conditions"
                  style={{ textDecoration: "none", color: "#178CF2" }}
                >
                  {t("recruiter.privacy")}
                </a>
              }
              {t("recruiter.read")}
            </Typography>
          }
        />
        <FormHelperText error>
          {errors.checked?.message as string}
        </FormHelperText>
      </Stack>
      <Box>
        <Button
          color={"primary"}
          variant={"contained"}
          size={"small"}
          type={"submit"}
          disableElevation
          fullWidth
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          disabled={isLoading}
        >
          {t("recruiter.finish")}
        </Button>
      </Box>
      <Typography variant="subtitle2" align="center" color="textSecondary">
        {t("register.alredy_have_account")}
        <Link underline="none" display="inline" onClick={onSignIn}>
          {t("login.sign_in")}
        </Link>
      </Typography>
    </form>
  </AuthLayout>
);

export default RegisterRecruiterComponent;
