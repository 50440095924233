import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { confirmEmailCode } from "../../../store/reducers/Registration";
import { fetchUser } from "../../../store/reducers/CurrentUser";
import { getCurrentUserToken } from "../../../store/selectors/CurrentUser";

const ConfirmationRegister = () => {
  const { code } = useParams();
  const dispatch = useDispatch();

  const token = useSelector(getCurrentUserToken);

  useEffect(() => {
    if (code)
      dispatch(
        confirmEmailCode({
          code,
          callback: () => {
            dispatch(fetchUser());
          },
        })
      );
  }, []);

  return token ? <Navigate to="/jobs/active" replace={true} /> : null;
};

export default ConfirmationRegister;
