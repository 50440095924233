import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import AuthLayout from "../../../layouts/Auth";
import { IResetPasswordComponent } from "../../../models/Auth";

const ResetPasswordComponent = ({
  t,
  register,
  errors,
  isLoading,
  desktop,
  handleSubmit,
  onSubmit,
  onReturn,
}: IResetPasswordComponent) => (
  <AuthLayout desktop={desktop}>
    <Typography lineHeight={2.5} variant="body1">
      {t("reset_password_page.title")}
    </Typography>
    <Typography variant="subtitle2" color="textSecondary">
      {t("reset_password_page.text")}
    </Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={3.5} mb={2.5}>
        <InputLabel>{t("email")}</InputLabel>
        <TextField
          {...register("email")}
          variant={"outlined"}
          size={"small"}
          fullWidth
          placeholder={"name@examlpe.de"}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />
      </Box>
      <Box>
        <Button
          color={"primary"}
          variant={"contained"}
          size={"small"}
          type={"submit"}
          disableElevation
          fullWidth
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          disabled={isLoading}
        >
          {t("reset_password_page.button")}
        </Button>
      </Box>
      <Link
        variant="subtitle2"
        underline="none"
        display="inline"
        onClick={onReturn}
      >
        {t("reset_password_page.link")}
      </Link>
    </form>
  </AuthLayout>
);

export default ResetPasswordComponent;
