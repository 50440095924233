import React from "react";
import {
  Stack,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import DataGrid from "../../DataGrid";
import JobListNoResultOverlay from "../../../pages/JobList/NoResultOverlay";
import Pagination from "../../Pagination";
import SimpleFilter from "../../filters/SimpleFilter";
import { IAddCandidateToJobDialog } from "../../../models/dialogs";
import { DATA_GRID_LIST_TYPE } from "../../../models/DataGrid";

const AddCandidateToJobDialog = ({
  t,
  isOpen,
  selectedCandidate,
  jobs,
  jobsCount,
  pageSize,
  pageNum,
  quickSearch,
  selectedJob,
  onClose,
  onJobsPaginate,
  onChangeSimpleFilter,
  onAddCandidateToJob,
}: IAddCandidateToJobDialog) => (
  <Dialog
    open={Boolean(isOpen)}
    onClose={onClose}
    PaperProps={{
      elevation: 0,
    }}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          minWidth: 920,
          maxWidth: 920,
        },
        "& hr": {
          margin: "19px 0px 4px 0px",
        },
      },
    }}
  >
    <Stack px={3}>
      <Stack
        pt={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="h6"
          maxWidth={750}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {t("talentPools.addProfileToJob", {
            firstname: selectedCandidate?.firstname,
            lastname: selectedCandidate?.lastname,
          })}
        </Typography>

        <Stack>
          <XMarkIcon
            width={24}
            height={24}
            stroke="#131E30"
            strokeWidth={2}
            cursor="pointer"
            onClick={onClose}
          />
        </Stack>
      </Stack>
      <Divider />
    </Stack>
    <DialogContent>
      {jobs && jobs.length > 0 ? (
        <Stack width={872} height={420} spacing={3}>
          <SimpleFilter
            value={quickSearch}
            onChange={(value) =>
              onChangeSimpleFilter({ filter: "quickSearch", value })
            }
            placeholder={t("joblist.search_placeholder")}
          />
          <DataGrid
            t={t}
            list={jobs}
            type={DATA_GRID_LIST_TYPE.TALENT_POOL_JOBS}
            actions={{}}
          />
          <Pagination
            totalPosts={jobsCount}
            itemsPerPage={pageSize}
            page={pageNum}
            paginate={onJobsPaginate}
          />
        </Stack>
      ) : (
        <JobListNoResultOverlay t={t} onOpenCustomerDialog={() => {}} />
      )}
    </DialogContent>
    <DialogActions style={{ padding: "16px 24px", paddingTop: "0px" }}>
      <Button variant="outlined" onClick={onClose} autoFocus>
        {t("button.secondCancel")}
      </Button>
      <Button
        variant="contained"
        onClick={onAddCandidateToJob}
        disabled={!selectedJob}
      >
        {t("talentPools.add")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default AddCandidateToJobDialog;
