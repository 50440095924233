import React from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import AdditionalQuestionsDocumentsComponent from "./component";
import { IAdditionalQuestionSimpleSection } from "../../../../models/JobPreview";

const AdditionalQuestionsDocuments = ({
  control,
  desktop,
  companyColor,
}: IAdditionalQuestionSimpleSection) => {
  const { t } = useTranslation();

  const { fields } = useFieldArray({
    control,
    name: "documents",
  });

  return (
    <AdditionalQuestionsDocumentsComponent
      t={t}
      control={control}
      desktop={desktop}
      companyColor={companyColor}
      fields={fields}
    />
  );
};

export default AdditionalQuestionsDocuments;
