import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import Loading from "../Loading";
import ActivityLogItem from "./ActivityLogItem";
import {
  IActivityLogComponent,
  IActivityLogItem,
} from "../../models/Applications";

const AcivityLog = ({
  t,
  isActivityLogLoading,
  activityLog,
  currentApplication,
}: IActivityLogComponent) => (
  <Stack
    border="1px solid #E5E7EB"
    borderRadius={1.5}
    sx={{ background: "#fff" }}
    spacing={0}
  >
    <Stack p={3}>
      <Typography
        fontSize={18}
        color={"#131E30"}
        fontWeight={500}
        lineHeight={"18px"}
      >
        {t("candidate.activity_log")}
      </Typography>
      <Divider
        sx={{
          marginTop: 3,
        }}
      />
    </Stack>
    {isActivityLogLoading ? (
      <Loading />
    ) : (
      <Stack>
        {activityLog?.map((item: IActivityLogItem, index: number) => (
          <ActivityLogItem
            key={index}
            t={t}
            item={item}
            currentApplication={currentApplication}
          />
        ))}
      </Stack>
    )}
  </Stack>
);

export default AcivityLog;
