import React from "react";
import { Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { TFunction } from "i18next";
import UploadDocument from "../../../components/UploadDocument";
import { IAdditionalQuestionSimpleSection } from "../../../models/JobPreview";
import { StyledAdditionalQuestionsFieldContainer } from "../styles";

const AdditionalQuestionsPhoto = ({
  t,
  control,
  desktop,
  companyColor,
}: IAdditionalQuestionSimpleSection & { readonly t: TFunction }) => (
  <StyledAdditionalQuestionsFieldContainer desktop={desktop}>
    <Stack className={"title"}>
      <Typography variant="subtitle2">
        {t("additional_questions.profile_picture")}
      </Typography>
    </Stack>
    <Stack className={"input-field"} spacing={2}>
      <Controller
        name={`photo`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <UploadDocument
            companyColor={companyColor}
            placeholder={
              t("additional_questions.profile_picture_placeholder") as string
            }
            value={value.name}
            onChange={(file) => {
              onChange({ file, name: file?.name || "" });
            }}
          />
        )}
      />
    </Stack>
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsPhoto;
