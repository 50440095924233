import React, { useEffect, useState } from "react";
import { PAGE_TYPES } from "../../models/TopHeader";
import { Stack } from "@mui/material";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useMatches } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SingleJobHeader from "./SingleJobHeader";
import SingleApplicationHeader from "./SingleApplicationHeader";
import UserMenu from "./UserMenu";
import SingleApplicationCandidateHeader from "./SingleApplicationCandidateHeader";
import MobileChatTopHeader from "./MobileChatTopHeader";
import CustomerDetailsHeader from "./CustomerDetailsHeader";
import TalentPoolHeader from "./TalentPoolHeader";
import ProductHeader from "./ProductHeader";
import SubPageMainHeader from "./SubPageMainHeader";
import { StyledHeaderContainer, StyledHeaderTitle } from "./styles";
import { setMobileDrawerOpen } from "../../store/reducers/Navigation";
import { getMessagesState } from "../../store/selectors/Messages";
import {
  getApplicationListState,
  getApplicationsViewState,
} from "../../store/selectors/Applications";
import { getSingleJobNavigationState } from "../../store/selectors/SingleJob";
import { JOB_TABS_KEY } from "../../models/SingleJob";
import { getActiveProduct } from "../../store/selectors/CurrentUser";
import { getNavigationState } from "../../store/selectors/Navigation";

const TopHeader = ({ desktop }: { readonly desktop: boolean }) => {
  const location = useLocation();
  const matches = useMatches();
  const { t } = useTranslation();
  const { handle } = matches?.[1] || {};
  const { pageType, title } =
    (handle as { pageType: PAGE_TYPES; title: string }) || {};
  const dispatch = useDispatch();

  const view = useSelector(getApplicationsViewState);
  const { selectedJobTab } = useSelector(getSingleJobNavigationState);
  const { cardsApplications, list } = useSelector(getApplicationListState);
  const activeProduct: any = useSelector(getActiveProduct);
  const { messagesInfo } = useSelector(getMessagesState);
  const [columnWidth, setColumnWidth] = useState<number>(290);

  const showUserAvatar = desktop
    ? true
    : messagesInfo && !desktop
      ? false
      : !(!desktop && PAGE_TYPES.SINGLE_APPLICATION_CANDIDATE);

  const { open } = useSelector(getNavigationState);

  const columnSpacing = 16;
  const spacingNumber = 3;
  const margin = window.innerWidth > 1536 ? 90 : 0;
  const differenceBeetwenOpenAndClosedSidebar = 150;
  const openSidebarDndWidth =
    columnWidth * 4 + columnSpacing * spacingNumber + margin;
  const closedSidebarDndWidth =
    openSidebarDndWidth + differenceBeetwenOpenAndClosedSidebar;
  const dndContainerWidth = open ? openSidebarDndWidth : closedSidebarDndWidth;

  const isFixedHeader =
    location.pathname.includes("/jobs") &&
    location.pathname.includes("/applications") &&
    !location.pathname.includes("/candidate") &&
    cardsApplications &&
    list.length &&
    Object.keys(cardsApplications).length > 4 &&
    view === "cards" &&
    selectedJobTab === JOB_TABS_KEY.APPLICATIONS;

  useEffect(() => {
    const column = document.querySelector(".dnd-column");
    if (column?.clientWidth) {
      setColumnWidth(column.clientWidth);
    }
  }, [view, cardsApplications]);

  return (
    <StyledHeaderContainer
      position={isFixedHeader ? "fixed" : "relative"}
      width={isFixedHeader ? dndContainerWidth : "auto"}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"flex-start"}
        spacing={1.5}
      >
        {!desktop && !pageType && (
          <Bars3Icon
            className="menu-icon"
            onClick={() => dispatch(setMobileDrawerOpen(true))}
          />
        )}
        {pageType === PAGE_TYPES.SINGLE_JOB && (
          <SingleJobHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.SINGLE_APPLICATION && (
          <SingleApplicationHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.TALENT_POOL && <TalentPoolHeader />}
        {pageType === PAGE_TYPES.SINGLE_APPLICATION_CANDIDATE && (
          <SingleApplicationCandidateHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.MESSAGES_MOBILE && (
          <MobileChatTopHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.CUSTOMER_DETAILS && (
          <CustomerDetailsHeader desktop={desktop} />
        )}
        {pageType === PAGE_TYPES.PRODUCT && <ProductHeader />}
        {pageType === PAGE_TYPES.PACKAGE && (
          <SubPageMainHeader
            title={activeProduct?.package_name}
            mainRoute="/discover"
            discount={activeProduct?.discount}
          />
        )}
        {pageType === PAGE_TYPES.AUTOMATION && (
          <SubPageMainHeader
            title={t("automation.createAutomation")}
            mainRoute="/automations"
            discount={activeProduct?.discount}
          />
        )}
        {!pageType && (
          <StyledHeaderTitle desktop={desktop}>{title}</StyledHeaderTitle>
        )}
      </Stack>
      {showUserAvatar && <UserMenu desktop={desktop} />}
    </StyledHeaderContainer>
  );
};

export default TopHeader;
