import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PremiumJobBoards from "../SingleJob/PremiumJobBoards";
import {
  fetchProductsAndPackages,
  setCartOpen,
} from "../../store/reducers/SingleJob";
import { getSingleJobState } from "../../store/selectors/SingleJob";

const Discover = () => {
  const dispatch = useDispatch();
  const { shopProducts } = useSelector(getSingleJobState);

  const discoverScroll = sessionStorage.getItem("discover-scroll");

  const handleOnOpenCart = () => {
    dispatch(setCartOpen(true));
  };

  const handleScroll = () => {
    sessionStorage.setItem(
      "discover-scroll",
      document.documentElement.scrollTop.toString()
    );
  };

  useEffect(() => {
    if (discoverScroll) {
      document.documentElement.scrollTop = discoverScroll as unknown as number;
    }

    if (!shopProducts) {
      dispatch(
        fetchProductsAndPackages({
          urlKey: "",
        })
      );
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <PremiumJobBoards onOpenCart={handleOnOpenCart} isDiscoverPage />;
};

export default Discover;
