import React from "react";
import { Box, Stack } from "@mui/material";
import DataGrid from "../../../components/DataGrid";
import Loading from "../../../components/Loading";
import { DATA_GRID_LIST_TYPE } from "../../../models/DataGrid";
import { IBasicJobBoardComponent } from "../../../models/SingleJob";

const BasicJobBoardComponent = ({
  t,
  isListLoading,
  jobList,
  actions,
}: IBasicJobBoardComponent) => (
  <Box sx={{ height: "100%" }}>
    {isListLoading ? (
      <Loading />
    ) : (
      <Stack height={"100%"}>
        <DataGrid
          t={t}
          list={jobList}
          type={DATA_GRID_LIST_TYPE.BASIC_JOB_BOARDS}
          actions={actions}
        />
      </Stack>
    )}
  </Box>
);

export default BasicJobBoardComponent;
