import moment from "moment/moment";
import {
  TJobDataToExport,
  TJobDetails,
  TJobLocation,
} from "../../models/SingleJob";
import { TApplication } from "../../models/Applications";
import { TFunction } from "i18next";

export const getJobDataToExport = ({
  t,
  jobDetails,
  jobLocations,
  applications,
}: {
  t: TFunction;
  jobDetails: TJobDetails | null;
  jobLocations: TJobLocation[] | null;
  applications: TApplication[];
}): TJobDataToExport => ({
  id: jobDetails?.id,
  company_id: jobDetails?.company_id,
  title: jobDetails?.title,
  url_key: jobDetails?.url_key,
  publish_date: jobDetails?.publish_date
    ? moment(jobDetails?.publish_date).format("DD.MM.YYYY")
    : undefined,
  created_at: jobDetails?.created_at
    ? moment(jobDetails?.created_at).format("DD.MM.YYYY")
    : undefined,
  contract_type_id: jobDetails?.contract_type_id,
  contract_type: jobDetails?.contract_type,
  seniority_id: jobDetails?.seniority_id,
  seniority_level: jobDetails?.seniority_level,
  work_field_id: jobDetails?.work_id,
  field_of_work: jobDetails?.work_type,
  position_type_id: jobDetails?.position_id,
  industry_id: jobDetails?.industry_id,
  industry_title: jobDetails?.industry,
  position_type: jobDetails?.position_type,
  company_title: jobDetails?.company,
  company_jass_id: jobDetails?.company_jass_id,
  applicationsCount: applications?.length,
  locations: jobLocations?.[0].city,
  status:
    jobDetails?.is_archived === "0" ? t("status.active") : t("status.archived"),
});
