import React, { CSSProperties, useEffect } from "react";
import { Stack } from "@mui/material";
import { XMarkIcon, ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import ColorPicker from "../ColorPicker";
import { IWorkflowStatus } from "../../models/Workflow";
import { IInitialColumns, IKanbanBoardComponent } from "../../models/Kanban";
import { getDialogsState } from "../../store/selectors/Dialogs";
import { getWorkflowState } from "../../store/selectors/Workflow";

const colorPickerStyle: CSSProperties = {
  marginLeft: -255,
  marginTop: -60,
};

const moveIconStyle: CSSProperties = {
  position: "relative",
  top: 2,
  transform: "rotate(45deg)",
  strokeWidth: 1,
};

const StatusItem = ({
  item,
  provided,
  column,
  index,
  setColumns,
}: {
  readonly item: IWorkflowStatus;
  readonly provided: any;
  readonly column: IKanbanBoardComponent;
  readonly index: number;
  readonly setColumns: (value: IInitialColumns) => void;
}) => {
  const { isSelectWorkflowDialogOpen } = useSelector(getDialogsState);
  const { selectedWorkflow, workflows, editWorkflow } =
    useSelector(getWorkflowState);

  const isDefaultColor = isSelectWorkflowDialogOpen ? true : item.defaultStatus;
  const customTextValue = item.defaultStatus ? undefined : item.label;
  const showDragIcon =
    !editWorkflow?.defaultWorkflow && !isSelectWorkflowDialogOpen;

  const handleOnRemoveStatusInWorkflow = () => {
    const workflowStatuses = { ...column };
    const newItems = [...workflowStatuses.items];
    newItems.splice(index, 1);
    setColumns({
      "0": {
        items: newItems,
        columnId: "new",
        title: "New",
        color: "#818CF8",
      },
    });
  };

  const handleOnUpdateWorkflowStatusColor = (value: string) => {
    const workflowStatuses = { ...column };
    workflowStatuses.items[index] = {
      ...workflowStatuses.items[index],
      color: value,
    };
    setColumns({
      "0": {
        items: workflowStatuses.items,
        columnId: "new",
        title: "New",
        color: "#818CF8",
      },
    });
  };

  const handleOnUpdateWorkflowStatusName = (value: string) => {
    const workflowStatuses = { ...column };
    workflowStatuses.items[index] = {
      ...workflowStatuses.items[index],
      label: value,
      value: value.replace(/\s+/g, "").toLowerCase(),
    };
    setColumns({
      "0": {
        items: workflowStatuses.items,
        columnId: "new",
        title: "New",
        color: "#818CF8",
      },
    });
  };

  useEffect(() => {
    if (isSelectWorkflowDialogOpen && selectedWorkflow) {
      const currentWorkflow = workflows.find(
        (item) => item.title == selectedWorkflow?.label
      );
      setColumns({
        "0": {
          items: currentWorkflow?.statuses || [],
          columnId: "new",
          title: "New",
          color: "#818CF8",
        },
      });
    }
  }, [selectedWorkflow]);

  return (
    <Stack
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      direction="row"
      spacing={1.5}
      alignItems="center"
      sx={{ background: "#fff" }}
    >
      {showDragIcon && (
        <ArrowsPointingOutIcon width={20} height={20} style={moveIconStyle} />
      )}
      <Stack
        width={
          item.defaultStatus || isSelectWorkflowDialogOpen ? "100%" : "90%"
        }
      >
        <ColorPicker
          title={item.label}
          color={item.color}
          onChange={handleOnUpdateWorkflowStatusColor}
          onChangeName={handleOnUpdateWorkflowStatusName}
          customStyle={colorPickerStyle}
          hideColorText
          isDefaultColor={isDefaultColor}
          customTextValue={customTextValue}
        />
      </Stack>
      {!item.defaultStatus && !isSelectWorkflowDialogOpen && (
        <Stack
          height={40}
          width={40}
          border="1px solid #EF4444"
          alignItems="center"
          justifyContent="center"
          borderRadius={1.5}
          sx={{ cursor: "pointer" }}
          onClick={handleOnRemoveStatusInWorkflow}
        >
          <XMarkIcon width={20} height={20} stroke="#EF4444" />
        </Stack>
      )}
    </Stack>
  );
};

export default StatusItem;
