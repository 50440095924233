import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CandidateNoteCommentListComponent from "./component";
import { IApplicationNotesComments } from "../../../../models/Applications";
import { getSelectedJobOwners } from "../../../../store/selectors/Applications";

const CandidateNoteCommentList = ({ comments }: IApplicationNotesComments) => {
  const { t } = useTranslation();
  const selectedJobOwners = useSelector(getSelectedJobOwners);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOnToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedCommentList = useMemo(
    () =>
      comments.map((comment) => {
        const selectedUser = selectedJobOwners?.find(
          (jobOwner) => jobOwner.id === comment.author_id
        );
        return {
          ...comment,
          avatar: selectedUser?.profile_photo || "",
        };
      }),
    [comments, selectedJobOwners]
  );

  return (
    <CandidateNoteCommentListComponent
      t={t}
      comments={displayedCommentList}
      isExpanded={isExpanded}
      onToggle={handleOnToggle}
    />
  );
};

export default CandidateNoteCommentList;
