import React, { useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import { createFilterOptions, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateRange } from "rsuite/DateRangePicker";
import { RangeType } from "rsuite/esm/DateRangePicker/types";
import AutocompleteFilter from "../../components/filters/AutocompleteFilter";
import { getPredefinedRanges } from "./utils";
import { StyledDatePickerContainer } from "./styles";
import { TListOption } from "../../models/common";
import {
  getAnalyticsJobList,
  getAnalyticsSelectedJob,
} from "../../store/selectors/Analytics";
import {
  fetchAnalyticsNumberOfApplications,
  setAnalyticsSelectedJob,
} from "../../store/reducers/Analytics";

const Toolbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const ranges = getPredefinedRanges(t);

  const jobList = useSelector(getAnalyticsJobList);
  const selectedJob = useSelector(getAnalyticsSelectedJob);
  const [selectedRangeBtn, setSelectedRangeBtn] = useState<string | null>(
    ranges[2].label as string
  ); // this week
  const [dateRange, setDateRange] = useState<[Date, Date] | null>([
    moment().startOf("week").toDate(),
    moment().endOf("week").toDate(),
  ]);

  useEffect(() => {
    if (dateRange?.[0] && dateRange?.[1])
      dispatch(
        fetchAnalyticsNumberOfApplications({
          since: moment(dateRange[0]).format("YYYY-MM-DD"),
          until: moment(dateRange[1]).format("YYYY-MM-DD"),
        })
      );
  }, [dateRange, selectedJob]);

  const handleOnChangeDateFilter = (newValue: [Date, Date] | null) => {
    const [start, finish] = newValue || [];
    if (start && finish) setDateRange([start, finish]);
    else setDateRange(null);

    const targetBtn = getPredefinedRanges(t).find(
      (range: RangeType) =>
        moment((range.value as DateRange)[0]).isSame(newValue?.[0], "day") &&
        moment((range.value as DateRange)[1]).isSame(newValue?.[1], "day")
    );
    setSelectedRangeBtn((targetBtn?.label as string) || null);
  };

  const modifiedRanges = useMemo(
    () =>
      ranges.map((range) =>
        range.label === selectedRangeBtn
          ? { ...range, className: "selected-range-date-picker-button" }
          : range
      ),
    [ranges, selectedRangeBtn]
  );

  const filterOptions = createFilterOptions({
    stringify: (option: TListOption) => option.label,
    ignoreCase: true,
    matchFrom: "start",
    limit: 11,
  });

  const handleOnChangeJob = (jobId: string | string[]) => {
    const currentSelectedJob =
      jobList?.find(({ value }) => value === jobId) || null;
    dispatch(setAnalyticsSelectedJob(currentSelectedJob));
  };

  return (
    <Grid container py={3} spacing={3}>
      <Grid item xs={3}>
        <AutocompleteFilter
          disableClearable
          value={selectedJob?.value || ""}
          filterOptions={filterOptions}
          options={
            jobList
              ? [
                  { value: "", label: t("jobs.navigation.all_jobs") },
                  ...jobList,
                ]
              : []
          }
          placeholder={t("jobs.navigation.all_jobs")}
          onChange={handleOnChangeJob}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledDatePickerContainer
          isoWeek
          character={" - "}
          cleanable={false}
          defaultValue={dateRange}
          format={"yyyy/MM/dd"}
          placeholder="Default"
          ranges={modifiedRanges}
          showHeader={false}
          size={"lg"}
          onChange={handleOnChangeDateFilter}
        />
      </Grid>
    </Grid>
  );
};

export default Toolbar;
