import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { candidateLogIn } from "../../store/reducers/Auth";
import {
  getLatestMessages,
  readUnreadMessage,
  setActiveUser,
} from "../../store/reducers/Messages";
import { getMessagesState } from "../../store/selectors/Messages";
import { getCurrentUserIsCandidate } from "../../store/selectors/CurrentUser";

const NewMessageRedirection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code, jobId, applicationId, recruiterId } = useParams();

  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const { latestMessages } = useSelector(getMessagesState);

  useEffect(() => {
    if (isCandidate) {
      dispatch(getLatestMessages());
    } else {
      dispatch(
        candidateLogIn({
          code: code as string,
          callback: () => {},
        })
      );
    }
  }, [isCandidate]);

  useEffect(() => {
    if (latestMessages) {
      dispatch(
        setActiveUser({
          job_id: parseInt(jobId as string),
          application_id: parseInt(applicationId as string),
          recruiter_id: parseInt(recruiterId as string),
        })
      );
      dispatch(
        readUnreadMessage({
          recruiter_id: recruiterId as string,
          application_id: applicationId as string,
          candidate_id: recruiterId,
          job_id: jobId as string,
          isNewMessage: true,
        })
      );
      navigate("/messages");
    }
  }, [latestMessages]);

  return <Loading />;
};

export default NewMessageRedirection;
