import React from "react";
import dayjs from "dayjs";
import { Box, Stack, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller } from "react-hook-form";
import { TFunction } from "i18next";
import { StyledAdditionalQuestionsFieldContainer } from "../styles";
import { IAdditionalQuestionSimpleSection } from "../../../models/JobPreview";

const AdditionalQuestionsStartDate = ({
  t,
  desktop,
  control,
}: IAdditionalQuestionSimpleSection & { readonly t: TFunction }) => (
  <StyledAdditionalQuestionsFieldContainer desktop={desktop}>
    <Stack className={"title"}>
      <Typography variant="subtitle2">
        {t("additional_questions.start_date")}
      </Typography>
    </Stack>
    <Controller
      name={"earliestStartDate"}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box className={"input-field"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%" }}
              format="DD.MM.YYYY"
              value={value ? dayjs(value) : null}
              onChange={onChange}
            />
          </LocalizationProvider>
        </Box>
      )}
    />
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsStartDate;
