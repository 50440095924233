import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import RegisterCompanyComponent from "./component";
import { fetchRegistrationData } from "../../../store/reducers/CompanySettings";
import {
  checkCompanyName,
  confirmInvitation,
  setCompanyData,
} from "../../../store/reducers/Registration";
import { getCompanySettingsCompanyState } from "../../../store/selectors/Company";
import { getRegistrationState } from "../../../store/selectors/Registration";
import { formDefaultValues, getFormSettings } from "./config";

const RegisterCompany = ({ desktop }: { readonly desktop: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { employees, industries } = useSelector(getCompanySettingsCompanyState);
  const { isLoading, error, agency_id } = useSelector(getRegistrationState);

  const isInvitationPath = location.pathname.includes(
    "/registration/agency/invitation-confirm/"
  );

  const url_key = isInvitationPath
    ? location.pathname.substring(40)
    : undefined;

  const {
    getValues,
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(getFormSettings(t)),
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    dispatch(fetchRegistrationData());
    if (isInvitationPath) {
      dispatch(
        confirmInvitation({
          urlKey: url_key as string,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (error) {
      setError("company", {
        type: "manual",
        message: t("company_name_exist") as string,
      });
    }
  }, [error]);

  const onSubmit = ({ company }: { company: string }) => {
    dispatch(checkCompanyName({ company, callback: handleOnSubmitSuccess }));
  };

  const handleOnSubmitSuccess = () => {
    const data = getValues();
    const formatedData = agency_id
      ? {
          ...data,
          agency_id,
        }
      : data;
    dispatch(setCompanyData(formatedData));
    navigate("/register/recruiter");
  };

  const handleOnSignIn = () => {
    navigate("/login");
  };

  return (
    <RegisterCompanyComponent
      t={t}
      errors={errors}
      control={control}
      isLoading={isLoading}
      industries={industries}
      employees={employees}
      desktop={desktop}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      onSignIn={handleOnSignIn}
    />
  );
};

export default RegisterCompany;
