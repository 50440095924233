import React from "react";
import { Droppable } from "react-beautiful-dnd";
import InnerList from "./InnerList";
import { StyledList, StyledListContainer } from "./style";
import { IListComponent } from "../../models/Kanban";

const List = ({
  t,
  listId,
  variant,
  selectedItems,
  isDragDisabled,
  column,
  isSelectWorkflowDialogOpen,
  editWorkflow,
  onAddNewStatus,
  setColumns,
  onSelectItem,
  onItemClick,
}: IListComponent) => (
  <Droppable droppableId={listId}>
    {(dropProvided, dropSnapshot) => (
      <StyledList
        isDraggingOver={dropSnapshot.isDraggingOver}
        variant={variant}
        isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
        {...dropProvided.droppableProps}
      >
        {variant === "candidate" ? (
          <StyledListContainer>
            <InnerList
              t={t}
              column={column}
              selectedItems={selectedItems}
              isDragDisabled={isDragDisabled}
              isSelectWorkflowDialogOpen={isSelectWorkflowDialogOpen}
              editWorkflow={editWorkflow}
              setColumns={setColumns}
              onItemClick={onItemClick}
              onSelectItem={onSelectItem}
              onAddNewStatus={onAddNewStatus}
              listId={listId}
              variant={variant}
              dropProvided={dropProvided}
            />
          </StyledListContainer>
        ) : (
          <InnerList
            t={t}
            column={column}
            selectedItems={selectedItems}
            isSelectWorkflowDialogOpen={isSelectWorkflowDialogOpen}
            isDragDisabled={isDragDisabled}
            editWorkflow={editWorkflow}
            setColumns={setColumns}
            onItemClick={onItemClick}
            onSelectItem={onSelectItem}
            onAddNewStatus={onAddNewStatus}
            listId={listId}
            variant={variant}
            dropProvided={dropProvided}
          />
        )}
      </StyledList>
    )}
  </Droppable>
);

export default List;
