import React from "react";
import { Stack, Typography, Divider, Button, InputLabel } from "@mui/material";
import AutocompleteFilter from "../../../../components/filters/AutocompleteFilter";
import { IApplicationStatusUpdatedTriggerComponent } from "../../../../models/Automation";

const ApplicationStatusUpdatedTriggerComponent = ({
  t,
  statuses,
  currentAutomation,
  statusTo,
  statusFrom,
  onChangeStatus,
  onSave,
}: IApplicationStatusUpdatedTriggerComponent) => (
  <Stack>
    <Stack spacing={2}>
      <Stack
        mt="0px !important"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize={16} fontWeight={500}>
          {currentAutomation?.trigger?.name}
        </Typography>
      </Stack>
      <Divider />
      <Stack spacing={0.25}>
        <InputLabel>{t("automation.oldStatus")}</InputLabel>
        <AutocompleteFilter
          placeholder={t("automation.oldStatus")}
          options={statuses.fromStatuses}
          value={statusFrom}
          onChange={(value: any) => onChangeStatus(value, "from")}
          hasColors
        />
      </Stack>
      <Stack spacing={0.25}>
        <InputLabel>{t("automation.newStatus")}</InputLabel>
        <AutocompleteFilter
          placeholder={t("automation.newStatus")}
          options={statuses.toStatuses}
          value={statusTo}
          onChange={(value: any) => onChangeStatus(value, "to")}
          hasColors
        />
      </Stack>
    </Stack>
    <Stack
      mt={2}
      direction="row"
      width="100%"
      justifyContent="flex-end"
      spacing={1.5}
    >
      <Button
        disabled={!statusTo || !statusFrom}
        variant="contained"
        onClick={onSave}
      >
        {t("button.save")}
      </Button>
    </Stack>
  </Stack>
);

export default ApplicationStatusUpdatedTriggerComponent;
