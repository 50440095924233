import React, { useState, useEffect } from "react";
import { Stack, Avatar, Tooltip } from "@mui/material";
import { EnvelopeOpenIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import UserMenu from "./UserMenu";
import {
  StyledChatUser,
  StyledJobTitleTypography,
  StyledLastMessageTypography,
  StyledUserNameTypography,
  StyledSmallTypography,
  StyledNewMessageInfoContainer,
  StyledIconHovered,
} from "./styles";
import { TLatestMessage } from "../../models/Messages";
import { IUserComponent } from "../../models/Messages";
import { generateTime } from "../../utils";
import { getCurrentUserIsCandidate } from "../../store/selectors/CurrentUser";

const UserComponent = ({
  t,
  recruiterId,
  user,
  desktop,
  users,
  index,
  anchorEl,
  handleReadUnread,
  handleSetActiveUser,
  handleOpenUserMenu,
  handleCloseUserMenu,
}: IUserComponent) => {
  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const [isIconHovered, setIsIconHovered] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<TLatestMessage>();

  const backgroundColor =
    user.recruiter_id === recruiterId || user.candidate_id === recruiterId
      ? "#EEF2FF"
      : "transparent";

  const showReadUnreadIcon =
    user.is_candidate_replied || user.is_recruiter_replied;

  useEffect(() => {
    const id = anchorEl?.getAttribute("id");
    if (id) {
      const elementIndex = id.split("-")[0];
      const formatedIndex = parseInt(elementIndex);
      setCurrentUser(users[formatedIndex]);
    }
  }, [anchorEl]);

  return (
    <StyledChatUser
      sx={{
        backgroundColor,
      }}
      onClick={() => {
        if (!isIconHovered) {
          handleSetActiveUser(user);
        }
      }}
    >
      <Stack direction="row" spacing={1.5} width="100%">
        <Stack spacing={0.6}>
          <Avatar src={user?.recruiter_photo || user?.candidate_photo} />
          {showReadUnreadIcon ? (
            <Stack position="relative">
              <Tooltip
                title={
                  desktop
                    ? user?.is_new_messages == 0
                      ? t("messages_page.mark_as_read")
                      : t("messages_page.mark_as_unread")
                    : ""
                }
              >
                <StyledIconHovered
                  desktop={desktop}
                  isCandidate={isCandidate}
                  id={`${index}-messagess-user-popover`}
                  onTouchEnd={(e) => {
                    if (!desktop) {
                      handleOpenUserMenu(e);
                    }
                  }}
                  onClick={() => {
                    if (desktop) {
                      handleReadUnread(
                        user,
                        user?.is_new_messages == 0 ? true : false
                      );
                    }
                  }}
                  onMouseOver={() => setIsIconHovered(true)}
                  onMouseLeave={() => setIsIconHovered(false)}
                >
                  {user?.is_new_messages != 0 ? (
                    <EnvelopeIcon width={18} height={18} stroke="#178CF2" />
                  ) : (
                    <EnvelopeOpenIcon width={18} height={18} stroke="#9CA3AF" />
                  )}
                </StyledIconHovered>
              </Tooltip>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
        <Stack width="100%" spacing={1.2} overflow="hidden">
          <Stack width="100%" spacing={0.5}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              maxWidth="100%"
            >
              <Stack direction="row" spacing={1} maxWidth={150}>
                <StyledUserNameTypography>
                  {user?.recruiter_name || user?.candidate_name}
                </StyledUserNameTypography>
                {user?.is_new_messages != 0 ? (
                  <Stack
                    width={8}
                    height={8}
                    borderRadius="50%"
                    alignSelf="center"
                    sx={{ background: "#3B82F6" }}
                  />
                ) : null}
              </Stack>
              <StyledSmallTypography>
                {generateTime(user.created_at)}
              </StyledSmallTypography>
            </Stack>
            <StyledJobTitleTypography>
              {user.job_title}
            </StyledJobTitleTypography>
            {isCandidate && !desktop && (
              <StyledSmallTypography>
                {t("messages_page.from")} {user?.company_name}
              </StyledSmallTypography>
            )}
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack maxWidth={user?.is_new_messages != 0 ? "75%" : "100%"}>
              <StyledLastMessageTypography
                color={user?.is_new_messages != 0 ? "#131E30" : "#6B7280"}
                fontWeight={user?.is_new_messages != 0 ? 600 : 400}
              >
                {user.text}
              </StyledLastMessageTypography>
            </Stack>
            {user?.is_new_messages != 0 ? (
              <StyledNewMessageInfoContainer>
                {t("status.new")}
              </StyledNewMessageInfoContainer>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
      <UserMenu
        t={t}
        anchorEl={anchorEl}
        handleCloseUserMenu={handleCloseUserMenu}
        handleReadUnread={handleReadUnread}
        currentUser={currentUser as TLatestMessage}
      />
    </StyledChatUser>
  );
};

export default UserComponent;
