import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ChangeApplicationStatusComponent from "./component";
import {
  IAutomationAction,
  ISelectedActionComponent,
} from "../../../../models/Automation";
import { getAutomationState } from "../../../../store/selectors/Automation";
import {
  setActions,
  setSelectedAction,
} from "../../../../store/reducers/Automation";
import { getWorkflowState } from "../../../../store/selectors/Workflow";

const ChangeApplicationStatus = ({
  t,
  onCloseAction,
}: ISelectedActionComponent) => {
  const dispatch = useDispatch();

  const { selectedAction, currentAutomation } = useSelector(getAutomationState);
  const { workflows } = useSelector(getWorkflowState);

  const allStatuses = workflows.flatMap((workflow) =>
    workflow.statuses.map((status) => ({
      label:
        workflow.title === t("workflow.defaultWorkflow")
          ? `${status.label} (${t("workflow.allWorkflows")})`
          : `${status.label} (Workflow:${workflow.title})`,
      value: status.value,
      color: status.color,
    }))
  );

  const allUniqueStatuses = allStatuses.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.value === item.value)
  );

  const defaultStatuses = [
    {
      label: "New (All Workflows)",
      value: "new",
      color: "#818CF8",
    },
    {
      label: "In Progress (All Workflows)",
      value: "review",
      color: "#F59E0B",
    },
    {
      label: "Hired (All Workflows)",
      value: "hired",
      color: "#22C55E",
    },
    {
      label: "Rejected (All Workflows)",
      value: "rejected",
      color: "#EF4444",
    },
  ];

  const formattedStatuses = allUniqueStatuses.length
    ? allUniqueStatuses
    : defaultStatuses;

  const handleChangeStatus = (value: string) => {
    if (selectedAction) {
      dispatch(
        setSelectedAction({
          ...selectedAction,
          params: {
            ...selectedAction.params,
            status: value,
          },
        })
      );
    }
  };

  const handleSave = () => {
    const updatedActions = currentAutomation.actions.map(
      (action: IAutomationAction) => {
        if (action.id === selectedAction?.id) {
          return {
            ...selectedAction,
            actionCompleted: true,
          };
        }
        return action;
      }
    );
    dispatch(setActions(updatedActions as IAutomationAction[]));
    dispatch(setSelectedAction(undefined));
  };

  useEffect(() => {
    if (selectedAction?.params.status === "string") {
      dispatch(
        setSelectedAction({
          ...selectedAction,
          params: {
            ...selectedAction.params,
            status: "",
            application_id: "{application_id}",
          },
        })
      );
    }
  }, []);

  return (
    <ChangeApplicationStatusComponent
      t={t}
      statuses={formattedStatuses}
      selectedStatus={selectedAction?.params?.status}
      onCloseAction={onCloseAction}
      onSave={handleSave}
      onChangeStatus={handleChangeStatus}
    />
  );
};

export default ChangeApplicationStatus;
