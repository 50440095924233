import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CheckBoXMarkIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledInviteTeamMemberContainer,
  StyledSelectedMemberContainer,
  StyledSelectedMemberContainerWrapper,
  StyledSelectedMemberItem,
  StyledTextField,
} from "./style";
import {
  fetchUsers,
  setJoinedPaginationSize,
} from "../../../store/reducers/Team";
import { TEAM_USER_STATUSES, TTeamsMember } from "../../../models/Team";
import { getTeamsUsersState } from "../../../store/selectors/Team";
import { getSingleJobState } from "../../../store/selectors/SingleJob";
import {
  fetchJobDetails,
  setTempJobDetails,
} from "../../../store/reducers/SingleJob";
import { updateJob } from "../../../store/reducers/JobEditor";
import { getDialogsState } from "../../../store/selectors/Dialogs";
import { useTranslation } from "react-i18next";
import { setIsInviteMemberDialogOpen } from "../../../store/reducers/dialogs";
import { getCurrentUserData } from "../../../store/selectors/CurrentUser";
import { getCurrentApplication } from "../../../store/selectors/Applications";

const InviteTeamMemberDialog = () => {
  const { t } = useTranslation();
  const { isInviteMemberDialogOpen } = useSelector(getDialogsState);
  const dispatch = useDispatch();

  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  useState<TTeamsMember | null>(null);

  const { list } = useSelector(getTeamsUsersState);
  const { jobOwners, jobDetails, tempJobDetails } =
    useSelector(getSingleJobState);
  const { memberId } = useSelector(getCurrentUserData);
  const currentApplication = useSelector(getCurrentApplication);

  const targetJobDetails = useMemo(
    () => jobDetails || tempJobDetails || currentApplication?.job,
    [jobDetails, tempJobDetails]
  );

  const selectedOwnersIds = useMemo(
    () => selectedMembers?.map((obj: TTeamsMember) => obj.id),
    [selectedMembers]
  );

  const filteredMembers = useMemo(() => {
    const filteredSelectedMembers = list.filter(
      (obj) => !selectedOwnersIds?.includes(parseInt(obj.id))
    );

    const filteredSelectedMembersAndAdmin = filteredSelectedMembers.filter(
      (obj: TTeamsMember) => parseInt(obj.id) !== memberId
    );

    return (
      selectedMembers.length
        ? filteredSelectedMembersAndAdmin
        : filteredSelectedMembers
    ).map((item) => ({ ...item, value: item.id }));
  }, [selectedMembers, list]);

  const handleOnChange = (newMembers: TTeamsMember[]) => {
    const uniqueSelectedMembers = Array.from(
      new Map(
        [...selectedMembers, ...newMembers].map((item) => [item.id, item])
      ).values()
    );
    setSelectedMembers(uniqueSelectedMembers);
  };

  const handleRemoveMember = (id: number | string) => {
    const filteredMembers = selectedMembers.filter(
      (item: TTeamsMember) => item.id !== id
    );
    setSelectedMembers(filteredMembers);
  };

  const handleOnClose = () => {
    dispatch(setIsInviteMemberDialogOpen(false));
    dispatch(setTempJobDetails(null));
    setSelectedMembers([]);
  };

  const handleOnInvite = () => {
    const selectedMembersIds =
      selectedMembers?.map((item: TTeamsMember) => item.id) || [];

    if (targetJobDetails) {
      dispatch(
        updateJob({
          apiPayload: {
            title: targetJobDetails.title,
            job_owners: selectedMembersIds,
          },
          url_key: targetJobDetails.url_key,
          callback: (jobUrlKey: string) => {
            dispatch(fetchJobDetails({ jobUrlKey }));
            handleOnClose();
          },
        })
      );
    }
  };

  useEffect(() => {
    if (isInviteMemberDialogOpen) {
      dispatch(setJoinedPaginationSize(-1));
      dispatch(fetchUsers(TEAM_USER_STATUSES.JOINED));
    }
  }, [isInviteMemberDialogOpen]);

  useEffect(() => {
    if (isInviteMemberDialogOpen) {
      if (jobOwners) setSelectedMembers(jobOwners);
      else if (tempJobDetails) setSelectedMembers(tempJobDetails?.owners);
    }
  }, [jobOwners, tempJobDetails, isInviteMemberDialogOpen]);

  return (
    <Dialog
      open={isInviteMemberDialogOpen}
      onClose={handleOnClose}
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minHeight: 400,
            maxHeight: 400,
            minWidth: 544,
            maxWidth: 544,
            overflow: "hidden",
          },
        },
      }}
    >
      <StyledInviteTeamMemberContainer>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="h6">
              {t("team_members.invite_team_members")}
            </Typography>
          </Stack>
          <Stack>
            <XMarkIcon
              width={24}
              height={24}
              stroke="#131E30"
              strokeWidth={2}
              cursor="pointer"
              onClick={handleOnClose}
            />
          </Stack>
        </Stack>
        <Divider />

        <Stack height={224}>
          <Typography variant="subtitle1" color="#0F141B" fontWeight={500}>
            {t("team_members.choose_team_members")}
          </Typography>
          <Stack overflow="hidden" spacing={1.5} zIndex={0}>
            <Autocomplete
              noOptionsText={t("team_members.no_team_members_text")}
              disablePortal
              multiple
              onChange={(_, value) => {
                handleOnChange(value);
              }}
              popupIcon={false}
              options={filteredMembers}
              ListboxProps={{
                className: "team-members-list-dialog",
              }}
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname}`
              }
              size="small"
              PaperComponent={({ children }) => (
                <Paper
                  sx={{
                    minHeight: "160px !important",
                    maxHeight: "160px !important",
                    minWidth: "496px !important",
                    maxWidth: "496px !important",
                  }}
                >
                  {children}
                </Paper>
              )}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label=""
                  placeholder={t("applications.name") as string}
                  margin="dense"
                />
              )}
              renderOption={(props, option) => (
                <li {...props} style={{ height: 40 }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Checkbox
                      color="secondary"
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoXMarkIcon />}
                      checked={selectedMembers?.some(
                        (item: TTeamsMember) => item.id === option.id
                      )}
                    />
                    <Avatar
                      src={option.photo as string}
                      alt="user-photo"
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                    >
                      <Typography variant="caption" fontSize={11.5}>
                        {`${option.firstname.substring(
                          0,
                          1
                        )}${option.lastname.substring(0, 1)}`}
                      </Typography>
                    </Avatar>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {option.firstname} {option.lastname}
                      </Typography>
                    </Stack>
                  </Stack>
                </li>
              )}
              renderTags={() => null}
            />
            <StyledSelectedMemberContainerWrapper>
              <StyledSelectedMemberContainer>
                {selectedMembers?.map((item: TTeamsMember, index: number) => (
                  <StyledSelectedMemberItem key={index}>
                    <Stack>
                      <Typography variant="subtitle2" noWrap>
                        {item.firstname} {item.lastname}
                      </Typography>
                      <XMarkIcon onClick={() => handleRemoveMember(item.id)} />
                    </Stack>
                  </StyledSelectedMemberItem>
                ))}
              </StyledSelectedMemberContainer>
            </StyledSelectedMemberContainerWrapper>
          </Stack>
        </Stack>
        <Stack
          mt={3}
          width="100%"
          direction="row"
          justifyContent="flex-end"
          spacing={1.5}
        >
          <Button variant={"outlined"} onClick={handleOnClose}>
            {t("button.secondCancel")}
          </Button>
          <Button variant={"contained"} onClick={handleOnInvite}>
            {t("team_members.invite")}
          </Button>
        </Stack>
      </StyledInviteTeamMemberContainer>
    </Dialog>
  );
};

export default InviteTeamMemberDialog;
