import React from "react";
import { Button } from "@mui/material";
import {
  CheckCircleIcon,
  CloudArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { StyledFileContainer, StyledUploadButtonContainer } from "./styles";
import { TUploadDocument } from "../../models/common";

const UploadDocument = ({
  placeholder,
  value,
  companyColor,
  onChange,
}: TUploadDocument) => (
  <StyledUploadButtonContainer companyColor={companyColor}>
    {value ? (
      <StyledFileContainer direction={"row"} spacing={1}>
        <CheckCircleIcon className={"success-icon"} />
        <span>{value}</span>
        <XMarkIcon
          onClick={() => {
            onChange(undefined);
          }}
          className={"remove-icon"}
        />
      </StyledFileContainer>
    ) : (
      <Button variant={"outlined"} component={"label"}>
        {placeholder}
        <input
          hidden
          type={"file"}
          onChange={(event) => onChange(event.target.files?.[0])}
        />
        <CloudArrowUpIcon />
      </Button>
    )}
  </StyledUploadButtonContainer>
);

export default UploadDocument;
