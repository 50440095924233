import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import LogoCell from "../cellRenderers/logo";
import iconAndText from "../cellRenderers/iconAndText";
import { TDataGridGetColDef } from "../../../models/DataGrid";

const getBookingsColDef = ({ t }: Pick<TDataGridGetColDef, "t">): ColDef[] => [
  {
    field: "logo",
    headerName: "" as string,
    cellRenderer: (params: ICellRendererParams) => {
      return LogoCell({ logoSrc: params.data.supplierLogo });
    },
    flex: 1.5,
  },
  {
    field: "product_name",
    headerName: t("basic_job_board.product_name") as string,
    valueFormatter: (params: ValueFormatterParams) => params.data.productName,
    flex: 2.5,
  },
  {
    field: "remaining",
    headerName: t("basic_job_board.remaining") as string,
    cellRenderer: (params: ICellRendererParams) =>
      iconAndText({
        variant: "clock",
        text: `${params.data.remainingDays} ${t("basic_job_board.of")} ${
          params.data.totalDays
        } ${t("basic_job_board.days")}`,
      }),
    flex: 1,
  },
  {
    field: "views",
    headerName: "Views",
    cellRenderer: (params: ICellRendererParams) =>
      iconAndText({
        variant: "views",
        text: params.data.views,
      }),
    flex: 0.6,
  },
  {
    field: "clicks",
    headerName: t("basic_job_board.clicks") as string,
    cellRenderer: (params: ICellRendererParams) =>
      iconAndText({
        variant: "clicks",
        text: params.data.clicks,
      }),
    flex: 0.6,
  },
];

export default getBookingsColDef;
