import React from "react";
import { InputLabel, Stack, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../components/filters/AutocompleteFilter";
import JobDescriptionField from "./JobDescriptionField";
import { IJobDescriptionFieldsComponent } from "../../models/Marketing";

const JobDescriptionFields = ({
  desktop,
  countries,
  control,
  register,
}: IJobDescriptionFieldsComponent) => (
  <Stack borderRadius={1.5} sx={{ backgroundColor: "#fff" }} p={3} spacing={3}>
    <Stack
      p={3}
      sx={{
        background: "#EEF2FF",
      }}
    >
      <InputLabel>Jobtitel</InputLabel>
      <TextField
        {...register("title")}
        placeholder="Jobtitel (m/w/d)"
        fullWidth
      />
    </Stack>
    <Stack
      direction={desktop ? "row" : "column"}
      spacing={1.5}
      p={3}
      sx={{
        background: "#EEF2FF",
      }}
    >
      <Stack flex={1} spacing={0.5}>
        <InputLabel>Land</InputLabel>
        <Controller
          name={"country"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutocompleteFilter
              placeholder="Land"
              options={countries || []}
              value={value}
              onChange={onChange}
              hasFlags
            />
          )}
        />
      </Stack>
      <Stack flex={1} spacing={0.5}>
        <InputLabel>Stadt</InputLabel>
        <TextField {...register("city")} placeholder="Stadt" fullWidth />
      </Stack>
    </Stack>
    <JobDescriptionField
      label="Einleitung Titel"
      placeholder="Über uns"
      property="aboutTitle"
      register={register}
      control={control}
      textEditorProperty="about"
      textEditorLabel="Einleitung"
    />
    <JobDescriptionField
      label="Aufgaben Titel"
      placeholder="Aufgaben"
      property="tasksTitle"
      register={register}
      control={control}
      textEditorProperty="tasks"
      textEditorLabel="Aufgaben"
    />
    <JobDescriptionField
      label="Anforderungen Titel"
      placeholder="Qualifikationen"
      property="requirementsTitle"
      register={register}
      control={control}
      textEditorProperty="requirements"
      textEditorLabel="Anforderungen"
    />
    <JobDescriptionField
      label="Benefits Titel"
      placeholder="Benefits"
      property="benefitsTitle"
      register={register}
      control={control}
      textEditorProperty="benefits"
      textEditorLabel="Benefits"
    />
    <JobDescriptionField
      label="Kontakinformationen Titel"
      placeholder="Kontaktinformationen"
      property="contactTitle"
      register={register}
      control={control}
      textEditorProperty="contact"
      textEditorLabel="Kontakinformationen"
    />
  </Stack>
);

export default JobDescriptionFields;
