import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo.svg";
import BackgroundImage from "../../components/BackgroundImage";
import {
  StyledMagicLinkContainer,
  StyledMagicLinkContent,
  StyledMagicLinkWrapper,
} from "./styles";

const MagicLinkConfirmation = ({ desktop }: { desktop: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const redirection = () => {
    navigate(-1);
  };

  return (
    <StyledMagicLinkWrapper container>
      <StyledMagicLinkContainer item xs={desktop ? 6 : 10.75} container>
        <StyledMagicLinkContent>
          <img src={logo} alt="karriera logo" />
          <Typography variant="body1">
            {t("magic_link_confirmation_page.title")}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" paddingTop={1}>
            {t("magic_link_confirmation_page.text1")}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {t("magic_link_confirmation_page.text2")}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" paddingTop={3}>
            {t("magic_link_confirmation_page.text3")}
          </Typography>
          <Box mt={2}>
            <Link className={"link-back-to-job"} onClick={redirection}>
              {t("magic_link_confirmation_page.back_to_job")}
            </Link>
          </Box>
        </StyledMagicLinkContent>
      </StyledMagicLinkContainer>
      {desktop && <BackgroundImage />}
    </StyledMagicLinkWrapper>
  );
};

export default MagicLinkConfirmation;
