import React from "react";
import MessageDialog from "../../components/dialogs/MessageDialog";
import ApplicantsListDialog from "../../components/dialogs/MessageDialog/ApplicantsListDialog";
import { IDialogComponent, MESSAGES_DIALOG_TYPE } from "../../models/Messages";

const RenderDialogComponent = ({
  t,
  openedDialogType,
  onCloseDialog,
  onSaveDialog,
}: IDialogComponent) => {
  switch (openedDialogType) {
    case MESSAGES_DIALOG_TYPE.CONFIRMATION:
      return (
        <MessageDialog
          t={t}
          isOpen={openedDialogType === MESSAGES_DIALOG_TYPE.CONFIRMATION}
          title={t("messages_page.edit_dialog_title")}
          body={t("messages_page.edit_dialog_text")}
          saveButtonText={t("button.save")}
          onClose={onCloseDialog}
          onSave={onSaveDialog}
        />
      );
    case MESSAGES_DIALOG_TYPE.DELETE:
      return (
        <MessageDialog
          t={t}
          isOpen={openedDialogType === MESSAGES_DIALOG_TYPE.DELETE}
          title={t("messages_page.delete_message_dialog_title")}
          body={t("messages_page.delete_message_dialog_text")}
          saveButtonText={t("messages_page.delete_message_dialog_title")}
          onClose={onCloseDialog}
          onSave={onSaveDialog}
        />
      );
    case MESSAGES_DIALOG_TYPE.DISCARD:
      return (
        <MessageDialog
          t={t}
          isOpen={openedDialogType === MESSAGES_DIALOG_TYPE.DISCARD}
          title={t("messages_page.discard_dialog_title")}
          body={t("messages_page.discard_dialog_text")}
          saveButtonText={t("button.save")}
          onClose={onCloseDialog}
          onSave={onSaveDialog}
        />
      );
    case MESSAGES_DIALOG_TYPE.NEW_CHAT:
      return (
        <ApplicantsListDialog
          t={t}
          isOpen={openedDialogType === MESSAGES_DIALOG_TYPE.NEW_CHAT}
          title={t("messages_page.new_conversation")}
          onClose={onCloseDialog}
        />
      );
    default:
      return null;
  }
};
export default RenderDialogComponent;
