import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import KanbanBoard from "../../components/Kanban/index";
import {
  setApplicationStatus,
  setView,
} from "../../store/reducers/Applications";
import { ICardView } from "../../models/Kanban";
import { getSingleJobState } from "../../store/selectors/SingleJob";
import { TSelectedJobCandidateApplication } from "../../models/ApplicationPage";

export default function CardView({
  t,
  statuses,
  initialColumns,
  variant = "candidate",
  isApplicationView = false,
  onItemClick,
}: ICardView) {
  const dispatch = useDispatch();
  const { jobId } = useSelector(getSingleJobState);

  const handleOnChangeStatus = (
    applicationId: string,
    newStatus: string,
    index: string
  ) => {
    if (isApplicationView) {
      dispatch(
        setApplicationStatus({
          applicationId,
          newStatus,
          index: index ?? "0",
        })
      );
    }
  };

  useEffect(() => {
    if (!jobId) {
      dispatch(setView("table"));
    }
  }, [jobId]);

  return (
    <KanbanBoard
      t={t}
      variant={variant}
      statuses={statuses}
      initialColumns={initialColumns}
      onChangeStatus={handleOnChangeStatus}
      onItemClick={
        onItemClick as (application: TSelectedJobCandidateApplication) => void
      }
    />
  );
}
