import {
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import ActionsCell from "../cellRenderers/actions";
import StatusCell from "../cellRenderers/status";
import { TDataGridGetColDef } from "../../../models/DataGrid";
import { TEAM_USER_STATUSES } from "../../../models/Team";

const getUsersColDef = ({
  t,
  actions,
}: Pick<TDataGridGetColDef, "t" | "actions">): ColDef[] => [
  {
    field: "name",
    headerName: t("applications.name") as string,
    flex: 1,
    valueGetter: (params: ValueGetterParams) => {
      const user = params.data;
      return user.status === TEAM_USER_STATUSES.JOINED
        ? `${user.firstname} ${user.lastname}`
        : "-";
    },
  },
  {
    field: "email",
    flex: 1,
    valueGetter: (params: ValueGetterParams) => {
      const user = params.data;
      return user.status === TEAM_USER_STATUSES.JOINED
        ? user.username
        : user.email;
    },
  },
  {
    field: "contract_type",
    headerName: t("team_members.date_invited") as string,
    flex: 0.5,
  },
  {
    field: "status",
    flex: 0.5,
    cellRenderer: (params: ICellRendererParams) =>
      StatusCell({
        label: params.value,
        type: params.value,
      }),
  },
  {
    field: "role",
    headerName: t("team_members.role") as string,
    flex: 0.5,
    cellRenderer: (params: ICellRendererParams) =>
      StatusCell({
        label: params.value,
        type: params.value,
      }),
  },
  {
    field: "reinviateTeamMember",
    headerName: "",
    flex: 0.6,
    hide: actions && !actions.onReInviteTeamMember,
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onReInviteTeamMember: () =>
            actions.onReInviteTeamMember &&
            actions.onReInviteTeamMember(params.data.email),
        },
      }),
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    flex: 0.25,
    cellStyle: { justifyContent: "center" },
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onDelete: () => actions.onDelete && actions.onDelete(params.data),
        },
      }),
  },
];

export default getUsersColDef;
