import React from "react";
import { Stack, Typography } from "@mui/material";
import { Controller, FieldArrayWithId } from "react-hook-form";
import UploadDocument from "../../../../components/UploadDocument";
import { IAdditionalQuestionsDocumentsComponent } from "../../../../models/JobPreview";
import { StyledAdditionalQuestionsFieldContainer } from "../../styles";
import { TListOption } from "../../../../models/common";

const AdditionalQuestionsDocumentsComponent = ({
  t,
  control,
  desktop,
  fields,
  companyColor,
}: IAdditionalQuestionsDocumentsComponent) => (
  <StyledAdditionalQuestionsFieldContainer desktop={desktop}>
    <Stack className={"title"}>
      <Typography variant="subtitle2">
        {t("additional_questions.documents")}
      </Typography>
    </Stack>
    <Stack className={"input-field"} spacing={2}>
      {fields.map((field: FieldArrayWithId<TListOption>, index: number) => (
        <Controller
          key={field.id}
          name={`documents.${index}`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <UploadDocument
              companyColor={companyColor}
              placeholder={
                t("additional_questions.documents_placeholder") as string
              }
              value={value.name}
              onChange={(file) => {
                onChange({ file, name: file?.name || "" });
              }}
            />
          )}
        />
      ))}
    </Stack>
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsDocumentsComponent;
