import React from "react";
import { Stack, Typography } from "@mui/material";
import { generateActivityLogDate, getTimeAgo } from "../../utils";
import { IByDateText } from "../../models/Applications";

const ByDateText = ({ t, firstname, lastname, date }: IByDateText) => (
  <Stack direction="row" width="100%" justifyContent="space-between" pr={3}>
    <Typography color="#6B7280" fontSize={12}>
      {firstname ? t("candidate.by_candidate") : ""} {firstname || ""}{" "}
      {lastname || ""} {t("candidate.on_date")} {generateActivityLogDate(date)}
    </Typography>
    <Typography color="#6B7280" fontSize={12}>
      {getTimeAgo(date)}
    </Typography>
  </Stack>
);

export default ByDateText;
