import React from "react";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import disabledAnalytics from "../../assets/disabledAnalytics.png";
import {
  StyledPremiumRequestBGContainer,
  StyledPremiumRequestBGShadeContainer,
  StyledPremiumRequestContainer,
  StyledPremiumRequestPaperContainer,
} from "./styles";
import { extendLimit } from "../../store/reducers/dialogs";
import { getDialogsState } from "../../store/selectors/Dialogs";

const PremiumRequestComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isExtendLimitRequested, isExtendLimitRequestLoading } =
    useSelector(getDialogsState);

  const handleOnRequestPremium = () => {
    dispatch(extendLimit({ callback: () => {} }));
  };
  return (
    <StyledPremiumRequestContainer>
      <StyledPremiumRequestBGContainer />
      <StyledPremiumRequestBGShadeContainer />
      <StyledPremiumRequestPaperContainer>
        <Stack className={"image-container"}>
          <img alt={"analytics PRO"} src={disabledAnalytics} />
        </Stack>
        <Stack className={"title-container"}>
          <h2>{t("limit_dialog.usePro")}</h2>
        </Stack>
        <Stack className={"main-text"}>
          {t(
            `limit_dialog.${
              isExtendLimitRequested ? "confirmation_text" : "text"
            }`
          )}
        </Stack>
        {!isExtendLimitRequested && (
          <LoadingButton
            fullWidth
            variant={"contained"}
            onClick={handleOnRequestPremium}
            loading={isExtendLimitRequestLoading}
          >
            {t("limit_dialog.button")}
          </LoadingButton>
        )}
      </StyledPremiumRequestPaperContainer>
    </StyledPremiumRequestContainer>
  );
};

export default PremiumRequestComponent;
