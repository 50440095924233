import * as yup from "yup";
import { TFunction } from "i18next";
import { TLoginFormFields } from "../../models/Auth";

export const getFormSettings = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t("valid_email_message") as string)
      .required(t("register.message") as string),
    password: yup.string().required(t("register.message") as string),
  });

export const formDefaultValues: TLoginFormFields = {
  email: "",
  password: "",
};
