import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TalentPoolHeaderComponent from "./component";
import { getTalentPoolState } from "../../../store/selectors/Talentpool";
import { DIALOG_TYPE } from "../../../models/common";
import { setIsTalentPoolsDialogOpen } from "../../../store/reducers/dialogs";
import { setEditedTalentPool } from "../../../store/reducers/TalentPools";

const TalentPoolHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedTalentPool } = useSelector(getTalentPoolState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const redirectToMainPage = () => {
    navigate("/talentpools");
  };

  const handleOnOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOnCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOnOpenTalentPoolDialog = (type: DIALOG_TYPE) => {
    dispatch(setIsTalentPoolsDialogOpen(type));
    if (type === DIALOG_TYPE.CREATE_EDIT_TALENT_POOL) {
      dispatch(setEditedTalentPool(selectedTalentPool));
    }
  };

  return (
    <TalentPoolHeaderComponent
      t={t}
      talentPool={selectedTalentPool}
      anchorEl={anchorEl}
      onNavigateBack={redirectToMainPage}
      onCloseMenu={handleOnCloseMenu}
      onOpenMenu={handleOnOpenMenu}
      onOpenTalentPoolDialog={handleOnOpenTalentPoolDialog}
    />
  );
};

export default TalentPoolHeader;
