import React from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import AuthLayout from "../../../layouts/Auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ConfirmationPassword = ({ desktop }: { readonly desktop: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleOnReturn = () => {
    navigate("/");
  };

  return (
    <AuthLayout desktop={desktop}>
      <Typography variant="body1" lineHeight={2.5}>
        {t("login.password_changed_title")}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" mb={3}>
        {t("login.password_changed_text")}
      </Typography>
      <Box>
        <Button
          color={"primary"}
          variant={"contained"}
          size={"small"}
          onClick={handleOnReturn}
          disableElevation
          fullWidth
        >
          {t("login.sign_in")}
        </Button>
      </Box>
      <Link
        variant="subtitle2"
        underline="none"
        display="inline"
        onClick={handleOnReturn}
      >
        {t("reset_password_page.link")}
      </Link>
    </AuthLayout>
  );
};

export default ConfirmationPassword;
