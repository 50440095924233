import React from "react";
import { Stack, Typography } from "@mui/material";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import SelectorFilter from "../../../components/filters/SelectorFilter";
import { TAdditionalQuestionsHeader } from "../../../models/common";

const logoSize = { width: 130, height: 64, objectFit: "cover" };

const AdditionalQuestionsHeader = ({
  t,
  companyTitle,
  logo,
  language,
  languages,
  desktop,
  onChangeLanguage,
}: TAdditionalQuestionsHeader) => (
  <Stack
    direction={desktop ? "row" : "column"}
    justifyContent={desktop ? "space-between" : "center"}
    alignItems="center"
    spacing={desktop ? 0 : 2}
  >
    <Stack
      direction={desktop ? "row" : "column"}
      spacing={2}
      alignItems="center"
    >
      {logo ? (
        <img
          className="company-logo"
          alt={"company logo"}
          src={logo}
          {...logoSize}
        />
      ) : (
        <BuildingOffice2Icon {...logoSize} />
      )}
      <Stack spacing={1.5} justifyContent="center">
        <Typography
          variant="body1"
          fontSize={desktop ? 24 : 20}
          fontWeight={500}
        >
          {companyTitle}
        </Typography>
      </Stack>
    </Stack>
    <SelectorFilter
      className={"input-selector-filter"}
      label={t("userSettings.language")}
      value={language}
      options={languages}
      onChange={onChangeLanguage}
      hideCloseIcon
    />
  </Stack>
);

export default AdditionalQuestionsHeader;
