import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PersonalInformationComponent from "./component";
import { IApplicationPersonalInfo } from "../../../models/ApplicationPage";
import { TListOption } from "../../../models/common";
import {
  fetchCandidateApplications,
  fetchCountries,
  updateCandidateApplication,
} from "../../../store/reducers/Candidate";
import { getCandidateState } from "../../../store/selectors/Candidate";
import { getPersonalInfoFormFields } from "./config";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
} from "../../../store/selectors/CurrentUser";
import { setIsRequestDataDialogOpen } from "../../../store/reducers/dialogs";
import {
  setRequestedDataList,
  setRequestedDataTitle,
} from "../../../store/reducers/Applications";

const PersonalInfo = ({
  currentApplication,
  desktop,
}: IApplicationPersonalInfo) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const { email } = useSelector(getCurrentUserData);
  const { isLoading, countries } = useSelector(getCandidateState);

  const [isEditMode, setIsEditMode] = useState(false);

  const currentCountry = useMemo(() => {
    if (currentApplication && countries.length)
      return countries.find(
        (country: TListOption) => country.value === currentApplication.country
      );
  }, [countries, currentApplication]);

  useEffect(() => {
    if (!countries.length && !isLoading) {
      dispatch(fetchCountries());
    }
  }, [countries]);

  useEffect(() => {
    if (currentApplication) {
      setValue("phone", currentApplication.phone);
      setValue("location", currentApplication.location);
      setValue("country", currentApplication.country);
    }
  }, [currentApplication]);

  const { register, control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(getPersonalInfoFormFields()),
    defaultValues: {
      phone: currentApplication?.phone || "",
      location: currentApplication?.location || "",
      country: currentApplication?.country || "",
    },
  });

  const handleOnEdit = (event: MouseEvent) => {
    setIsEditMode(true);
    event.preventDefault();
  };

  const handleOnSubmit = (val: {
    phone: string;
    location: string;
    country: string;
  }) => {
    if (currentApplication?.id) {
      const apiPayload = {
        ...val,
        applicationId: currentApplication.id.toString(),
      };
      dispatch(
        updateCandidateApplication({
          ...apiPayload,
          callback: handleOnSubmitSuccess,
        })
      );
    }
  };

  const handleOnSubmitSuccess = () => {
    dispatch(fetchCandidateApplications());
    setIsEditMode(false);
  };

  const emptyFields = useMemo(() => {
    const { email, phone, location, country } = currentApplication || {};
    return [{ email }, { phone }, { location }, { country }].reduce(
      (
        acc: { title: string; id: string }[],
        field: { [x: string]: string | undefined }
      ) =>
        !Object.values(field)[0]
          ? [
              ...acc,
              {
                title: t(`candidate.${Object.keys(field)[0]}`),
                id: Object.keys(field)[0],
              },
            ]
          : acc,
      []
    );
  }, []);

  const handleOnRequestData = () => {
    if (emptyFields.length) {
      dispatch(setIsRequestDataDialogOpen(true));
      dispatch(setRequestedDataList(emptyFields));
      dispatch(setRequestedDataTitle(t("request_personal_information")));
    }
  };

  return (
    <PersonalInformationComponent
      t={t}
      isCandidate={Boolean(isCandidate)}
      control={control}
      register={register}
      handleSubmit={handleSubmit}
      emptyFields={emptyFields}
      email={currentApplication?.email || email || ""}
      phone={currentApplication?.phone || ""}
      location={currentApplication?.location || ""}
      country={currentCountry?.label}
      countries={countries}
      isEditMode={isEditMode}
      desktop={desktop}
      onEdit={handleOnEdit}
      onSubmit={handleOnSubmit}
      onRequestData={handleOnRequestData}
    />
  );
};

export default PersonalInfo;
