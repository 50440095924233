import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "debounce";
import MarketingPageComponent from "./component";
import { getMarketingState } from "../../store/selectors/Marketing";
import {
  sendJobCompanyData,
  setIsAgree,
  setJobData,
  setJobUrl,
  setStep,
} from "../../store/reducers/Marketing";
import { fetchCountries } from "../../store/reducers/CompanySettings";
import { getCompanySettingsCompanyState } from "../../store/selectors/Company";
import { formDefaultValues, getCountrySettings } from "./config";
import { TMarketingForm } from "../../models/Marketing";
import { openUrlnNewTab } from "../../utils";

const Marketing = ({ desktop }: { readonly desktop: boolean }) => {
  const dispatch = useDispatch();

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { countries } = useSelector(getCompanySettingsCompanyState);

  const {
    control,
    formState: { errors },
    register,
    watch,
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(getCountrySettings()),
    defaultValues: formDefaultValues,
  });

  const { jobData, isAgree, step } = useSelector(getMarketingState);

  const orderDisabledFirstStep = !jobData;
  const orderDisabledSecondStep = !isAgree || Object.keys(errors).length;
  const isOrderDisabled =
    step === 1 ? orderDisabledFirstStep : orderDisabledSecondStep;

  const handleChangeJobUrl = (currentJobUrl: string) => {
    if (currentJobUrl.length) {
      setIsError(false);
    }
    dispatch(setJobUrl(currentJobUrl));
  };

  // WE WILL USE IN THE FUTURE
  /*   const handleGenerateJobData = () => {
    if (!jobUrl.length) {
      setIsError(true);
      return;
    }
    dispatch(extractJobData(jobUrl));
  }; */

  const handleGenerateJobData = () => {
    dispatch(setJobData(formDefaultValues));
  };

  const handleOpenSecondStep = () => {
    if (step === 1) {
      setIsLoading(true);
      dispatch(setStep(2));
    }
  };

  const handleSendData = (data: TMarketingForm) => {
    dispatch(
      sendJobCompanyData({
        content: data,
        callback: () => dispatch(setStep(3)),
      })
    );
  };

  const handleChangeAgreement = () => {
    dispatch(setIsAgree(!isAgree));
  };

  const handleRequestAppointment = () => {
    openUrlnNewTab("https://calendly.com/klessny");
  };

  useEffect(() => {
    const debouncedDispatch = debounce((value: any) => {
      dispatch(setJobData(value));
    }, 300);

    const { unsubscribe } = watch((value) => {
      debouncedDispatch(value);
    });

    return () => {
      unsubscribe();
    };
  }, [watch, jobData, dispatch]);

  useEffect(() => {
    dispatch(fetchCountries());
    const footerContainer = document.querySelector(".footer-container");
    if (footerContainer) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      footerContainer.style.display = "none";
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        footerContainer.style.display = "flex";
      }, 500);
    }
  }, [step]);

  useEffect(() => {
    if (step === 2) {
      setTimeout(() => {
        clearErrors();
      }, 1);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [step]);

  return (
    <MarketingPageComponent
      desktop={desktop}
      isOrderDisabled={Boolean(isOrderDisabled)}
      isAgree={isAgree}
      jobData={jobData}
      isError={isError}
      countries={countries}
      control={control}
      step={step}
      errors={errors}
      isLoading={isLoading}
      onRequestAppointment={handleRequestAppointment}
      onOpenSecondStep={handleOpenSecondStep}
      handleSubmit={handleSubmit}
      register={register}
      onChangeJobUrl={handleChangeJobUrl}
      onGenerateJobData={handleGenerateJobData}
      onSendData={handleSendData}
      onChangeAgreement={handleChangeAgreement}
    />
  );
};

export default Marketing;
