import React from "react";
import { InputLabel, Stack } from "@mui/material";
import IconWithTooltip from "./IconWithTooltip";
import { IApplicationAdditionalInfoQuestions } from "../../../models/ApplicationPage";
import { StyledQuestionTitle, StyledTextField } from "../styles";
import { TJobDetails } from "../../../models/SingleJob";

const Questions = ({
  t,
  questions,
  jobDetails,
}: IApplicationAdditionalInfoQuestions) => (
  <Stack spacing={2}>
    {questions.map((item) => (
      <Stack key={item.label ? item.label : (item.labelKey as string)}>
        <StyledQuestionTitle direction={"row"}>
          <InputLabel>
            {item.label ? item.label : t(item.labelKey as string)}
          </InputLabel>
          {jobDetails?.[
            `${item.type}_required` as keyof Omit<
              TJobDetails,
              "job_type_id" | "job_type"
            >
          ] ? (
            <IconWithTooltip />
          ) : null}
        </StyledQuestionTitle>
        <StyledTextField
          value={
            item.isTypeYesNo
              ? t(item.value.toString().toLowerCase()) ||
                t("candidate_questions.no_answer")
              : item.value && item.value != "Invalid date"
                ? item.value
                : t("candidate_questions.no_answer")
          }
          disabled
        />
      </Stack>
    ))}
  </Stack>
);

export default Questions;
