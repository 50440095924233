import React from "react";
import { Stack, Typography, Box, Tooltip } from "@mui/material";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import {
  BanknotesIcon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  ChevronRightIcon,
  FolderOpenIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { StyledJobContainer, StyledJobItemContainer } from "./styles";
import { formatLogoUrl } from "../../utils";
import { convertSalaryString, getSalarySymbol } from "../../utils/common";
import { StyledStatusChip } from "../../components/DataGrid/styles";
import { ICandidateApplicationComponent } from "../../models/ApplicationPage";
import { APPLICATION_STATUSES } from "../../models/Applications";

const CandidateApplication = ({
  t,
  desktop,
  application,
  allStatuses,
  onSelectJob,
}: ICandidateApplicationComponent) => (
  <StyledJobContainer
    key={application.id!.toString()}
    direction={"row"}
    spacing={2}
    p={2}
    onClick={() => {
      onSelectJob(application);
    }}
    sx={{ background: application.is_unlock_requested ? "#fffbeb" : "#fff" }}
  >
    {desktop && (
      <Box>
        {application.job.logo ? (
          <img
            src={formatLogoUrl(application.job.logo)}
            width={56}
            height={56}
            alt={"company logo"}
            style={{
              objectFit: "contain",
            }}
          />
        ) : (
          <BuildingOffice2Icon className={"company-logo"} />
        )}
      </Box>
    )}
    <Stack width={"100%"} spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant={"h6"}>{application.job.title}</Typography>
        {application.is_unlock_requested && (
          <Tooltip title={t("candidate.persmissions_requested")}>
            <ExclamationCircleIcon width={24} height={24} fill="#178CF2" />
          </Tooltip>
        )}
      </Stack>
      <Stack direction={desktop ? "row" : "column"} spacing={desktop ? 2 : 1}>
        <StyledJobItemContainer direction="row" spacing={1}>
          <MapPinIcon />
          <Typography
            variant="subtitle2"
            display="inline"
            color="textSecondary"
          >
            {`${application?.job_locations[0]?.city}, ${application?.job_locations[0]?.nicename}`}
          </Typography>
        </StyledJobItemContainer>
        <StyledJobItemContainer direction="row" spacing={1}>
          <BriefcaseIcon />
          <Typography
            variant="subtitle2"
            display="inline"
            color="textSecondary"
          >
            {application.job.position_type}
          </Typography>
        </StyledJobItemContainer>
        <StyledJobItemContainer direction="row" spacing={1}>
          <FolderOpenIcon />
          <Typography
            variant="subtitle2"
            display="inline"
            color="textSecondary"
          >
            {application.job.work_type}
          </Typography>
        </StyledJobItemContainer>
        {application.job.min_salary && application.job.max_salary && (
          <StyledJobItemContainer direction="row" spacing={1}>
            <BanknotesIcon className="icon" />
            <Typography
              variant="subtitle2"
              display="inline"
              color="textSecondary"
            >
              {t("jobPreview.salary", {
                currency: getSalarySymbol(
                  application.job.salary_currency || ""
                ),
                min: convertSalaryString(application.job.min_salary),
                max: convertSalaryString(application.job.max_salary),
              })}

              {application.job?.salary_type && (
                <>
                  <span> / </span>
                  {t(
                    `singleJob.jobDetails.salary.type.${application.job?.salary_type}`
                  )}
                </>
              )}
            </Typography>
          </StyledJobItemContainer>
        )}
        {application.status && (
          <StyledStatusChip
            sx={{
              width: "fit-content",
              alignSelf: "flex-end",
              textTransform: "capitalize",
            }}
            type={
              allStatuses.includes(application.status)
                ? application.status
                : APPLICATION_STATUSES.REVIEW.toLowerCase()
            }
            label={
              allStatuses.includes(application.status)
                ? t(`status.${application.status}`)
                : t("status.in_progress")
            }
          />
        )}
      </Stack>
    </Stack>
    {desktop && <ChevronRightIcon className={"next-icon"} />}
  </StyledJobContainer>
);

export default CandidateApplication;
