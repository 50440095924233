import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardApplicationsComponent from "./component";
import {
  getApplicationListState,
  getApplicationsIsListLoading,
} from "../../../store/selectors/Applications";
import { setSelectedJobApplications } from "../../../store/reducers/Applications";
import { TSelectedJobCandidateApplication } from "../../../models/ApplicationPage";

const DashboardApplications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { list } = useSelector(getApplicationListState);
  const isLoading = useSelector(getApplicationsIsListLoading);
  const applicationList = useMemo(() => {
    if (list) {
      if (list.length > 5) return list.slice(0, 5);
      return list;
    }
    return [];
  }, [list]);

  const handleOnJobNavigate = (
    application: TSelectedJobCandidateApplication
  ) => {
    dispatch(setSelectedJobApplications([application]));
    navigate(
      `/applications/candidate/${application.candidate_url_key}/${application.job_id}`
    );
  };

  return (
    <DashboardApplicationsComponent
      t={t}
      applicationList={applicationList}
      isLoading={isLoading}
      onApplicationNavigate={handleOnJobNavigate}
    />
  );
};

export default DashboardApplications;
