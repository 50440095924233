import React from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Stack, Typography } from "@mui/material";
import { ITopBar } from "../../models/JobEditor";

const TopBarComponent = ({ t, currentStep, onPrevStep, onClose }: ITopBar) => (
  <Stack py={4} spacing={2} direction={"row"}>
    <ArrowLeftIcon
      onClick={currentStep > 1 && currentStep < 4 ? onPrevStep : onClose}
      width={24}
      height={24}
      cursor={"pointer"}
    />
    <Typography variant="body1" color="#0F141B">
      {currentStep < 4
        ? t("create_job_first_step.create_job_ad")
        : t("cart.empty_button")}
    </Typography>
  </Stack>
);

export default TopBarComponent;
