import React from "react";
import { Stack } from "@mui/material";
import Job from "./Job";
import Pagination from "../../components/Pagination";
import { TCompanyInfoJobs, TJob } from "../../models/CompanyInfo";

const Jobs = ({
  company,
  page,
  desktop,
  jobWidgetPage = false,
  paginate,
}: TCompanyInfoJobs) => (
  <>
    <Stack width="100%" spacing={1.5} id="jobs-widget-jobs">
      {company?.jobs?.map((job: TJob) => (
        <Job
          key={job.id}
          desktop={desktop}
          job={job}
          company={company}
          jobWidgetPage={jobWidgetPage}
        />
      ))}
    </Stack>
    {parseInt(company?.jobs_total as string) > 10 && (
      <Pagination
        desktop={desktop}
        totalPosts={company?.jobs_total as string}
        itemsPerPage={10}
        page={page}
        paginate={paginate}
      />
    )}
  </>
);

export default Jobs;
