import React from "react";
import { Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import NoJobsImg from "../../../assets/no_jobs.png";
import { IApplicationsNoResultComponent } from "../../../models/Applications";
import { StyledNoResultContainer } from "../styles";
import { setSelectedJobTab } from "../../../store/reducers/SingleJob";
import { JOB_TABS_KEY } from "../../../models/SingleJob";

const JobListNoResultComponent = ({
  t,
  bodyText,
  titleText,
  hasFilters,
  showPremiumButton = true,
  onResetFilters,
}: IApplicationsNoResultComponent) => {
  const dispatch = useDispatch();

  return (
    <>
      <StyledNoResultContainer
        data-testid={"applications-list-empty-list-wrapper"}
      >
        <img src={NoJobsImg} alt="no-jobs" />
        <Typography variant="h6" paddingTop={4.5}>
          {titleText}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          paddingTop={1}
          paddingBottom={3}
        >
          {bodyText}
        </Typography>
        {hasFilters ? (
          <Button variant="contained" onClick={onResetFilters}>
            {t("joblist.reset_filters_button")}
          </Button>
        ) : showPremiumButton ? (
          <Button
            variant="contained"
            onClick={() =>
              dispatch(setSelectedJobTab(JOB_TABS_KEY.PREMIUM_JOB_BOARDS))
            }
          >
            {t("applications.empty_button")}
          </Button>
        ) : null}
      </StyledNoResultContainer>
    </>
  );
};

export default JobListNoResultComponent;
