import React from "react";
import { Box, InputLabel, Stack, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import ConnectedAgencyHeader from "../../../components/ConnectedAgencyHeader";
import AutocompleteFilter from "../../../components/filters/AutocompleteFilter";
import { ICompanySettingsGeneralInfo } from "../../../models/CompanySettings";
import { StyledSectionContainer } from "../styles";

const GeneralInfo = ({
  t,
  errors,
  control,
  countries,
  company,
  register,
  onOpenDisconnectDialog,
}: ICompanySettingsGeneralInfo) => (
  <Box>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="body2">
        {t("create_job_second_step.general_information")}
      </Typography>
      {company?.agency_name && (
        <ConnectedAgencyHeader
          t={t}
          agencyName={company.agency_name}
          connectionDate={company.connected_to_agency_at}
          openDisconnectDialog={onOpenDisconnectDialog}
        />
      )}
    </Stack>
    <StyledSectionContainer spacing={1.5}>
      <Stack direction={"row"} spacing={3}>
        <Box sx={{ flex: 1 }} mb={errors["title"] ? 1 : 0}>
          <InputLabel>{t("company_settings.company_name")}</InputLabel>
          <TextField
            placeholder={t("company_settings.company_name") as string}
            fullWidth
            {...register("title")}
            error={Boolean(errors["title"])}
            helperText={errors["title"]?.message}
          />
        </Box>
        <Stack direction={"row"} sx={{ flex: 1 }} spacing={3}>
          <Box sx={{ flex: 3 }} mb={errors["city"] ? 1 : 0}>
            <InputLabel>{t("update_company_information.city")}</InputLabel>
            <TextField
              placeholder={t("update_company_information.city") as string}
              fullWidth
              {...register("city")}
              error={Boolean(errors["city"])}
              helperText={errors["city"]?.message}
            />
          </Box>
          <Box sx={{ flex: 1 }} mb={errors["zip"] ? "8px!important" : 0}>
            <InputLabel>{t("update_company_information.postcode")}</InputLabel>
            <TextField
              placeholder={t("update_company_information.postcode") as string}
              fullWidth
              {...register("zip")}
              error={Boolean(errors["zip"])}
              helperText={errors["zip"]?.message}
            />
          </Box>
        </Stack>
      </Stack>
      <Stack direction={"row"} spacing={3}>
        <Stack direction={"row"} sx={{ flex: 1 }} spacing={3}>
          <Box sx={{ flex: 3 }} mb={errors["street"] ? 1 : 0}>
            <InputLabel>{t("update_company_information.street")}</InputLabel>
            <TextField
              placeholder={t("update_company_information.street") as string}
              fullWidth
              {...register("street")}
              error={Boolean(errors["street"])}
              helperText={errors["street"]?.message}
            />
          </Box>
          <Box sx={{ flex: 1 }} mb={errors["number"] ? 1 : 0}>
            <InputLabel>{t("update_company_information.number")}</InputLabel>
            <TextField
              placeholder={t("update_company_information.number") as string}
              {...register("number")}
              fullWidth
              error={Boolean(errors["number"])}
              helperText={errors["number"]?.message}
            />
          </Box>
        </Stack>
        <Box sx={{ flex: 1 }}>
          <InputLabel>{t("update_company_information.country")}</InputLabel>
          <Controller
            name={"country"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                placeholder={t("update_company_information.country") as string}
                options={countries}
                value={value}
                onChange={onChange}
                hasFlags
                errors={errors["country"]}
              />
            )}
          />
        </Box>
      </Stack>
      <Stack direction={"row"} spacing={3}>
        <Box sx={{ flex: 1 }}>
          <InputLabel>
            {`${t("company_settings.link_company_profile")} Linkedin`}
          </InputLabel>
          <TextField
            placeholder={"https://linkedin.com/company/mycompany/"}
            fullWidth
            {...register("linkedin")}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <InputLabel>
            {`${t("company_settings.link_company_profile")} Xing`}
          </InputLabel>
          <TextField
            placeholder={"https://www.xing.com/pages/karriera"}
            fullWidth
            {...register("xing")}
          />
        </Box>
      </Stack>
      <Stack direction={"row"} spacing={3}>
        <Box sx={{ flex: 1 }}>
          <InputLabel>{t("company_settings.privacy_link")}</InputLabel>
          <TextField
            placeholder={t("company_settings.privacy_link") as string}
            fullWidth
            {...register("pp_link")}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <InputLabel>{t("company_settings.terms_link")}</InputLabel>
          <TextField
            placeholder={t("company_settings.terms_link") as string}
            fullWidth
            {...register("tc_link")}
          />
        </Box>
      </Stack>
    </StyledSectionContainer>
  </Box>
);

export default GeneralInfo;
