import React from "react";
import { Stack, Typography } from "@mui/material";
import { openUrlnNewTab } from "../../utils";
import { TWebSiteItem } from "../../models/CompanyInfo";

const WebSiteItem = ({ url, text, icon, companyColor }: TWebSiteItem) => (
  <Stack
    px={2}
    direction="row"
    spacing={1}
    border="1px solid #E5E7EB"
    borderRadius="100px"
    color="#131E30"
    alignItems="center"
    justifyContent="flex-end"
    height={40}
    sx={{
      cursor: "pointer",
      "&:hover": {
        border: `1px solid ${companyColor} !important`,
      },
    }}
    onClick={() => openUrlnNewTab(url)}
  >
    {icon}

    <Typography fontSize={14} fontWeight={500} paddingTop={0.5}>
      {text}
    </Typography>
  </Stack>
);

export default WebSiteItem;
