import React from "react";
import { Stack, Typography, Tooltip } from "@mui/material";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useDispatch } from "react-redux";
import { StyledActionButton } from "../styles";
import { useTranslation } from "react-i18next";
import {
  fetchApplications,
  requestApplicationUnlock,
} from "../../../store/reducers/Applications";
import { IDataGridGDPR } from "../../../models/DataGrid";

const GDPR = ({ table, isUnlockRequested, applicationId }: IDataGridGDPR) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleRequestApplicationUnlock = () => {
    if (!isUnlockRequested) {
      dispatch(
        requestApplicationUnlock({
          applicationId,
          callback: () => dispatch(fetchApplications({ withoutLoader: true })),
        })
      );
    }
  };

  return (
    <Stack
      direction="row"
      height="100%"
      alignItems="center"
      position="fixed"
      zIndex={1}
      spacing={table === "small" ? 4 : 8}
    >
      <Stack direction="row" height="100%" alignItems="center" spacing={0.5}>
        <Typography
          maxWidth={table === "small" ? 260 : 500}
          whiteSpace="pre-line"
          fontSize={12}
          color="textSecondary"
        >
          {table === "small"
            ? t("applications.information_not_available")
            : t("applications.gdpr")}
        </Typography>
        {table === "small" && (
          <Tooltip title={t("applications.gdpr")}>
            <ExclamationCircleIcon width={24} height={24} fill="#178CF2" />
          </Tooltip>
        )}
      </Stack>
      <StyledActionButton
        disabled={isUnlockRequested}
        onClick={handleRequestApplicationUnlock}
      >
        {isUnlockRequested
          ? t("applications.persmissions_requested")
          : t("applications.request_permission")}
      </StyledActionButton>
    </Stack>
  );
};

export default GDPR;
