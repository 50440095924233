import React from "react";
import { Stack } from "@mui/material";
import SelectedTriggerAutomation from "./SelectedTriggerAutomation";
import {
  ACTION_TRIGGER_VARIANT,
  IAutomationAction,
  IAutomationInfoComponent,
} from "../../models/Automation";

const AutomationInfo = ({
  t,
  currentAutomation,
  onOpenSelectActionContainer,
  onOpenTrigger,
  onOpenAction,
  onDeleteTriggerAction,
  onHoverLeaveDeleteActionIcon,
}: IAutomationInfoComponent) => (
  <Stack className="automation-info-container">
    <SelectedTriggerAutomation
      t={t}
      onClick={onOpenTrigger}
      variant={ACTION_TRIGGER_VARIANT.TRIGGER}
      data={{
        name: currentAutomation?.trigger?.name,
        const: currentAutomation?.trigger?.const,
      }}
      subText={
        currentAutomation.trigger
          ? undefined
          : (t("automation.selectTriggerSubTitle") as string)
      }
      currentAutomation={currentAutomation}
      onDeleteTriggerAction={onDeleteTriggerAction}
      onHoverLeaveDeleteActionIcon={onHoverLeaveDeleteActionIcon}
    />
    {currentAutomation.actions.map(
      (action: IAutomationAction, index: number) => (
        <SelectedTriggerAutomation
          key={index}
          onClick={onOpenAction as any}
          t={t}
          variant={ACTION_TRIGGER_VARIANT.COMPONENT}
          data={action}
          index={index}
          currentAutomation={currentAutomation}
          onDeleteTriggerAction={onDeleteTriggerAction}
          onHoverLeaveDeleteActionIcon={onHoverLeaveDeleteActionIcon}
        />
      )
    )}

    <SelectedTriggerAutomation
      t={t}
      onClick={onOpenSelectActionContainer}
      variant={ACTION_TRIGGER_VARIANT.EMPTY_COMPONENT}
      data={{
        name: t("automation.newComponent"),
      }}
      currentAutomation={currentAutomation}
      subText={t("automation.addAction") as string}
      hideWhenThenText
    />
  </Stack>
);

export default AutomationInfo;
