import React, { ReactNode } from "react";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view";
import { Button, Stack, Tooltip } from "@mui/material";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import logo from "../../../assets/logo.png";
import oneLetterLogo from "../../../assets/oneLetterLogo.png";
import {
  StyledComingSoonLabel,
  StyledDrawer,
  StyledDrawerContent,
  StyledDrawerHeader,
  StyledToggleMenuButton,
} from "./styles";
import { TSidebar } from "../../../models/common";

const SidebarComponent = ({
  t,
  expandedMenuItems,
  isCandidate,
  isOpenMenu,
  isLoading,
  menuItems,
  selectedMenuItem,
  desktop,
  mobileDrawerOpen,
  isScrolling,
  onMobileDrawerClose,
  onExpandMenuItem,
  onNavigateMenu,
  onOpenJobEditor,
  onSelectMenuItem,
  onToggleMenu,
}: TSidebar) => (
  <>
    {desktop && (
      <StyledToggleMenuButton open={isOpenMenu} onClick={onToggleMenu}>
        <>
          {isOpenMenu ? (
            <ChevronLeftIcon width={16} height={16} stroke={"#fff"} />
          ) : (
            <ChevronRightIcon width={16} height={16} stroke={"#fff"} />
          )}
        </>
      </StyledToggleMenuButton>
    )}
    <StyledDrawer
      open={isOpenMenu}
      variant="permanent"
      mobileDrawerOpen={mobileDrawerOpen}
    >
      <StyledDrawerHeader>
        <Stack
          direction="row"
          px={desktop ? 0 : 3}
          justifyContent={desktop ? "center" : "space-between"}
          alignItems="flex-end"
          width="100%"
        >
          {isOpenMenu ? (
            <img src={logo} alt="logo" />
          ) : (
            <img src={oneLetterLogo} alt="logo" />
          )}
          {!desktop && (
            <XMarkIcon
              width={24}
              height={24}
              stroke="#fff"
              cursor="pointer"
              onClick={onMobileDrawerClose}
            />
          )}
        </Stack>
      </StyledDrawerHeader>
      {isLoading ? null : (
        <StyledDrawerContent
          open={isOpenMenu}
          isScrolling={isScrolling}
          id="drawer-content"
        >
          <SimpleTreeView
            expandedItems={expandedMenuItems}
            selectedItems={selectedMenuItem}
            onExpandedItemsChange={onExpandMenuItem}
            onItemSelectionToggle={onSelectMenuItem}
            sx={{ width: "100%" }}
          >
            {menuItems.map(
              (menuItem: {
                label: string;
                route: string;
                icon: ReactNode;
                nodeId: string;
                comingSoon: boolean;
                children?: any[];
              }) => (
                <TreeItem
                  key={menuItem.nodeId}
                  itemId={menuItem.nodeId}
                  onClick={() => onNavigateMenu(menuItem.route)}
                  label={
                    isOpenMenu ? (
                      <Stack
                        pl={1}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        {menuItem.label}
                        {menuItem.children &&
                          (expandedMenuItems.includes(menuItem.nodeId) ? (
                            <ChevronUpIcon width={20} height={20} />
                          ) : (
                            <ChevronDownIcon width={20} height={20} />
                          ))}
                        {menuItem.comingSoon && (
                          <StyledComingSoonLabel
                            label={t("comming_soon")}
                            size="small"
                          />
                        )}
                      </Stack>
                    ) : null
                  }
                  slots={{
                    icon: (() => menuItem.icon) as React.ElementType,
                  }}
                >
                  {menuItem.children?.map(
                    (subMenuItem) =>
                      subMenuItem && (
                        <Tooltip
                          key={subMenuItem.nodeId}
                          title={isOpenMenu ? "" : subMenuItem.label}
                        >
                          <TreeItem
                            itemId={subMenuItem.nodeId}
                            onClick={() => onNavigateMenu(subMenuItem.route)}
                            label={
                              isOpenMenu ? (
                                <Stack
                                  pl={1}
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  color={"#6B7280"}
                                >
                                  {subMenuItem.label}
                                </Stack>
                              ) : null
                            }
                            slots={{ icon: () => subMenuItem.icon }}
                          />
                        </Tooltip>
                      )
                  )}
                </TreeItem>
              )
            )}
          </SimpleTreeView>
        </StyledDrawerContent>
      )}
      {!isCandidate && !isLoading && (
        <Stack margin="32px 16px">
          {isOpenMenu ? (
            <Button
              fullWidth
              variant="contained"
              onClick={onOpenJobEditor}
              sx={{ marginTop: "auto" }}
            >
              {t("navigation.create_job_ad")}
            </Button>
          ) : (
            <Tooltip
              title={t("navigation.create_job_ad")}
              PopperProps={{ style: { zIndex: 1299 } }}
            >
              <Button
                variant="contained"
                onClick={onOpenJobEditor}
                sx={{ marginTop: "auto", minWidth: 48 }}
              >
                <b>+</b>
              </Button>
            </Tooltip>
          )}
        </Stack>
      )}
    </StyledDrawer>
  </>
);

export default SidebarComponent;
