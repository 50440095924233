import React from "react";
import { Box } from "@mui/material";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { StyledCVContainer } from "../styles";
import { IDataGridCV } from "../../../models/DataGrid";

const CVCell = ({ cv, onDownloadCV }: IDataGridCV) => {
  const linkAsArray = cv?.link?.split("_");
  const cvName = linkAsArray ? linkAsArray[linkAsArray.length - 1] : undefined;
  return cv && cvName ? (
    <StyledCVContainer>
      <DocumentTextIcon
        className={"cv-icon"}
        onClick={() => onDownloadCV && onDownloadCV(cv.link)}
      />
      <Box
        onClick={() => onDownloadCV && onDownloadCV(cv.link)}
        className={"cv-file-name"}
      >
        {cvName}
      </Box>{" "}
    </StyledCVContainer>
  ) : (
    <Box>-</Box>
  );
};

export default CVCell;
