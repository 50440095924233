import React from "react";
import {
  Typography,
  IconButton,
  Stack,
  Divider,
  InputLabel,
  Button,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../../../components/filters/AutocompleteFilter";
import { IArchiveJobComponent } from "../../../../models/Automation";

const DeleteArchiveJobComponent = ({
  t,
  jobs,
  selectedAction,
  errors,
  control,
  onCloseAction,
  onSubmit,
  handleSubmit,
}: IArchiveJobComponent) => (
  <Stack>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack
          mt="0px !important"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize={16} fontWeight={500}>
            {selectedAction?.name}
          </Typography>
          <Stack height="100%" alignItems="center">
            <IconButton onClick={onCloseAction}>
              <XMarkIcon stroke="#111827" width={20} height={20} />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={0.25}>
          <InputLabel>{t("jobs.navigation.all_jobs")}</InputLabel>
          <Controller
            name={"job_id"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                onChange={onChange}
                value={value}
                options={jobs}
                placeholder="Job"
                errors={errors.job_id}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack
        mt={2}
        direction="row"
        width="100%"
        justifyContent="flex-end"
        spacing={1.5}
      >
        <Button variant="outlined" onClick={onCloseAction}>
          {t("button.secondCancel")}
        </Button>
        <Button variant="contained" type="submit">
          {t("button.save")}
        </Button>
      </Stack>
    </form>
  </Stack>
);

export default DeleteArchiveJobComponent;
