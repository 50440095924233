import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import ActionsCell from "../cellRenderers/actions";
import SkillsCell from "../cellRenderers/skills";
import { TDataGridGetColDef } from "../../../models/DataGrid";

const getTalentPoolCandidateColDef = ({
  t,
  actions,
}: Pick<TDataGridGetColDef, "t" | "actions" | "onSelect">): ColDef[] => [
  {
    field: "name",
    headerName: t("applications.name") as string,
    valueGetter: (params: ValueGetterParams) => {
      const { firstname, lastname } = params.data;
      return `${firstname} ${lastname}`;
    },
    flex: 0.7,
    cellClass: "ag-grid-cell-clickable",
    onCellClicked: (params: CellClickedEvent) => {
      if (actions.onEdit) actions.onEdit(params.data);
    },
  },
  {
    field: "timestamp",
    headerName: t("applications.date_applied") as string,
    flex: 0.5,
    valueGetter: (params: ValueGetterParams) =>
      new Date(params.data["created_at"]).toLocaleDateString(),
  },
  {
    field: "location",
    headerName: t("joblist.location_placeholder") as string,
    flex: 0.5,
    valueGetter: (params: ValueGetterParams) => params.data["location"] || "-",
  },
  {
    field: "skills",
    headerName: t("create_job_third_step.skills") as string,
    flex: 0.8,
    cellRenderer: (params: ICellRendererParams) =>
      SkillsCell({
        skills: params.data.skills,
      }),
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    flex: 0.2,
    cellStyle: { justifyContent: "center" },
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onRemove: () => actions.onRemove && actions.onRemove(params.data),
          onAddCandidateToActiveJob: () =>
            actions.onAddCandidateToActiveJob &&
            actions.onAddCandidateToActiveJob(params.data),
        },
        type: params.data.status,
        forceMultiple: true,
      }),
  },
];

export default getTalentPoolCandidateColDef;
