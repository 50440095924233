import moment from "moment/moment";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import ActionsCell from "../cellRenderers/actions";
import LogoCell from "../cellRenderers/logo";
import StatusCell from "../cellRenderers/status";
import { TDataGridGetColDef } from "../../../models/DataGrid";

const getBasicJobBoardsColDef = ({
  t,
  actions,
}: Pick<TDataGridGetColDef, "t" | "actions">): ColDef[] => [
  {
    field: "logo",
    headerName: t("basic_job_board.title") as string,
    cellRenderer: (params: ICellRendererParams) =>
      LogoCell({ logoSrc: params.value }),
    flex: 0.75,
  },
  {
    field: "created_at",
    headerName: t("basic_job_board.date_published") as string,
    valueFormatter: (params: ValueFormatterParams) =>
      moment(params.value).format("DD.MM.YYYY"),
    flex: 0.75,
  },
  {
    field: "remainingDays",
    headerName: t("basic_job_board.days_remaining") as string,
    flex: 0.75,
  },
  {
    field: "status",
    flex: 0.5,
    cellRenderer: (params: ICellRendererParams) =>
      StatusCell({
        label: params.value,
        type: params.value,
      }),
  },
  {
    field: "",
    flex: 1,
    cellRenderer: (params: ICellRendererParams) => {
      const connectType =
        params.data.title === "Linkedin"
          ? "Linkedin"
          : params.data.title === "Onlyfy"
            ? "Xing"
            : "";
      return ActionsCell({
        t,
        connectType,
        actions: {
          onConnect: params.data.hasConnectAction
            ? () => actions.onConnect && actions.onConnect(params.data)
            : undefined,
          onUpload: params.data.hasUploadAction
            ? (file) => actions.onUpload && actions.onUpload(file)
            : undefined,
        },
      });
    },
  },
];

export default getBasicJobBoardsColDef;
