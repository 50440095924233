import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useFieldArray, useForm } from "react-hook-form";
import JobApplicationProcessComponent from "./component";
import {
  IJobApplicationProcess,
  TJobApplicationProcessFormFields,
} from "../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../models/SingleJob";
import {
  getJobApplicationProcessFormFields,
  jobApplicationProcessFormDefaultValues,
} from "../config";

const JobApplicationProcessForm = ({
  t,
  defaultValues,
  formRef,
  selectedSections = [
    SELECTED_EDIT_SECTIONS.JOB_APPLICATION_PROCESS_BASIC_QUESTIONS,
    SELECTED_EDIT_SECTIONS.JOB_APPLICATION_PROCESS_ADDITIONAL_QUESTIONS,
    SELECTED_EDIT_SECTIONS.JOB_APPLICATION_PROCESS_DOCUMENTS,
  ],
  onSubmit,
  onChangeForm,
}: IJobApplicationProcess) => {
  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(getJobApplicationProcessFormFields(t)),
    defaultValues: defaultValues || jobApplicationProcessFormDefaultValues,
  });

  useEffect(() => {
    if (onChangeForm) {
      const subscription = watch((value: any) => {
        onChangeForm(value);
      });
      return () => subscription.unsubscribe();
    }
  }, [watch]);

  const basicQuestionsTitles = {
    phone_required: t("create_job_third_step.phone_number"),
    location_required: t("joblist.location_placeholder"),
    salary_expectation_required: t("create_job_third_step.salary_expectation"),
    earliest_start_date_required: t(
      "create_job_third_step.earliest_start_date"
    ),
    driver_license_required: t("create_job_third_step.driving_licenses"),
    current_professional_status_required: t(
      "create_job_third_step.current_position"
    ),
    highest_degree_required: t("create_job_third_step.highest_degree"),
    langs_required: t("create_job_third_step.language_skills"),
    skills_required: t("create_job_third_step.skills"),
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  const questionTypes = [
    { label: t("create_job_third_step.yes_no"), value: 1 },
    { label: t("create_job_third_step.free_text"), value: 0 },
  ];

  const handleOnAddQuestion = () => {
    append({ question: "", is_answer_type_yesno: 1 });
  };

  const handleOnRemoveQuestion = (index: number) => {
    remove(index);
  };

  const handleOnSubmit = (data: TJobApplicationProcessFormFields) => {
    onSubmit(data);
  };

  return (
    <JobApplicationProcessComponent
      t={t}
      errors={errors}
      register={register}
      control={control}
      handleSubmit={handleSubmit}
      formRef={formRef}
      selectedSections={selectedSections}
      basicQuestionsTitles={basicQuestionsTitles}
      questions={fields}
      questionTypes={questionTypes}
      onAddQuestion={handleOnAddQuestion}
      onRemoveQuestion={handleOnRemoveQuestion}
      onSubmit={handleOnSubmit}
    />
  );
};

export default JobApplicationProcessForm;
