import React from "react";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import ProductsEmptyState from "./ProductsEmptyState";
import bgImg from "../../../assets/productBgImg.png";
import { IPremiumProductsComponent } from "../../../models/SingleJob";
import {
  StyledDiscoverProductsContainer,
  StyledPremiumPackagesProductsContainer,
  StyledProductContainerHeader,
} from "../styles";

const DiscoverProductsComponent = ({
  t,
  products,
  // totalShopProducts,
  onOpenProduct,
  onResetFilters,
}: IPremiumProductsComponent) => (
  <StyledPremiumPackagesProductsContainer>
    <Typography variant="body1" color={"#131E30"} fontWeight={500}>
      {t("create_job_fourth_step.products")}
    </Typography>
    {products?.length ? (
      <Grid container spacing={2} mt={2}>
        {products?.map((product) => (
          <Grid item xl={4} sm={12} key={product.name}>
            <StyledDiscoverProductsContainer
              onClick={() => onOpenProduct(product)}
            >
              <img alt={"bgImg"} src={bgImg} className={"bgImg"} />
              <StyledProductContainerHeader
                alignItems="center"
                justifyContent="flex-start !important"
                gap={3}
              >
                {product.products[0].image_url ? (
                  <Stack width={115}>
                    <img
                      src={product.products[0].image_url}
                      alt={product.name}
                    />
                  </Stack>
                ) : (
                  <Stack minWidth={115}>
                    <Tooltip title={product.name}>
                      <Typography
                        className="two-line-title"
                        maxWidth={110}
                        fontWeight={500}
                        variant="body2"
                      >
                        {product.name}
                      </Typography>
                    </Tooltip>
                  </Stack>
                )}

                <Stack width="100%" spacing={0.5}>
                  <Stack width="100%">
                    <Tooltip title={product.name}>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="#131E30"
                        maxWidth={175}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {product.name}
                      </Typography>
                    </Tooltip>
                  </Stack>
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="subtitle2" color="#9CA3AF">
                      {t("create_job_fourth_step.starting_from")}
                    </Typography>
                    <Box className={"new-price"} alignSelf="flex-end">
                      {product.products[0].agency_price ||
                        product.products[0].retail_price}
                      €
                    </Box>
                  </Stack>
                </Stack>
              </StyledProductContainerHeader>
            </StyledDiscoverProductsContainer>
          </Grid>
        ))}
      </Grid>
    ) : (
      <ProductsEmptyState t={t} onResetFilters={onResetFilters} />
    )}
  </StyledPremiumPackagesProductsContainer>
);

export default DiscoverProductsComponent;
