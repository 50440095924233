import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "./Header";
import TextFields from "./Textfields";
import RemoveConnectionDialog from "../../components/dialogs/RemoveDialog";
import { DIALOG_TYPE } from "../../models/common";
import { TSelectedCustomer } from "../../models/Agency";
import { getCustomersState } from "../../store/selectors/Customers";
import {
  resetSelectedCustomer,
  fetchCustomer,
  disconnectCustomer,
} from "../../store/reducers/Customers";
import { StyledCustomerDetailContainer } from "./style";

const CustomerDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const [openedDialogType, setOpenedDialogType] = useState<DIALOG_TYPE | null>(
    null
  );

  const { selectedCustomer } = useSelector(getCustomersState);

  const handleOpenDisconnectDialog = () => {
    setOpenedDialogType(DIALOG_TYPE.DISCONNECT);
  };

  const onDisconnectSuccess = () => {
    setOpenedDialogType(null);
    navigate("/customers");
  };

  const handleOnDisconnect = () => {
    dispatch(
      disconnectCustomer({
        id: id as unknown as number,
        callback: () => onDisconnectSuccess(),
      })
    );
  };

  const handleCloseDisconectDialog = () => {
    setOpenedDialogType(null);
  };

  useEffect(() => {
    dispatch(fetchCustomer(id as unknown as number));
    return () => {
      dispatch(resetSelectedCustomer());
    };
  }, []);

  return (
    <StyledCustomerDetailContainer>
      <Header
        t={t}
        selectedCustomer={selectedCustomer as TSelectedCustomer}
        openDisconnectDialog={handleOpenDisconnectDialog}
      />
      <TextFields
        t={t}
        selectedCustomer={selectedCustomer as TSelectedCustomer}
      />
      <RemoveConnectionDialog
        t={t}
        title={t("customers.disconnect_dialog_title")}
        body={t("customers.disconnect_dialog_body")}
        submitButtonText={t("customers.disconnect")}
        isOpen={openedDialogType === DIALOG_TYPE.DISCONNECT}
        onClose={handleCloseDisconectDialog}
        onRemove={handleOnDisconnect}
      />
    </StyledCustomerDetailContainer>
  );
};

export default CustomerDetails;
