import * as yup from "yup";
import { TFunction } from "i18next";
import { TCompanyRegistrationFormFields } from "../../../models/Registration";

const regMatch =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w[a-zA-Z-_%/@?]+)*([^/\w[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export const getFormSettings = (t: TFunction) => {
  return yup.object().shape({
    company: yup.string().required(t("register.message") as string),
    website: yup
      .string()
      .required(t("register.message") as string)
      .matches(regMatch, t("website_valid_url_message") as string),
    industry_id: yup.string().required(t("register.message") as string),
    employees_id: yup.string().required(t("register.message") as string),
  });
};

export const formDefaultValues: TCompanyRegistrationFormFields = {
  company: "",
  website: "",
  industry_id: "",
  employees_id: "",
  email: "",
};
