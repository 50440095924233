import React, { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import StackedBarChart from "../../components/Charts/StackedBarChart";
import Loading from "../../components/Loading";
import IconWithTooltip from "./IconWithTooltip";
import { StyledChartContainer, StyledChartContainerTitle } from "./styles";
import {
  getAllAnalyticsIsLoadingState,
  getAnalyticsAffectedStatuses,
  getAnalyticsJobsWithStats,
} from "../../store/selectors/Analytics";
import { getPerformedName } from "./utils";
import { getWorkflowState } from "../../store/selectors/Workflow";
import { IWorkflow, IWorkflowStatus } from "../../models/Workflow";

const AverageDaysInStage = () => {
  const { t } = useTranslation();

  const [width, setWidth] = useState("");

  const isLoading = useSelector(getAllAnalyticsIsLoadingState);
  const stages = useSelector(getAnalyticsAffectedStatuses);
  const jobs = useSelector(getAnalyticsJobsWithStats);
  const { workflows } = useSelector(getWorkflowState);

  useEffect(() => {
    setTimeout(() => {
      setWidth("100%");
    }, 1000);
  }, [isLoading]);

  const allStatuses = workflows.flatMap((workflow: IWorkflow) =>
    workflow.statuses.map((status: IWorkflowStatus) => ({
      label: status.label,
      value: status.value,
      color: status.color,
    }))
  );

  const colors = stages.map((stage: string) => {
    const currentStage = allStatuses.find((status) => {
      return status.value === stage;
    });
    if (currentStage) {
      return currentStage.color;
    }
    return "#000";
  });

  const { series, categories } = useMemo((): {
    categories: string[];
    series: { name: string; data: number[] }[];
  } => {
    let categories: string[] = [];
    let series: { name: string; data: number[] }[] = [];

    if (jobs.length) {
      categories = jobs.map((job) => job.title);
      series = stages.map((stage) => {
        return {
          name: t(getPerformedName({ name: stage, path: "defaultStatuses" })),
          data: jobs.map((job) => {
            const { value, quantity } = job.sumStat[stage] || {};
            return value ? Math.floor(value / quantity) : 0;
          }),
        };
      });
    }

    return { categories, series };
  }, [jobs]);

  return (
    <StyledChartContainer width={width}>
      <StyledChartContainerTitle>
        <h4>{t("analytics.timeToHire")}</h4>
        <IconWithTooltip title={t("analytics.infoText.timeToHire")} />
      </StyledChartContainerTitle>
      {isLoading ? (
        <Stack maxHeight={350}>
          <Loading />
        </Stack>
      ) : (
        <StackedBarChart
          categories={categories}
          series={series}
          colors={colors}
          stages={stages}
          jobs={jobs}
        />
      )}
    </StyledChartContainer>
  );
};

export default AverageDaysInStage;
