import React from "react";
import { Stack } from "@mui/material";
import TriggerSelection from "./TriggerSelection";
import AddActionContainer from "./AddActionContainer";
import ShowAction from "./ShowAction";
import ApplicationCreatedTrigger from "./triggers/ApplicationCreated";
import ApplicationStatusUpdatedTrigger from "./triggers/ApplicationStatusUpdated";
import {
  ITriggerSelectionComponent,
  TRIGGERS_TYPE,
} from "../../models/Automation";

const RenderSelection = ({
  t,
  currentAutomation,
  triggers,
  activeTriggerGroup,
  triggersGroup,
  isTriggersLoading,
  isAddActionContainer,
  selectedAction,
  onOpenSelectActionContainer,
  onChangeActiveTrigger,
  onSelectTrigger,
}: ITriggerSelectionComponent) => {
  const isOpenOrIsCompletedTrigger =
    currentAutomation?.trigger?.isOpen ||
    !currentAutomation?.trigger?.isCompleted;
  const isCreateApplicationTrigger =
    currentAutomation?.trigger &&
    currentAutomation.trigger.const === TRIGGERS_TYPE.APPLICATION_CREATED;
  const isApplicationStatusUpdatedTrigger =
    currentAutomation?.trigger &&
    currentAutomation.trigger.const ===
      TRIGGERS_TYPE.APPLICATION_STATUS_UPDATED;

  const showCreateApplicationTrigger =
    isOpenOrIsCompletedTrigger && isCreateApplicationTrigger;

  const showApplicationStatusUpdatedTrigger =
    isOpenOrIsCompletedTrigger && isApplicationStatusUpdatedTrigger;

  if (showCreateApplicationTrigger) {
    return <ApplicationCreatedTrigger />;
  }
  if (showApplicationStatusUpdatedTrigger) {
    return <ApplicationStatusUpdatedTrigger />;
  }
  if (selectedAction?.const) {
    return (
      <ShowAction
        t={t}
        activeTriggerGroup={activeTriggerGroup}
        currentAutomation={currentAutomation}
        triggers={triggers}
        triggersGroup={triggersGroup}
        isTriggersLoading={isTriggersLoading}
        selectedAction={selectedAction}
        onChangeActiveTrigger={onChangeActiveTrigger}
        onSelectTrigger={onSelectTrigger}
      />
    );
  }
  if (!currentAutomation?.trigger) {
    return (
      <TriggerSelection
        t={t}
        triggers={triggers}
        currentAutomation={currentAutomation}
        activeTriggerGroup={activeTriggerGroup}
        triggersGroup={triggersGroup}
        isTriggersLoading={isTriggersLoading}
        onChangeActiveTrigger={onChangeActiveTrigger}
        onSelectTrigger={onSelectTrigger}
      />
    );
  }
  if (isAddActionContainer) {
    return (
      <AddActionContainer
        t={t}
        onOpenSelectActionContainer={onOpenSelectActionContainer as () => void}
      />
    );
  }

  return (
    <ShowAction
      t={t}
      activeTriggerGroup={activeTriggerGroup}
      currentAutomation={currentAutomation}
      triggers={triggers}
      triggersGroup={triggersGroup}
      isTriggersLoading={isTriggersLoading}
      selectedAction={selectedAction}
      onChangeActiveTrigger={onChangeActiveTrigger}
      onSelectTrigger={onSelectTrigger}
    />
  );
};

const SelectionContainer = ({
  t,
  triggers,
  currentAutomation,
  activeTriggerGroup,
  triggersGroup,
  isTriggersLoading,
  isAddActionContainer,
  selectedAction,
  onOpenSelectActionContainer,
  onSelectTrigger,
  onChangeActiveTrigger,
}: ITriggerSelectionComponent) => (
  <Stack className="selection-container">
    <RenderSelection
      t={t}
      activeTriggerGroup={activeTriggerGroup}
      currentAutomation={currentAutomation}
      triggers={triggers}
      triggersGroup={triggersGroup}
      isTriggersLoading={isTriggersLoading}
      isAddActionContainer={isAddActionContainer}
      selectedAction={selectedAction}
      onOpenSelectActionContainer={onOpenSelectActionContainer}
      onChangeActiveTrigger={onChangeActiveTrigger}
      onSelectTrigger={onSelectTrigger}
    />
  </Stack>
);

export default SelectionContainer;
