import React, { useState } from "react";
import { Stack, Checkbox, Avatar, Tooltip, Typography } from "@mui/material";
import {
  DocumentTextIcon,
  MapPinIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useDispatch } from "react-redux";
import CandidateItemElement from "./CandidateItemElement";
import CandidateInTalentPoolIcon from "../CandidateInTalentPoolIcon";
import { generateCandidateDate } from "../../utils";
import { StyledCandidateItem, StyledStatusInColumnText } from "./style";
import { ICandidateItemComponent, ICandidateItem } from "../../models/Kanban";
import { daysBeetwen } from "../../utils/singleJob";
import { StyledActionButton } from "../DataGrid/styles";
import { requestApplicationUnlock } from "../../store/reducers/Applications";
import { fetchJobApplications } from "../../store/reducers/SingleJob";
import { TSelectedJobCandidateApplication } from "../../models/ApplicationPage";

const CandidateItem = ({
  t,
  data,
  provided,
  selectedItems,
  onSelectItem,
  onItemClick,
}: ICandidateItemComponent) => {
  const dispatch = useDispatch();

  const [isCheckboxHovered, setIsCheckboxHovered] = useState<boolean>(false);
  const cv = data?.cv?.substring(74);
  const findItem = selectedItems?.find(
    (item: ICandidateItem) => item.id === data.id
  );

  const showInStatusText = data.status === "new" || data.status === "review";
  const numberOfDaysInStatus = daysBeetwen(data.updated_at);
  const statusType =
    numberOfDaysInStatus < 4
      ? "normal"
      : numberOfDaysInStatus < 8
        ? "orange"
        : "red";

  const handleRequestApplicationUnlock = () => {
    if (!data.is_unlock_requested) {
      dispatch(
        requestApplicationUnlock({
          applicationId: data.application_id,
          callback: () =>
            dispatch(
              fetchJobApplications({
                jobId: data.job_id,
                callback: () => {},
              })
            ),
        })
      );
    }
  };

  return (
    <StyledCandidateItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      sx={{
        backgroundColor: findItem
          ? "#EEF2FF"
          : data.is_gdpr_outdated
            ? "#fffbeb"
            : "#fff",
        boxShadow: findItem
          ? `0px 0px 0px 0px rgba(37, 46, 55, 0.06),
                0px 7px 15px 0px rgba(37, 46, 55, 0.06),
                0px 27px 27px 0px rgba(37, 46, 55, 0.05),
                0px 60px 36px 0px rgba(37, 46, 55, 0.03),
                0px 106px 43px 0px rgba(37, 46, 55, 0.01),
                0px 166px 47px 0px rgba(37, 46, 55, 0.00)`
          : "none",
      }}
      onClick={() => {
        if (!isCheckboxHovered) {
          onItemClick(data as unknown as TSelectedJobCandidateApplication);
        }
      }}
    >
      <Stack borderBottom="1px solid #E5E7EB" spacing={1.5} height={57}>
        <Stack direction="row">
          {!data.is_gdpr_outdated && (
            <Checkbox
              checked={findItem ? true : false}
              color="secondary"
              onMouseOver={() => setIsCheckboxHovered(true)}
              onMouseLeave={() => setIsCheckboxHovered(false)}
              onClick={(e) => onSelectItem(e, data)}
            />
          )}
          <Stack
            pl={data.is_gdpr_outdated ? 2 : 0}
            height={51}
            justifyContent={showInStatusText ? "flex-end" : "center"}
          >
            <Typography
              variant="body2"
              color="#131E30"
              fontWeight={500}
              overflow="hidden"
              textOverflow="hidden"
              maxWidth={180}
              noWrap
            >
              {data.name}
            </Typography>
            {showInStatusText && (
              <StyledStatusInColumnText type={statusType}>
                {t("single_job.in_status_for", { numberOfDaysInStatus })}
              </StyledStatusInColumnText>
            )}
          </Stack>
        </Stack>
      </Stack>
      {data.is_gdpr_outdated ? (
        <Stack
          height={116}
          alignItems="center"
          justifyContent="center"
          spacing={0.5}
        >
          <Avatar
            src={undefined}
            sx={{
              width: 30,
              height: 30,
            }}
          />
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography fontSize={12} color="textSecondary">
              {t("applications.information_not_available")}
            </Typography>
            <Tooltip title={t("applications.gdpr")}>
              <ExclamationCircleIcon width={20} height={20} fill="#178CF2" />
            </Tooltip>
          </Stack>
          <StyledActionButton
            disabled={data.is_unlock_requested}
            onClick={handleRequestApplicationUnlock}
            onMouseOver={() => setIsCheckboxHovered(true)}
            onMouseLeave={() => setIsCheckboxHovered(false)}
          >
            {data.is_unlock_requested
              ? t("applications.persmissions_requested")
              : t("applications.request_permission")}
          </StyledActionButton>
        </Stack>
      ) : (
        <Stack direction="row" p={1.5} spacing={2} height={116}>
          <Stack spacing={1} alignItems="center">
            <Avatar
              src={data.photo}
              sx={{
                width: 32,
                height: 32,
              }}
            />
            {data.is_talentpooled == 1 && (
              <CandidateInTalentPoolIcon
                firstname={data.firstname}
                lastname={`${data.lastname} ${t(
                  "talentPools.isInYourTalentPool"
                )}`}
                photo={data.photo}
              />
            )}
          </Stack>
          <Stack spacing={0.25}>
            {!!data?.cv?.length && (
              <CandidateItemElement icon={<DocumentTextIcon />} text={cv} />
            )}
            <CandidateItemElement
              icon={<CalendarIcon />}
              text={generateCandidateDate(data?.date)}
            />
            {!!data?.location?.length && (
              <CandidateItemElement
                icon={<MapPinIcon />}
                text={data.location}
              />
            )}
          </Stack>
        </Stack>
      )}
    </StyledCandidateItem>
  );
};

export default CandidateItem;
