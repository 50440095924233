import React from "react";
import Header from "./Header";
import UserList from "./UserList";
import MessagesList from "./MessagesList";
import RenderDialog from "./RenderDialog";
import MobileHeaderWithDivider from "../../components/MobileHeaderWithDivider";
import NoMessages from "./NoMessages";
import { StyledChatContainer, StyledMessagesContainer } from "./styles";
import { IMessagesComponent } from "../../models/Messages";

const MessagesComponent = ({
  t,
  desktop,
  recruiterId,
  messagesInfo,
  messages,
  latestMessages,
  firstname,
  lastname,
  isCandidate,
  editMessageId,
  deleteMessageId,
  profilePhoto,
  openedDialogType,
  activeMessageId,
  editMessageDefaultValue,
  anchorEl,
  handleCloseUserMenu,
  handleOpenUserMenu,
  handleReadUnread,
  setActiveMessageId,
  setDeleteMessageId,
  openJob,
  onOpenDialog,
  onCloseDialog,
  onSaveDialog,
  handleEdit,
  handleSendMessage,
  handleSetActiveUser,
}: IMessagesComponent) => {
  const showUserList = desktop || !recruiterId;

  return (
    <StyledMessagesContainer
      border={desktop ? "1px solid #E5E7EB" : "none"}
      id="messages-container"
    >
      {desktop ? (
        <Header
          t={t}
          recruiterId={recruiterId}
          messagesInfo={messagesInfo}
          onOpenDialog={onOpenDialog}
          openJob={openJob}
        />
      ) : (
        !recruiterId && <MobileHeaderWithDivider title="Chat" />
      )}
      {latestMessages && (
        <>
          {latestMessages.length > 0 ? (
            <StyledChatContainer height={desktop ? "auto" : "100%"}>
              {showUserList && (
                <UserList
                  t={t}
                  desktop={desktop}
                  recruiterId={recruiterId}
                  latestMessages={latestMessages}
                  anchorEl={anchorEl}
                  handleSetActiveUser={handleSetActiveUser}
                  handleCloseUserMenu={handleCloseUserMenu}
                  handleOpenUserMenu={handleOpenUserMenu}
                  handleReadUnread={handleReadUnread}
                />
              )}
              {recruiterId && (
                <MessagesList
                  t={t}
                  desktop={desktop}
                  recruiterId={recruiterId}
                  messagesInfo={messagesInfo}
                  messages={messages}
                  firstname={firstname}
                  lastname={lastname}
                  isCandidate={isCandidate}
                  editMessageId={editMessageId}
                  deleteMessageId={deleteMessageId}
                  profilePhoto={profilePhoto}
                  activeMessageId={activeMessageId}
                  editMessageDefaultValue={editMessageDefaultValue}
                  handleEdit={handleEdit}
                  onOpenDialog={onOpenDialog}
                  handleSendMessage={handleSendMessage}
                  setDeleteMessageId={setDeleteMessageId}
                  setActiveMessageId={setActiveMessageId}
                />
              )}
            </StyledChatContainer>
          ) : (
            <NoMessages t={t} />
          )}
        </>
      )}
      <RenderDialog
        t={t}
        openedDialogType={openedDialogType}
        onCloseDialog={onCloseDialog}
        onSaveDialog={onSaveDialog}
      />
    </StyledMessagesContainer>
  );
};

export default MessagesComponent;
