import React from "react";
import PhoneInput from "react-phone-input-2";
import { AnimatePresence, motion } from "framer-motion";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../components/filters/AutocompleteFilter";
import TwoValidationTextfields from "../../components/TwoValidationTextfields";
import { IMarketingStepTwoComponent } from "../../models/Marketing";
import { StyledMarketingJobDataContainer } from "./style";

const MarketingStepTwo = ({
  desktop,
  countries,
  errors,
  control,
  register,
}: IMarketingStepTwoComponent) => (
  <AnimatePresence>
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.5 }}
    >
      <StyledMarketingJobDataContainer desktop={desktop}>
        <Stack p={3}>
          <Typography color="#131E30" fontSize={20} fontWeight={500}>
            Wir benötigen noch einige Informationen, um die Buchung
            abzuschließen
          </Typography>
        </Stack>
        <Stack width="100%" spacing={3}>
          <TwoValidationTextfields
            desktop={desktop}
            background="#EEF2FF"
            labels={["Unternehmensname", "Webseite des Unternehmens"]}
            placeholders={["Beispiel GmbH", "unternehmen.de"]}
            registers={["company", "website"]}
            errors={errors}
            register={register}
          />
          <Stack>
            <TwoValidationTextfields
              desktop={desktop}
              background="#EEF2FF"
              labels={["Stadt", "PLZ"]}
              placeholders={["Stadt", "PLZ"]}
              registers={["mainCity", "postcode"]}
              errors={errors}
              register={register}
            />
            <TwoValidationTextfields
              flex={[4, 1]}
              padding="0px 24px"
              desktop={desktop}
              background="#EEF2FF"
              labels={["Address", "Nummer"]}
              placeholders={["Address", "Nummer"]}
              registers={["address", "number"]}
              errors={errors}
              register={register}
            />
            <Stack
              p={3}
              sx={{
                background: "#EEF2FF",
              }}
            >
              <InputLabel>Land</InputLabel>
              <Controller
                name={"mainCountry"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteFilter
                    placeholder="Land"
                    options={countries || []}
                    value={value}
                    onChange={onChange}
                    errors={errors?.mainCountry}
                    hasFlags
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack>
            <TwoValidationTextfields
              desktop={desktop}
              background="#EEF2FF"
              labels={["Vorname", "Nachname"]}
              placeholders={["Vorname", "Nachname"]}
              registers={["firstname", "lastname"]}
              errors={errors}
              register={register}
            />
            <Stack
              direction={desktop ? "row" : "column"}
              p={3}
              pt={0}
              spacing={desktop ? 3 : 2}
              sx={{
                background: "#EEF2FF",
              }}
            >
              <Stack flex={1} spacing={0.5}>
                <InputLabel>E-Mail</InputLabel>
                <TextField
                  {...register("email")}
                  placeholder="example@company.de"
                  error={Boolean(errors.email)}
                  helperText={errors?.email?.message as string}
                  fullWidth
                />
              </Stack>
              <Stack flex={1} spacing={0.5}>
                <InputLabel>Telefon</InputLabel>
                <FormControl>
                  <Controller
                    name={"phone"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        inputClass={
                          errors.phone ? "phone-input error" : "phone-input"
                        }
                        country={"de"}
                        value={value}
                        onChange={onChange}
                        preferredCountries={["de", "at", "ch"]}
                      />
                    )}
                  />
                  <FormHelperText error>
                    {errors.phone?.message as string}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </StyledMarketingJobDataContainer>
    </motion.div>
  </AnimatePresence>
);

export default MarketingStepTwo;
