import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Stack, Typography, Box, Checkbox } from "@mui/material";
import MarketingProduct from "./MarketingProduct";
import stepstoneLogo from "./assets/stepstone.png";
import xingLogo from "./assets/xing.png";
import yourfirmLogo from "./assets/yourfirn.png";
import onlyjobsLogo from "./assets/onlyjobs.png";
import { IMarketingActionComponent } from "../../models/Marketing";
import { StyledMarketingActionContainer } from "./style";
import {
  StyledDiscountLabel,
  StyledPackagePriceBlock,
  StyledSuccessButton,
} from "../SingleJob/styles";
import { StyledLink } from "../../components/Checkout/styles";
import { onRedirectPrivacy, onRedirectTerms } from "../../utils";

const MarketingAction = ({
  desktop,
  isOrderDisabled,
  isAgree,
  step,
  onOpenSecondStep,
  onChangeAgreement,
}: IMarketingActionComponent) => (
  <AnimatePresence>
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.5 }}
    >
      <StyledMarketingActionContainer desktop={desktop}>
        <Stack p={4} spacing={3}>
          <Typography fontSize={24} fontWeight={700}>
            Dein Paket zum{" "}
            <Typography
              display="inline"
              fontSize={24}
              fontWeight={700}
              color="#818CF8"
            >
              Superpreis
            </Typography>
          </Typography>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Typography variant="body2" color="#131E30">
              Spare mit diesem Paket
            </Typography>
            <StyledDiscountLabel padding="0px !important">
              57%
            </StyledDiscountLabel>
          </Stack>
          <MarketingProduct
            logo={stepstoneLogo}
            oldPrice="1.399,00€"
            title="Stepstone Pro"
            days={30}
          />
          <MarketingProduct
            logo={xingLogo}
            oldPrice="699,00€"
            title="Xing Core"
            days={30}
          />
          <MarketingProduct
            logo={yourfirmLogo}
            oldPrice="995,00€"
            title="Yourfirm Programmatic"
            days={60}
          />
          <MarketingProduct
            logo={onlyjobsLogo}
            oldPrice="149,00€"
            title="OnlyJobs Organics"
            days={60}
          />

          <StyledPackagePriceBlock
            borderRadius={1.5}
            sx={{ background: "#EEF2FF" }}
          >
            <Typography variant="subtitle2">Preis:</Typography>
            <Stack spacing={1} direction={"row"}>
              <Box className={"old-price"}>3.242,00€</Box>
              <Box className={"new-price"}>1.399,00€</Box>
            </Stack>
          </StyledPackagePriceBlock>
          {step === 2 && (
            <Stack direction="row">
              <Stack position="relative" bottom={10}>
                <Checkbox
                  checked={isAgree}
                  color="secondary"
                  onChange={onChangeAgreement}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle2" color="textSecondary">
                  Ich stimme den{" "}
                  <StyledLink onClick={() => onRedirectTerms("newTab")}>
                    Allgemeinen Geschäftsbedingungen
                  </StyledLink>{" "}
                  von Karriera zu und bestätige, dass ich die{" "}
                  <StyledLink onClick={() => onRedirectPrivacy("newTab")}>
                    Datenschutzbestimmungen
                  </StyledLink>{" "}
                  gelesen habe.
                </Typography>
              </Stack>
            </Stack>
          )}
          <StyledSuccessButton
            type="submit"
            variant="contained"
            disabled={isOrderDisabled}
            onClick={onOpenSecondStep}
          >
            {step === 2 ? "Jetzt bestellen" : "Weiter zur Bestellung"}
          </StyledSuccessButton>
        </Stack>
      </StyledMarketingActionContainer>
    </motion.div>
  </AnimatePresence>
);

export default MarketingAction;
