import React from "react";
import ReactApexChart from "react-apexcharts";
import { IAreaChart } from "../../models/Chart";

const AreaChart = ({ height = 350, labels = [], series }: IAreaChart) => {
  const options: ApexCharts.ApexOptions = {
    labels: labels,
    chart: { toolbar: { show: false }, zoom: { enabled: false }, type: "area" },
    dataLabels: { enabled: false },
    stroke: { curve: "straight" },
    yaxis: {
      min: 0,
      labels: {
        formatter: (val: number) => val.toFixed(0),
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        colorStops: [
          { color: "#FF6E7F", offset: 0, opacity: 0.5 },
          { color: "#BFE9FF", offset: 100, opacity: 0.5 },
        ],
      },
    },
  };

  return (
    <ReactApexChart
      series={series}
      options={options}
      height={height}
      className="chart"
      type="area"
    />
  );
};

export default AreaChart;
