import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { ClockIcon, ShoppingBagIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import ProductsEmptyState from "./ProductsEmptyState";
import bgImg from "../../../assets/productBgImg.png";
import { StyledPreviewButton } from "../../../components/JobDialogs/styles";
import {
  IPremiumProductsComponent,
  TPremiumProductItem,
} from "../../../models/SingleJob";
import {
  StyledAddBtnContainer,
  StyledDaysBlock,
  StyledDiscountLabel,
  StyledPremiumPackagesProductsContainer,
  StyledProductBodyItem,
  StyledProductContainerHeader,
  StyledProductsContainer,
  StyledShoppingCartButtonContainer,
  StyledVerticalDivider,
} from "../styles";

const PremiumProductsComponent = ({
  t,
  products,
  isPremiumJobBoardsTab,
  selectedProducts,
  isSelected,
  onAddProduct,
  onRemoveFromCart,
  onResetFilters,
  onOpenCart,
}: IPremiumProductsComponent) => (
  <StyledPremiumPackagesProductsContainer>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="body1" color={"#131E30"} fontWeight={500}>
        {t("create_job_fourth_step.products")}
      </Typography>
      {isPremiumJobBoardsTab && (
        <Stack width="100%" alignItems="flex-end">
          <StyledShoppingCartButtonContainer
            isPremiumJobBoardsTab
            onClick={onOpenCart}
          >
            <StyledPreviewButton>
              <ShoppingBagIcon width={24} height={24} />
            </StyledPreviewButton>
            <Stack className={"count-label"}>{selectedProducts.length}</Stack>
          </StyledShoppingCartButtonContainer>
        </Stack>
      )}
    </Stack>
    {products?.length ? (
      <Grid container spacing={2} mt={2}>
        {products?.map((product) => (
          <Grid item xl={6} sm={12} key={product.name}>
            <StyledProductsContainer key={product.name}>
              <StyledProductContainerHeader fullCard>
                {product.products[0].image_url ? (
                  <img src={product.products[0].image_url} alt={product.name} />
                ) : (
                  <Typography
                    variant="subtitle2"
                    height={40}
                    display="flex"
                    alignItems="center"
                  >
                    {product.name}
                  </Typography>
                )}
              </StyledProductContainerHeader>
              <Stack spacing={1} p={2}>
                {product.products?.map((productItem: TPremiumProductItem) => (
                  <StyledProductBodyItem key={productItem.id}>
                    <Stack flex={1}>
                      <Stack direction={"row"} spacing={1}>
                        <Tooltip title={productItem.name}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            maxWidth={300}
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {productItem.name}
                          </Typography>
                        </Tooltip>
                        {productItem.discount && productItem.discount > 0 ? (
                          <StyledDiscountLabel>
                            {productItem.discount} %
                          </StyledDiscountLabel>
                        ) : null}
                      </Stack>
                      <StyledDaysBlock mt={0.5}>
                        <ClockIcon />
                        <span className="product-days">
                          {productItem.runtime_in_days}{" "}
                          {t("create_job_fourth_step.days")}
                        </span>
                      </StyledDaysBlock>
                    </Stack>
                    <Stack direction={"column"} alignItems={"flex-end"} mt={0}>
                      {productItem.agency_price
                        ? productItem.agency_price <
                            productItem.recommended_retail_price && (
                            <Box className={"old-price"}>
                              {productItem.recommended_retail_price} €
                            </Box>
                          )
                        : productItem.retail_price <
                            productItem.recommended_retail_price && (
                            <Box className={"old-price"}>
                              {productItem.recommended_retail_price} €
                            </Box>
                          )}
                      <Box className={"new-price"}>
                        {productItem.agency_price || productItem.retail_price} €
                      </Box>
                    </Stack>
                    <StyledVerticalDivider orientation={"vertical"} />
                    <StyledAddBtnContainer zIndex={1}>
                      {isSelected(productItem) ? (
                        <CheckCircleIcon
                          className={"success"}
                          onClick={() => onRemoveFromCart(productItem)}
                        />
                      ) : (
                        <AddCircleIcon
                          onClick={() => onAddProduct(productItem)}
                        />
                      )}
                    </StyledAddBtnContainer>
                    <img alt={"bgImg"} src={bgImg} className={"bgImg"} />
                  </StyledProductBodyItem>
                ))}
              </Stack>
            </StyledProductsContainer>
          </Grid>
        ))}
      </Grid>
    ) : (
      <ProductsEmptyState t={t} onResetFilters={onResetFilters} />
    )}
  </StyledPremiumPackagesProductsContainer>
);

export default PremiumProductsComponent;
