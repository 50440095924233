import React from "react";
import { Box, Link, Skeleton, Stack } from "@mui/material";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import StatusSelector from "../../DataGrid/cellRenderers/statusSelector";
import CandidateInTalentPoolIcon from "../../CandidateInTalentPoolIcon";
import { APPLICATION_STATUSES } from "../../../models/Applications";
import { ISingleApplicationHeaderComponent } from "../../../models/TopHeader";
import {
  StyledHeaderSubtitle,
  StyledHeaderTitle,
  StyledHeaderTitleContainer,
  StyledMenuButton,
  StyledSmallMobileButton,
  StyledStatusContainer,
} from "../styles";

const SingleApplicationHeaderComponent = ({
  t,
  title,
  subtitle,
  status,
  targetJob,
  isLoading,
  currentApplication,
  desktop,
  onOpenAddCandidateToJobDialog,
  onUpdateStatus,
  onNavigateBack,
}: ISingleApplicationHeaderComponent) => (
  <Stack>
    <StyledHeaderTitleContainer direction={"row"} spacing={2}>
      <StyledMenuButton onClick={onNavigateBack}>
        <ArrowLeftIcon />
      </StyledMenuButton>
      {isLoading ? (
        <Skeleton variant="rounded" width={200} height={30} />
      ) : (
        <StyledHeaderTitle desktop={desktop} alignItems="flex-start !important">
          {title}
        </StyledHeaderTitle>
      )}
      {isLoading ? (
        <Skeleton
          variant="rounded"
          width={70}
          height={20}
          sx={{
            borderRadius: 6,
          }}
        />
      ) : (
        <StyledStatusContainer direction={"row"} spacing={0.5}>
          {status && (
            <StatusSelector
              onSelect={(value: string) => {
                onUpdateStatus(value as APPLICATION_STATUSES);
              }}
              label={status.label}
              type={status.value}
            />
          )}
          {currentApplication?.is_talentpooled == 1 && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <CandidateInTalentPoolIcon
                firstname={currentApplication?.firstname}
                lastname={`${currentApplication?.lastname} ${t(
                  "talentPools.isInYourTalentPool"
                )}`}
                photo={currentApplication?.photo}
              />
              <StyledSmallMobileButton
                onClick={onOpenAddCandidateToJobDialog}
                sx={{ top: 0 }}
                variant="contained"
              >
                {t("talentPools.addToActiveJob")}
              </StyledSmallMobileButton>
            </Stack>
          )}
        </StyledStatusContainer>
      )}
    </StyledHeaderTitleContainer>
    <Stack pl={6.5} pt={0.25}>
      {isLoading ? (
        <Skeleton variant={"rounded"} width={100} height={20} />
      ) : (
        <StyledHeaderSubtitle direction={"row"} spacing={1}>
          <Box>
            {subtitle}{" "}
            {t(
              currentApplication?.application_id ? "candidate.for_the_job" : ""
            )}
          </Box>
          <Link underline={"none"} href={`/jobs/${targetJob.id}/applications`}>
            {targetJob.title}
          </Link>
        </StyledHeaderSubtitle>
      )}
    </Stack>
  </Stack>
);

export default SingleApplicationHeaderComponent;
