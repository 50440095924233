import React from "react";
import { Box, Divider, Grid, Stack } from "@mui/material";
import ExpandableBlock from "../../../../components/ExpandableBlock";
import StyledSectionTitle from "../../../../components/SectionTitle";
import { IJodDescriptionPreviewComponent } from "../../../../models/SingleJob";
import {
  getJobDescriptionFields,
  getJobDescriptionOptionalFields,
} from "./config";
import { StyledSectionContainer } from "./styles";

const JobDescriptionPreview = ({
  t,
  jobTemplate,
  onEdit,
}: IJodDescriptionPreviewComponent) => {
  const isEmptyContactSection =
    jobTemplate?.contact && jobTemplate?.contact === "<p><br></p>";
  return (
    <StyledSectionContainer spacing={2}>
      <StyledSectionTitle
        title={t("singleJob.jobDetails.titles.jobDescription")}
        onClick={onEdit}
      />
      <Divider />
      <Box mt={2} pt={2}>
        <Grid container spacing={2}>
          {getJobDescriptionFields(jobTemplate).map((field) => (
            <Grid item xs={6} key={field.title}>
              <ExpandableBlock t={t} title={field.title} body={field.body} />
            </Grid>
          ))}
        </Grid>
      </Box>
      {!isEmptyContactSection && (
        <Stack spacing={2}>
          {getJobDescriptionOptionalFields(jobTemplate).map(
            (field) =>
              field.body && (
                <ExpandableBlock
                  key={field.title}
                  t={t}
                  title={field.title}
                  body={field.body}
                />
              )
          )}
        </Stack>
      )}
    </StyledSectionContainer>
  );
};

export default JobDescriptionPreview;
