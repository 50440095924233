import React from "react";
import { Stack, Typography } from "@mui/material";
import { ClockIcon } from "@heroicons/react/24/outline";
import { StyledDaysBlock, StyledPackageBodyItem } from "../SingleJob/styles";
import { IMarketingProductComponent } from "../../models/Marketing";

const MarketingProduct = ({
  logo,
  title,
  days,
  oldPrice,
}: IMarketingProductComponent) => (
  <StyledPackageBodyItem spacing={1}>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <img src={logo} className="marketing-image" alt="logo" />
      <Typography className="old-price">{oldPrice}</Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Typography
          variant="subtitle2"
          noWrap
          maxWidth={210}
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {title}
        </Typography>
      </Stack>
      <StyledDaysBlock alignSelf="flex-end">
        <ClockIcon />
        <span>{days} Tage</span>
      </StyledDaysBlock>
    </Stack>
  </StyledPackageBodyItem>
);

export default MarketingProduct;
