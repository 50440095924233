import React, { useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DocumentDuplicateIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { ICompanySettingsTechnicalInformation } from "../../../models/CompanySettings";
import { StyledApiTokenIconContainer, StyledSectionContainer } from "../styles";

const TechnicalInformation = ({
  t,
  register,
  onCopyToken,
}: ICompanySettingsTechnicalInformation) => {
  const [showApiToken, setShowApiToken] = useState(false);
  return (
    <Box>
      <Typography variant={"body2"}>
        {t("company_settings.technical_information")}
      </Typography>
      <StyledSectionContainer spacing={1.5}>
        <Stack direction={"row"} spacing={3} justifyContent={"center"}>
          <Box sx={{ flex: 5 }}>
            <InputLabel>{t("company_settings.api_token")}</InputLabel>
            <TextField
              fullWidth
              {...register("apikey")}
              disabled
              type={showApiToken ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledApiTokenIconContainer width={19.75} height={19.75}>
                      {showApiToken ? (
                        <EyeSlashIcon
                          onClick={() => setShowApiToken(!showApiToken)}
                        />
                      ) : (
                        <EyeIcon
                          onClick={() => setShowApiToken(!showApiToken)}
                        />
                      )}
                    </StyledApiTokenIconContainer>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Stack sx={{ flex: 1 }} justifyContent={"flex-end"}>
            <Button
              onClick={onCopyToken}
              variant={"outlined"}
              startIcon={<DocumentDuplicateIcon width={24} height={24} />}
            >
              {t("job_widget.copy")}
            </Button>
          </Stack>
        </Stack>
      </StyledSectionContainer>
    </Box>
  );
};

export default TechnicalInformation;
