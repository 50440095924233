import React from "react";
import { Stack, Typography } from "@mui/material";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ShowMoreTooltipComponent from "../ShowMoreTooltip";
import { TJobLocations, TLocation } from "../../models/CompanyInfo";

export const RenderDot = ({
  index,
  desktop,
  jobLocations,
  alwaysShow,
  customColor,
}: TJobLocations) => {
  const showDot =
    (index === 0 && jobLocations?.length > 1) ||
    (jobLocations?.length > 2 && desktop);
  if (showDot || alwaysShow) {
    return (
      <Stack
        width={4}
        height={4}
        sx={{ background: customColor ?? "#6B7280" }}
        borderRadius="50%"
      />
    );
  }
  return null;
};

const JobLocations = ({
  desktop,
  jobLocations,
  companyColor,
  spacing = 2,
  containerMaxWidth = "100%",
  itemMaxWidth = 150,
}: {
  readonly desktop: boolean;
  readonly spacing?: number;
  readonly jobLocations: any;
  readonly companyColor?: string;
  readonly containerMaxWidth?: string | number;
  readonly itemMaxWidth?: string | number;
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  /*  const language = localStorage.getItem("lang");
  const checkLanguage = language === "en_US" ? "en" : "de";
  const regionNames = new Intl.DisplayNames([checkLanguage], {
    type: "region",
  }); */

  const formatedJobLocations = jobLocations?.map((item: TLocation) => ({
    firstname: `${item.city},`,
    lastname: item.country,
  }));

  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      maxWidth={containerMaxWidth}
    >
      {jobLocations?.length > 0 ? (
        <>
          <Stack
            direction="row"
            spacing={spacing}
            alignItems="center"
            maxWidth={containerMaxWidth}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <MapPinIcon
                width={20}
                height={20}
                strokeWidth={1}
                stroke={companyColor}
              />
              {jobLocations
                .slice(0, 2)
                .map((location: TLocation, index: number) => (
                  <React.Fragment key={index}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {desktop || index === 0 ? (
                        <Typography
                          variant="subtitle2"
                          display="inline"
                          color="textSecondary"
                          maxWidth={
                            jobLocations?.length > 1
                              ? itemMaxWidth
                              : containerMaxWidth
                          }
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {location.city}, {location.country}
                        </Typography>
                      ) : (
                        <ShowMoreTooltipComponent items={formatedJobLocations}>
                          <Typography
                            variant="subtitle2"
                            color={companyColor}
                            sx={{ cursor: "pointer" }}
                            whiteSpace="nowrap"
                          >
                            +{jobLocations?.length - 1} {t("company_page.more")}
                          </Typography>
                        </ShowMoreTooltipComponent>
                      )}
                      <RenderDot
                        index={index}
                        desktop={desktop}
                        jobLocations={jobLocations}
                      />
                    </Stack>
                  </React.Fragment>
                ))}
            </Stack>
          </Stack>
          {jobLocations?.length > 2 && desktop && (
            <ShowMoreTooltipComponent items={formatedJobLocations}>
              <Typography
                variant="subtitle2"
                color={companyColor}
                sx={{ cursor: "pointer" }}
                whiteSpace="nowrap"
              >
                +{jobLocations?.length - 2} {t("company_page.more")}
              </Typography>
            </ShowMoreTooltipComponent>
          )}
        </>
      ) : (
        <Stack direction="row" gap={1} alignItems="center">
          <MapPinIcon
            width={20}
            height={20}
            strokeWidth={1}
            stroke={companyColor}
          />
          <Typography
            variant="subtitle2"
            display="inline"
            color="textSecondary"
          >
            {id
              ? `${t("update_company_information.city")}, ${t(
                  "update_company_information.country"
                )}`
              : t("company_page.unknown")}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default JobLocations;
