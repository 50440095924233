import React from "react";
import { Stack, CircularProgress } from "@mui/material";
import { TLoading } from "../../models/common";

const Loading = ({
  color = "secondary",
  height = 500,
  size = 50,
}: TLoading) => (
  <Stack
    width="100%"
    height={height || 500}
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress color={color} size={size} />
  </Stack>
);

export default Loading;
