import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ResendPasswordComponent from "./component";
import { resetPassword } from "../../../store/reducers/Auth";
import { getAuthLoginState } from "../../../store/selectors/Auth";

const ResendPassword = ({ desktop }: { readonly desktop: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState(false);
  const { email, isLoading } = useSelector(getAuthLoginState);

  const handleOnReset = () => {
    dispatch(
      resetPassword({
        email,
        callback: onResetSuccess,
      })
    );
  };

  const onResetSuccess = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 60000);
  };

  const handleOnReturn = () => {
    navigate("/");
  };
  return (
    <ResendPasswordComponent
      t={t}
      isDisabled={isDisabled}
      isLoading={isLoading}
      desktop={desktop}
      onReset={handleOnReset}
      onReturn={handleOnReturn}
    />
  );
};

export default ResendPassword;
