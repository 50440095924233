import React, { useEffect, useState } from "react";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import JobDetailsComponent from "./component";
import JobAdditionalQuestionsEditDialog from "./dialogs/JobAdditionalQuestions";
import JobBasicQuestionsEditDialog from "./dialogs/JobBasicQuestions";
import JobDescriptionEditDialog from "./dialogs/JobDescription";
import JobDetailsEditDialog from "./dialogs/JobDetails";
import JobDocumentsEditDialog from "./dialogs/jobDocuments";
import {
  TJobDescriptionFormFieldsPayload,
  TJobDetailsUpdateJobPayload,
} from "../../../models/JobEditor";
import { DIALOG_TYPE, SELECTED_EDIT_SECTIONS } from "../../../models/SingleJob";
import {
  fetchJobData as fetchJobProductData,
  updateJob,
} from "../../../store/reducers/JobEditor";
import {
  fetchJobData,
  fetchJobDetails,
  fetchJobTemplate,
} from "../../../store/reducers/SingleJob";
import { getSingleJobState } from "../../../store/selectors/SingleJob";
import { getCompanySettingsCompanyState } from "../../../store/selectors/Company";
import {
  fetchCompanyData,
  updateCompanyData,
} from "../../../store/reducers/CompanySettings";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
} from "../../../store/selectors/CurrentUser";
import { getJobEditorJobDataState } from "../../../store/selectors/JobEditor";
import { getAllActiveJobs } from "../../../store/selectors/JobList";
import { fetchAllActiveJobs } from "../../../store/reducers/JobList";
import { addMessage } from "../../../store/reducers/Snackbar";

const JobDetails = ({ t }: { t: TFunction }) => {
  const dispatch = useDispatch();
  const { key: id } = useParams();

  const allJobs = useSelector(getAllActiveJobs);
  const { isLoading, jobDetails, jobTemplate, jobLocations, jobQuestions } =
    useSelector(getSingleJobState);
  const { jobTypes } = useSelector(getJobEditorJobDataState);

  const { company } = useSelector(getCompanySettingsCompanyState);
  const { isAgency, firstName } = useSelector(getCurrentUserData);
  const isCandidate = useSelector(getCurrentUserIsCandidate);

  const [openedDialog, setOpenedDialog] = useState<null | DIALOG_TYPE>(null);
  const [selectedDialogSections, setSelectedDialogSections] = useState<
    undefined | SELECTED_EDIT_SECTIONS[]
  >(undefined);

  const handleOnOpenEditDialog = (
    dialogType: DIALOG_TYPE,
    selectedSections?: SELECTED_EDIT_SECTIONS[]
  ) => {
    setOpenedDialog(dialogType);
    setSelectedDialogSections(selectedSections);
  };

  const handleOnCloseEditDialog = () => {
    setOpenedDialog(null);
  };

  const changeCompanyColor = (data: TJobDescriptionFormFieldsPayload) => {
    if (
      data.template_company_color &&
      data.template_company_color !== company?.company_color
    ) {
      dispatch(
        updateCompanyData({
          type: "form",
          data: {
            color: data.template_company_color,
          },
          callback: () => dispatch(fetchCompanyData()),
        })
      );
    }
  };

  const onSuccessUpdateJobDetails = (
    data: TJobDetailsUpdateJobPayload,
    jobUrlKey: string
  ) => {
    dispatch(fetchJobDetails({ jobUrlKey }));
    handleOnCloseEditDialog();
    changeCompanyColor(data as TJobDescriptionFormFieldsPayload);
    dispatch(
      addMessage({
        type: "success",
        title: t("changesSavedSuccessfully"),
      })
    );
  };

  const handleOnUpdateJob = (data: TJobDetailsUpdateJobPayload) => {
    if (jobDetails && !isCandidate) {
      dispatch(
        updateJob({
          apiPayload: {
            ...data,
            title: data.title || jobDetails.title,
          },
          url_key: jobDetails.url_key,
          callback: (jobUrlKey: string) =>
            onSuccessUpdateJobDetails(data, jobUrlKey),
        })
      );
    }
  };

  useEffect(() => {
    if (!jobDetails && !isLoading) {
      if (allJobs) {
        const findJob = allJobs.find((job) => job.id == id);
        dispatch(
          fetchJobDetails({
            jobUrlKey: findJob?.url_key as string,
          })
        );
      } else {
        dispatch(fetchAllActiveJobs());
      }
    }
  }, []);

  useEffect(() => {
    if (id && isAgency) {
      dispatch(
        fetchJobData({
          jobId: id,
          callback: () => {},
        })
      );
    }
  }, [firstName]);

  useEffect(() => {
    if (jobDetails) {
      dispatch(fetchJobTemplate({ templateId: jobDetails.template_id }));
    }
  }, [jobDetails]);

  useEffect(() => {
    dispatch(
      fetchJobProductData({
        callback: () => {},
      })
    );
  }, []);

  return (
    <>
      <JobDetailsComponent
        t={t}
        jobTemplate={jobTemplate}
        jobDetails={jobDetails}
        jobLocations={jobLocations}
        jobQuestions={jobQuestions}
        jobTypes={jobTypes}
        isLoading={isLoading || !jobDetails}
        companyColor={company?.company_color as string}
        onEdit={handleOnOpenEditDialog}
      />
      <JobDescriptionEditDialog
        t={t}
        isOpen={openedDialog === DIALOG_TYPE.JOB_DESCRIPTION}
        onClose={handleOnCloseEditDialog}
        onUpdateJob={handleOnUpdateJob}
        selectedSections={selectedDialogSections}
      />
      <JobDetailsEditDialog
        t={t}
        onClose={handleOnCloseEditDialog}
        isOpen={openedDialog === DIALOG_TYPE.JOB_DETAILS}
        onUpdateJob={handleOnUpdateJob}
      />
      <JobBasicQuestionsEditDialog
        t={t}
        isOpen={openedDialog === DIALOG_TYPE.JOB_BASIC_QUESTIONS}
        onClose={handleOnCloseEditDialog}
        onUpdateJob={handleOnUpdateJob}
      />
      <JobDocumentsEditDialog
        t={t}
        isOpen={openedDialog === DIALOG_TYPE.JOB_DOCUMENTS}
        onClose={handleOnCloseEditDialog}
        onUpdateJob={handleOnUpdateJob}
      />
      <JobAdditionalQuestionsEditDialog
        t={t}
        isOpen={openedDialog === DIALOG_TYPE.JOB_ADDITIONAL_QUESTION}
        onClose={handleOnCloseEditDialog}
        onUpdateJob={handleOnUpdateJob}
      />
    </>
  );
};

export default JobDetails;
