import React, { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTopHeaderState } from "../../../store/selectors/TopHeader";
import {
  setTopHeaderSubtitle,
  setTopHeaderTitle,
} from "../../../store/reducers/TopHeader";
import { getSingleJobState } from "../../../store/selectors/SingleJob";
import SingleJobHeaderComponent from "./component";
import {
  setArchiveRestoreDialogOpen,
  setDuplicateJobUrlKey,
  setIsDuplicateDialogOpen,
  setIsInviteMemberDialogOpen,
  setIsSelectWorkflowDialogOpen,
} from "../../../store/reducers/dialogs";
import {
  fetchJobApplications,
  fetchJobData,
  fetchJobDetails,
  setSelectedJobTab,
} from "../../../store/reducers/SingleJob";
import { JOB_TABS_KEY, TJobDataToExport } from "../../../models/SingleJob";
import { getApplicationListState } from "../../../store/selectors/Applications";
import { getJobDataToExport } from "../utils";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
} from "../../../store/selectors/CurrentUser";
import { getWorkflowState } from "../../../store/selectors/Workflow";
import {
  setCurrentWorkflowStatuses,
  setSelectedJob,
  setSelectedWorkflow,
} from "../../../store/reducers/Workflow";

const SingleJobHeader = ({ desktop }: { readonly desktop: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { key: id } = useParams();

  const CSVLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const { t } = useTranslation();
  const { title, subtitle } = useSelector(getTopHeaderState);
  const { jobDetails, jobLocations, jobOwners, isLoading } =
    useSelector(getSingleJobState);
  const { list: applications } = useSelector(getApplicationListState);
  const { memberId, isAgency, firstName } = useSelector(getCurrentUserData);
  const { workflows, isWorkflowsListLoaded } = useSelector(getWorkflowState);
  const isCandidate = useSelector(getCurrentUserIsCandidate);

  const [csvData, setCsvData] = useState<TJobDataToExport | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isJobOwner = jobOwners?.find((member) => member.id === memberId);

  useEffect(() => {
    if (jobDetails?.title && jobDetails?.created_at) {
      dispatch(setTopHeaderTitle(jobDetails.title));
      dispatch(
        setTopHeaderSubtitle(
          `${t("single_job.date_created")} ${moment(
            jobDetails.created_at
          ).format("DD.MM.YYYY")}`
        )
      );
    }
    handleOnCloseMenu();
  }, [title, subtitle, jobDetails]);

  const isArchivedJob = useMemo(() => !!jobDetails?.is_archived, [jobDetails]);

  const handleOnOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOnCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOnEdit = () => {
    dispatch(setSelectedJobTab(JOB_TABS_KEY.JOB_DETAILS));
  };

  const handleOnPreview = () => {
    if (jobDetails) {
      window.open(`/job/${jobDetails.url_key}`, "_blank");
    }
  };

  const handleOnArchive = () => {
    dispatch(setArchiveRestoreDialogOpen(true));
  };

  const handleOnInvite = () => {
    dispatch(setIsInviteMemberDialogOpen(true));
  };
  const handleOnExport = () => {
    setCsvData(
      getJobDataToExport({
        jobDetails,
        jobLocations,
        applications,
        t,
      })
    );
    setTimeout(() => {
      if (CSVLinkRef.current) CSVLinkRef.current.link.click();
    });
  };

  const handleOnDuplicate = () => {
    dispatch(setIsDuplicateDialogOpen(true));
    dispatch(setDuplicateJobUrlKey(jobDetails?.url_key as string));
  };

  const handleOnNavigateBack = () => {
    navigate("/jobs/active");
  };

  const onFetchApplicationsSuccess = (jobUrlKey: string) => {
    if (!jobDetails && isCandidate === false) {
      dispatch(
        fetchJobDetails({
          jobUrlKey,
        })
      );
    }
  };

  const handleOpenSetWorkflowDialog = () => {
    if (workflows.length > 0) {
      if (jobDetails) {
        const findWorkflow = workflows.find(
          (workflow) => workflow.id === jobDetails.workflow_id
        );

        const id = findWorkflow ? findWorkflow?.id : workflows[0]?.id;

        if (findWorkflow) {
          dispatch(setCurrentWorkflowStatuses(findWorkflow.statuses));
        }
        dispatch(
          setSelectedJob({
            id: jobDetails.id as string,
            title: jobDetails.title,
            urlKey: jobDetails.url_key,
            workflowId: jobDetails.workflow_id,
          })
        );
        dispatch(
          setSelectedWorkflow({
            label: findWorkflow?.title || workflows[0].title,
            value: id as unknown as string,
            jobId: jobDetails.id,
          })
        );
      }
      dispatch(setIsSelectWorkflowDialogOpen(true));
    } else {
      navigate("/workflow");
    }
  };

  useEffect(() => {
    if (!jobDetails && firstName && isWorkflowsListLoaded) {
      if (id) {
        if (isAgency) {
          dispatch(
            fetchJobData({
              jobId: id,
              callback: onFetchApplicationsSuccess,
            })
          );
        } else {
          dispatch(
            fetchJobApplications({
              jobId: id,
              callback: onFetchApplicationsSuccess,
            })
          );
        }
      }
    }
  }, []);

  return (
    <>
      <CSVLink
        data={csvData ? [csvData] : []}
        className="hidden"
        ref={CSVLinkRef}
        filename={jobDetails?.title || "Job"}
      />
      <SingleJobHeaderComponent
        t={t}
        title={title}
        subtitle={subtitle}
        anchorEl={anchorEl}
        jobOwners={jobOwners}
        isLoading={isLoading && !title && !subtitle}
        isArchivedJob={isArchivedJob}
        urlKey={jobDetails?.url_key}
        desktop={desktop}
        isJobOwner={Boolean(isJobOwner)}
        isAgency={isAgency}
        onOpenMenu={handleOnOpenMenu}
        onCloseMenu={handleOnCloseMenu}
        onEdit={handleOnEdit}
        onArchive={handleOnArchive}
        onPreview={handleOnPreview}
        onInvite={handleOnInvite}
        onExport={handleOnExport}
        onDuplicate={handleOnDuplicate}
        onNavigateBack={handleOnNavigateBack}
        onOpenSetWorkflowDialog={handleOpenSetWorkflowDialog}
      />
    </>
  );
};

export default SingleJobHeader;
