import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useState,
  useMemo,
} from "react";
import { Box, Checkbox, Stack, TextField } from "@mui/material";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "debounce";
import CVSection from "./CVSection";
import { StyledAgreementContainer } from "../styles";
import { getJobPreviewState } from "../../../store/selectors/JobPreview";
import {
  applyToJob,
  checkCandidateExist,
} from "../../../store/reducers/JobPreview";

import { TJobActionRegisterFormFields } from "../../../models/JobPreview";
import {
  jobActionAuthFormFields,
  jobActionRegisterFormDefaultValues,
} from "./config";
import { StyledLoadingApplyButton } from "../styles";

const RegisterForm = ({
  companyColor,
  setExistingEmail,
}: {
  readonly companyColor: string;
  readonly setExistingEmail: (email: string) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { key: jobUrlKey } = useParams();
  const [searchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source") || "";
  const { t } = useTranslation();
  const { isApplyInProgress, applyError, jobTemplate, jobData } =
    useSelector(getJobPreviewState);

  const {
    register,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(jobActionAuthFormFields(t)),
    defaultValues: jobActionRegisterFormDefaultValues,
  });
  const [uploadedCV, setUploadedCV] = useState<FileList | null>(null);

  const showCompanyAgreementText =
    jobData && jobData?.pp_link?.length && jobData?.tc_link?.length;

  const handleOnUploadCV = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) setUploadedCV(event.target.files);
  };

  const handleOnResetCV = (event: MouseEvent) => {
    setUploadedCV(null);
    event.preventDefault();
  };

  useEffect(() => {
    if (applyError) {
      setError("email", {
        message: t("application_email_exist") as string,
        type: "manual",
      });
    }
  }, [applyError]);

  const onSubmit = (formFields: TJobActionRegisterFormFields) => {
    const body = {
      ...formFields,
      ...(utm_source && { utm_source }),
      files: uploadedCV,
    };
    if (jobUrlKey)
      dispatch(
        applyToJob({
          jobUrlKey,
          body,
          callback: ({ candidateUrlKey }: { candidateUrlKey: string }) => {
            sessionStorage.setItem("jobUrlKey", jobUrlKey);
            sessionStorage.setItem("candidateUrlKey", candidateUrlKey);
            navigate("/successful-application");
          },
        })
      );
  };

  const checkEmail = (e: any) => {
    const { value } = e.target;
    setExistingEmail(value);
    setValue("email", value);
    if (value) {
      dispatch(
        checkCandidateExist({
          email: value,
          callback: () => {},
        })
      );
    }
  };

  const searchDelayed = useMemo(() => debounce(checkEmail, 500), [checkEmail]);

  return (
    <Stack mt={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Controller
            name={"email"}
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                data-testid={"apply-job-new-customer-email-input"}
                onChange={(e) => {
                  searchDelayed(e);
                  onChange(e);
                }}
                placeholder="name@example.de"
                fullWidth
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            )}
          />
        </Box>
        <Box mt={1.5}>
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            spacing={1.5}
          >
            <Stack flex={1}>
              <TextField
                data-testid={"apply-job-new-customer-first-name-input"}
                placeholder={t("userSettings.firstname") as string}
                fullWidth
                {...register("firstname")}
                error={Boolean(errors["firstname"])}
                helperText={errors["firstname"]?.message}
              />
            </Stack>
            <Stack flex={1}>
              <TextField
                data-testid={"apply-job-new-customer-last-name-input"}
                placeholder={t("userSettings.lastname") as string}
                fullWidth
                {...register("lastname")}
                error={Boolean(errors["lastname"])}
                helperText={errors["lastname"]?.message}
              />
            </Stack>
          </Stack>
        </Box>
        <CVSection
          t={t}
          uploadedCV={uploadedCV?.[0]}
          onUploadCV={handleOnUploadCV}
          onResetCV={handleOnResetCV}
        />
        <StyledAgreementContainer
          spacing={1}
          direction="row"
          py={2}
          companyColor={companyColor}
        >
          <Controller
            name={"agreement"}
            control={control}
            render={({ field: props }) => (
              <Checkbox
                {...props}
                className={"checkbox"}
                data-testid={"apply-job-new-customer-agreement-checkbox"}
              />
            )}
          />
          <Stack spacing={2} position="relative" top={10} pb={1.25}>
            <span className={"agreement-text"}>
              {t("apply_page.terms_first_text")}{" "}
              <span
                className="link-text"
                onClick={() =>
                  window.open(
                    "https://www.karriera.de/agb-candidates",
                    "_blank"
                  )
                }
              >
                {t("apply_page.terms_second_text")}
              </span>{" "}
              {t("apply_page.terms_third_text")}{" "}
              <span
                className="link-text"
                onClick={() =>
                  window.open("https://www.karriera.de/datenschutz", "_blank")
                }
              >
                {t("apply_page.terms_forth_text")}
              </span>{" "}
              {t("apply_page.terms_fifth_text")}{" "}
              {errors.agreement && (
                <span className="error">
                  <br />
                  {errors.agreement.message}
                </span>
              )}
            </span>
            {!!showCompanyAgreementText && (
              <span className={"agreement-text"}>
                {t("apply_page.company_terms_first_text")}{" "}
                <span
                  className="link-text"
                  onClick={() => window.open(jobData?.tc_link, "_blank")}
                >
                  {t("apply_page.company_terms_second_text")}
                </span>{" "}
                {t("apply_page.company_terms_third_text")}{" "}
                <span
                  className="link-text"
                  onClick={() => window.open(jobData?.pp_link, "_blank")}
                >
                  {t("apply_page.company_terms_forth_text")}
                </span>{" "}
                {t("apply_page.company_terms_fifth_text")}{" "}
                {jobTemplate?.company}
                {t("apply_page.company_terms_sixth_text")}
                {errors.agreement && (
                  <span className="error">
                    <br />
                    {errors.agreement.message}
                  </span>
                )}
              </span>
            )}
          </Stack>
        </StyledAgreementContainer>

        <Stack>
          <StyledLoadingApplyButton
            data-testid={"apply-job-new-customer-submit-button"}
            type={"submit"}
            variant={"contained"}
            loading={isApplyInProgress}
            companyColor={companyColor}
            loadingPosition="end"
          >
            {t("apply_page.apply_now")}
          </StyledLoadingApplyButton>
        </Stack>
      </form>
    </Stack>
  );
};

export default RegisterForm;
