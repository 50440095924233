import React from "react";
import { Stack } from "@mui/material";
import Loading from "../../components/Loading";
import EmptyData from "../../components/EmptyData";
import TalentPoolToolbar from "./TalentPoolToolbar";
import DataGrid from "../../components/DataGrid";
import { DATA_GRID_LIST_TYPE } from "../../models/DataGrid";
import { ITalentPoolsComponent, ITalentPool } from "../../models/TalentPools";

const TalentPoolsComponent = ({
  t,
  isListLoading,
  talentPools,
  quickSearch,
  onChangeFilters,
  onSelectTalentPool,
  onOpenCreateTalentPoolDialog,
  onOpenDeleteTalentPoolDialog,
}: ITalentPoolsComponent) => (
  <Stack mt={3} minHeight="80vh">
    <TalentPoolToolbar
      t={t}
      talentPools={talentPools}
      quickSearch={quickSearch}
      onChangeFilters={onChangeFilters}
      onOpenCreateTalentPoolDialog={onOpenCreateTalentPoolDialog}
    />
    {isListLoading ? (
      <Loading />
    ) : talentPools?.length > 0 ? (
      <Stack mt={3} height="100%">
        <DataGrid
          t={t}
          list={talentPools}
          type={DATA_GRID_LIST_TYPE.TALENT_POOLS}
          actions={{
            onClick: (data) => onSelectTalentPool(data as ITalentPool),
            onEdit: (data) => onOpenCreateTalentPoolDialog(data as ITalentPool),
            onRemove: onOpenDeleteTalentPoolDialog,
          }}
        />
      </Stack>
    ) : (
      <EmptyData
        title={t("talentPools.noTalentPoolsTitle")}
        subtitle={t("talentPools.noTalentPoolsSubtitle") as string}
        buttonText={t("talentPools.createTalentPool") as string}
        onButtonClick={() => onOpenCreateTalentPoolDialog(null)}
      />
    )}
  </Stack>
);

export default TalentPoolsComponent;
