import React, { useEffect, useMemo } from "react";
import { Stack } from "@mui/material";
import { SnackbarCloseReason } from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import SnackbarComponent from "./component";
import { getDialogsState } from "../../store/selectors/Dialogs";
import {
  getCurrentUserLoadingState,
  getCurrentUserUpdateLoadingState,
} from "../../store/selectors/CurrentUser";
import { INotification } from "../../models/Snackbar";
import { getSnackbarState } from "../../store/selectors/Snackbar";
import {
  deleteMessage,
  setNotifications,
  turnOffNotifications,
} from "../../store/reducers/Snackbar";

const SnackbarNotification = ({ desktop }: { readonly desktop: boolean }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getCurrentUserLoadingState);
  const isUpdateUserLoading = useSelector(getCurrentUserUpdateLoadingState);
  const { isPasswordNotificationShowed } = useSelector(getDialogsState);
  const { notifications } = useSelector(getSnackbarState);

  const isOpen = useMemo(
    () => !isLoading && !isUpdateUserLoading,
    [open, isLoading, isUpdateUserLoading]
  );

  const closeNotification = (uuid: string) => {
    dispatch(deleteMessage(uuid));
  };

  const handleTurnOffNotifications = () => {
    dispatch(turnOffNotifications());
  };

  const handleCloseSnackbar = (
    id: string,
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") return;
    setTimeout(() => closeNotification(id), 200);
  };

  useEffect(
    () => () => {
      if (isPasswordNotificationShowed) {
        return;
      }
      if (notifications.length > 1 && isPasswordNotificationShowed) {
        dispatch(setNotifications());
      }
    },
    [notifications, isPasswordNotificationShowed]
  );

  return (
    <Stack>
      {notifications.map((notif: INotification, index: number) => (
        <SnackbarComponent
          key={index}
          isOpen={isOpen}
          desktop={desktop}
          notification={notif}
          onTurnOffNotifications={handleTurnOffNotifications}
          onCloseSnackbar={handleCloseSnackbar}
        />
      ))}
    </Stack>
  );
};

export default SnackbarNotification;
