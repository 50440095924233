import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedinIcon from "@mui/icons-material/LinkedIn";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Stack, Typography } from "@mui/material";
import { LinkIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getJobPreviewState } from "../../../store/selectors/JobPreview";
import { StyledIconBox } from "./style";
import { addMessage } from "../../../store/reducers/Snackbar";

const ShareJob = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { jobTemplate } = useSelector(getJobPreviewState);
  const recipient = "";
  const subject = `${jobTemplate?.company} - ${jobTemplate?.title}`;
  const text = `${jobTemplate?.company} - ${jobTemplate?.title} - ${window.location.href}`;
  const encodedText = encodeURIComponent(text);

  const shareJob = (type: string) => {
    let url;
    switch (type) {
      case "copy":
        navigator.clipboard.writeText(window.location.href);
        dispatch(
          addMessage({
            type: "success",
            title: t("company_settings.copy_message"),
          })
        );
        break;
      case "whatsapp":
        url = "https://web.whatsapp.com/send?text=" + encodedText;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${jobTemplate?.company}&summary=${jobTemplate?.title}`;
        break;
      case "mail":
        window.location.href = `mailto:${recipient}?subject=${subject}&body=${text}`;
        break;
    }
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <Stack spacing={1} mt={3} pt={3} borderTop="1px solid #E5E7EB">
      <Typography variant="body2" fontWeight={500}>
        {t("apply_page.share_job")}
      </Typography>
      <Stack direction="row" spacing={1}>
        <StyledIconBox onClick={() => shareJob("copy")}>
          <LinkIcon style={{ stroke: "#000", strokeWidth: 2 }} />
        </StyledIconBox>
        <StyledIconBox onClick={() => shareJob("whatsapp")}>
          <WhatsAppIcon />
        </StyledIconBox>
        <StyledIconBox onClick={() => shareJob("linkedin")}>
          <LinkedinIcon />
        </StyledIconBox>
        <StyledIconBox onClick={() => shareJob("mail")}>
          <EmailOutlinedIcon />
        </StyledIconBox>
      </Stack>
    </Stack>
  );
};

export default ShareJob;
