import React from "react";
import { Stack, Tab, Tabs } from "@mui/material";
import TeamSettingsToolbar from "./TeamSettingsToolbar";
import DataGrid from "../../components/DataGrid";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import InviteDialog from "../../components/dialogs/InviteDialog";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import TeamSettingsNoResultOverlay from "./NoResultOverlay";
import { DIALOG_TYPE } from "../../models/common";
import { DATA_GRID_LIST_TYPE, TDataGridActions } from "../../models/DataGrid";
import { ITeamsComponent, TEAM_USER_STATUSES } from "../../models/Team";
import { StyledTeamsContainer, StyledTeamsTabsContainer } from "./styles";

const TeamsSettingsComponent = ({
  t,
  openedDialogType,
  selectedUserStatus,
  userStatuses,
  userList,
  isLoading,
  isListLoading,
  pageNum,
  pageSize,
  userCount,
  quickSearch,
  emailsToInvite,
  actions,
  onPaginate,
  onChangeFilters,
  onCloseDialog,
  onEmailsChange,
  onDelete,
  onOpenDialog,
  onInvite,
  onTabChange,
}: ITeamsComponent) => (
  <StyledTeamsContainer data-testid={"teams-settings-wrapper"}>
    <StyledTeamsTabsContainer>
      <Tabs value={selectedUserStatus} onChange={onTabChange}>
        {userStatuses.map((tab: TEAM_USER_STATUSES, index: number) => (
          <Tab key={tab + index} label={tab} value={tab} />
        ))}
      </Tabs>
    </StyledTeamsTabsContainer>
    <TeamSettingsToolbar
      t={t}
      selectedUserStatus={selectedUserStatus}
      quickSearch={quickSearch}
      onChangeFilters={onChangeFilters}
      onOpenDialog={onOpenDialog}
    />
    {isListLoading ? (
      <Loading />
    ) : userList?.length ? (
      <Stack height={"100%"} data-testid={"teams-settings-data-grid-wrapper"}>
        <DataGrid
          t={t}
          list={userList}
          type={DATA_GRID_LIST_TYPE.USERS}
          actions={actions as TDataGridActions}
        />
        <Pagination
          totalPosts={userCount}
          itemsPerPage={pageSize}
          page={pageNum}
          paginate={onPaginate}
        />
      </Stack>
    ) : (
      <TeamSettingsNoResultOverlay t={t} onOpenDialog={onOpenDialog} />
    )}

    <DeleteDialog
      isLoading={isLoading}
      title={t("team_members.delete_title")}
      body={t("team_members.delete_text")}
      onCancel={onCloseDialog}
      onDelete={onDelete}
      isOpen={openedDialogType === DIALOG_TYPE.DELETE}
      t={t}
    />
    <InviteDialog
      t={t}
      title={t("team_members.invite_title")}
      isLoading={isLoading}
      isOpen={openedDialogType === DIALOG_TYPE.INVITE}
      emailsToInvite={emailsToInvite}
      onCancel={onCloseDialog}
      onEmailsChange={onEmailsChange}
      onInvite={onInvite}
    />
  </StyledTeamsContainer>
);

export default TeamsSettingsComponent;
