import React, { useEffect, useMemo, useState } from "react";
import RequestDataComponent from "./component";
import { useTranslation } from "react-i18next";
import { getDialogsState } from "../../../store/selectors/Dialogs";
import { useDispatch, useSelector } from "react-redux";
import { setIsRequestDataDialogOpen } from "../../../store/reducers/dialogs";
import { getRequestedDataList } from "../../../store/selectors/Applications";
import { setRequestedDataList } from "../../../store/reducers/Applications";

const RequestDataDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isOpen = useSelector(getDialogsState).isRequestDataDialogOpen;
  const initialLstToRequest = useSelector(getRequestedDataList);

  const [listToRequest, setListToRequest] = useState<
    { title: string; id: string; checked: boolean }[]
  >([]);

  useEffect(() => {
    setListToRequest(
      initialLstToRequest.map((field) => ({ ...field, checked: true }))
    );
  }, [initialLstToRequest]);

  const handleOnClose = () => {
    dispatch(setIsRequestDataDialogOpen(false));
    dispatch(setRequestedDataList([]));
  };

  const handleOnsubmit = () => {
    // TODO add logic
    const filteredList = listToRequest.reduce(
      (acc: string[], { checked, id }) => (checked ? [...acc, id] : acc),
      []
    );
    console.dir(filteredList);
    handleOnClose();
  };

  const handleOnChange = (fieldId: string) => {
    const updatedList = listToRequest.map((field) =>
      field.id === fieldId ? { ...field, checked: !field.checked } : field
    );
    setListToRequest(updatedList);
  };

  const title = useMemo(
    () =>
      listToRequest.some((req) => req.id === "cv")
        ? "" //t("request_documents") later
        : "", //t("request_data") later
    [listToRequest]
  );

  const isDisabled = useMemo(
    () => !listToRequest.some(({ checked }) => checked),
    [listToRequest]
  );

  return (
    <RequestDataComponent
      t={t}
      isOpen={isOpen}
      title={title}
      listToRequest={listToRequest}
      isDisabled={isDisabled}
      onClose={handleOnClose}
      onChange={handleOnChange}
      onSubmit={handleOnsubmit}
    />
  );
};

export default RequestDataDialog;
