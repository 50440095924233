import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Link,
  InputLabel,
  Stack,
  CircularProgress,
} from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { logIn } from "../../../store/reducers/Auth";
import { getFormSettings } from "../../Login/config";
import { sendMagicLink } from "../../../store/reducers/JobPreview";
import { getJobPreviewState } from "../../../store/selectors/JobPreview";
import { getAuthLoginState } from "../../../store/selectors/Auth";
import { StyledAddLanguageButton } from "../../AdditionalQuestions/styles";
import { StyledLoadingApplyButton } from "../styles";
import { ICandidateExistLogin } from "../../../models/JobPreview";

const CandidateExistLogin = ({
  t,
  existingEmail,
  urlKey,
  companyColor,
}: ICandidateExistLogin) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { error } = useSelector(getAuthLoginState);
  const { isMagicLinkLoading } = useSelector(getJobPreviewState);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(getFormSettings(t)),
  });

  useEffect(() => {
    if (error) {
      setError("email", {
        message: t("password") as string,
        type: "manual",
      });
      setError("password", {
        message: t("password_match_message_try_again") as string,
        type: "manual",
      });
    }
  }, [error]);

  useEffect(() => {
    setValue("email", existingEmail);
  }, []);

  const handleSendMagicLink = () => {
    dispatch(
      sendMagicLink({
        urlKey,
        email: existingEmail,
        callback: () => {
          navigate("/magic-link-confirmation");
        },
      })
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordReset = () => {
    navigate("/forgot-password");
  };

  const onSubmit = (data: any) => {
    dispatch(logIn({ username: data.email, password: data.password }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={1} mb={1} data-testid={"existing-candidate-container"}>
        <InputLabel>{t("candidate.email")}</InputLabel>
        <TextField
          {...register("email")}
          defaultValue={existingEmail}
          value={existingEmail}
          variant="outlined"
          size="small"
          error={Boolean(errors.email)}
          placeholder="name@example.de"
          disabled
          fullWidth
        />
        <Typography
          variant="subtitle2"
          color="error"
          data-testid={"apply-job-form-error-message"}
        >
          {t("apply_page.error_message")}
        </Typography>
      </Box>
      <Box mt={2} mb={2}>
        <InputLabel>{t("apply_page.password")}</InputLabel>
        <TextField
          data-testid={"password-input"}
          {...register("password")}
          variant="outlined"
          size="small"
          type={showPassword ? "text" : "password"}
          fullWidth
          error={Boolean(errors.password)}
          placeholder={t("apply_page.password") as unknown as string}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Stack width={19.75} height={19.75}>
                  {showPassword ? (
                    <EyeSlashIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleClickShowPassword}
                      data-testid={"apply-job-password-on-button"}
                    />
                  ) : (
                    <EyeIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleClickShowPassword}
                      data-testid={"apply-job-password-off-button"}
                    />
                  )}
                </Stack>
              </InputAdornment>
            ),
          }}
        />
        {errors.password && (
          <Typography variant="subtitle2" color="error">
            {errors.password.message as string}
          </Typography>
        )}
      </Box>
      <Box mb={2}>
        <Link
          underline="none"
          onClick={handlePasswordReset}
          data-testid={"apply-job-forgot-pass-button"}
        >
          <Typography variant="body2">
            {t("apply_page.forgot_password")}
          </Typography>
        </Link>
      </Box>
      <Stack spacing={1.5}>
        <StyledLoadingApplyButton
          data-testid={"apply-submit-button"}
          companyColor={companyColor}
          color="primary"
          variant="contained"
          size="small"
          type="submit"
          disableElevation
        >
          {t("apply_page.login")}
        </StyledLoadingApplyButton>
        <StyledAddLanguageButton
          data-testid={"apply-job-send-magic-link-button"}
          companyColor={companyColor}
          disabled={isMagicLinkLoading}
          endIcon={
            isMagicLinkLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: companyColor,
                }}
              />
            ) : undefined
          }
          variant="outlined"
          size="small"
          type="button"
          disableElevation
          onClick={handleSendMagicLink}
        >
          {t("apply_page.send_magic_link")}
        </StyledAddLanguageButton>
      </Stack>
    </form>
  );
};

export default CandidateExistLogin;
