import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  InputLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import AuthLayout from "../../../layouts/Auth";
import { INewPasswordComponent } from "../../../models/Auth";

const NewPasswordComponent = ({
  t,
  register,
  errors,
  isLoading,
  showPassword,
  desktop,
  handleSubmit,
  onSubmit,
  onReturn,
  onShowPassword,
}: INewPasswordComponent) => (
  <AuthLayout desktop={desktop}>
    <Typography variant="body1" lineHeight={2.5}>
      {t("reset_password_page.title")}
    </Typography>
    <Typography variant="subtitle2" color="textSecondary">
      {t("reset_password_page.new_password_text")}
    </Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={2.5} mb={2}>
        <InputLabel>{t("reset_password_page.new_password")}</InputLabel>
        <TextField
          {...register("password")}
          variant="outlined"
          size="small"
          type={showPassword ? "text" : "password"}
          placeholder={t("reset_password_page.new_password") as string}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Stack width={19.75} height={19.75}>
                  {showPassword ? (
                    <EyeSlashIcon onClick={onShowPassword} cursor={"pointer"} />
                  ) : (
                    <EyeIcon onClick={onShowPassword} cursor={"pointer"} />
                  )}
                </Stack>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box mt={2.5} mb={3}>
        <Typography variant="subtitle2">
          {t("reset_password_page.confirm_password")}
        </Typography>
        <TextField
          {...register("confirmPassword")}
          variant="outlined"
          size="small"
          type={showPassword ? "text" : "password"}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword?.message}
          placeholder={t("reset_password_page.confirm_password") as string}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Stack width={19.75} height={19.75}>
                  {showPassword ? (
                    <EyeSlashIcon onClick={onShowPassword} cursor={"pointer"} />
                  ) : (
                    <EyeIcon onClick={onShowPassword} cursor={"pointer"} />
                  )}
                </Stack>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        <Button
          color={"primary"}
          variant={"contained"}
          size={"small"}
          type={"submit"}
          disableElevation
          fullWidth
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          disabled={isLoading}
        >
          {t("reset_password_page.confirm_password")}
        </Button>
      </Box>
      <Link underline="none" display="inline" onClick={onReturn} fontSize={14}>
        {t("reset_password_page.link")}
      </Link>
    </form>
  </AuthLayout>
);

export default NewPasswordComponent;
